const UPDATE_SIDE_SELECTION = 'UPDATE_SIDE_SELECTION';
const UPDATE_ORDER_TYPE = 'UPDATE_ORDER_TYPE';
const UPDATE_DROP_DOWN = 'UPDATE_DROP_DOWN';
const UPDATE_QUANTITY_INPUT = 'UPDATE_QUANTITY_INPUT';
const UPDATE_QUANTITY_IN_CONTRACTS = 'UPDATE_QUANTITY_IN_CONTRACTS';
const UPDATE_LIMIT_PRICE = 'UPDATE_LIMIT_PRICE';
const UPDATE_REDUCE_ONLY = 'UPDATE_REDUCE_ONLY';
const UPDATE_MARGIN_VALUE = 'UPDATE_MARGIN_VALUE';
const CALCULATE_MARGIN_REQUEST = 'CALCULATE_MARGIN_REQUEST';
const STOP_CALCULATE_MARGIN = 'STOP_CALCULATE_MARGIN';
const TOGGLE_CLOSE_POSITION_CONFIRMATION_MODAL =
  'TOGGLE_CLOSE_POSITION_CONFIRMATION_MODAL';
const UPDATE_TAKE_PROFIT_PERCENT = 'UPDATE_TAKE_PROFIT_PERCENT';
const UPDATE_STOP_LOSS_PERCENT = 'UPDATE_STOP_LOSS_PERCENT';
const RESET_AFTER_SUBMIT = 'RESET_AFTER_SUBMIT';
const TOGGLE_CONFIRMATION_POPUP = 'TOGGLE_CONFIRMATION_POPUP';
const TOGGLE_QUANTITY_INPUT_HAS_ERROR = 'TOGGLE_QUANTITY_INPUT_HAS_ERROR';
const UPDATE_QUANTITY_INPUT_ERROR_MESSAGE = 'UPDATE_QUANTITY_INPUT_ERROR_MESSAGE';
const UPDATE_DONT_SHOW_CONFIRMATION_CHECKBOX = 'UPDATE_DONT_SHOW_CONFIRMATION_CHECKBOX';
const UPDATE_DONT_SHOW_CONFIRMATION_ON_CHART = 'UPDATE_DONT_SHOW_CONFIRMATION_ON_CHART';
const UPDATE_SELECTED_PERCENTAGE = 'UPDATE_SELECTED_PERCENTAGE';
const UPDATE_ORDER_SUBMIT_STATUS = 'UPDATE_ORDER_SUBMIT_STATUS';
const START_MAX_NOTIONAL_INTERVAL_ACTION = 'START_MAX_NOTIONAL_INTERVAL_ACTION';
const STOP_MAX_NOTIONAL_INTERVAL_ACTION = 'STOP_MAX_NOTIONAL_INTERVAL_ACTION';
const MAX_NOTIONAL_INTERVAL_ACTION = 'MAX_NOTIONAL_INTERVAL_ACTION';
const UPDATE_MAX_NOTIONAL_VALUE = 'UPDATE_MAX_NOTIONAL_VALUE';
const USER_INPUT_LEVERAGE = 'USER_INPUT_LEVERAGE';
const SELECTED_QUANTITY_PERCENTAGE = 'SELECTED_QUANTITY_PERCENTAGE';

export {
  CALCULATE_MARGIN_REQUEST,
  MAX_NOTIONAL_INTERVAL_ACTION,
  RESET_AFTER_SUBMIT,
  SELECTED_QUANTITY_PERCENTAGE,
  START_MAX_NOTIONAL_INTERVAL_ACTION,
  STOP_CALCULATE_MARGIN,
  STOP_MAX_NOTIONAL_INTERVAL_ACTION,
  TOGGLE_CLOSE_POSITION_CONFIRMATION_MODAL,
  TOGGLE_CONFIRMATION_POPUP,
  TOGGLE_QUANTITY_INPUT_HAS_ERROR,
  UPDATE_DONT_SHOW_CONFIRMATION_CHECKBOX,
  UPDATE_DONT_SHOW_CONFIRMATION_ON_CHART,
  UPDATE_DROP_DOWN,
  UPDATE_LIMIT_PRICE,
  UPDATE_MARGIN_VALUE,
  UPDATE_MAX_NOTIONAL_VALUE,
  UPDATE_ORDER_SUBMIT_STATUS,
  UPDATE_ORDER_TYPE,
  UPDATE_QUANTITY_INPUT,
  UPDATE_QUANTITY_INPUT_ERROR_MESSAGE,
  UPDATE_QUANTITY_IN_CONTRACTS,
  UPDATE_REDUCE_ONLY,
  UPDATE_SELECTED_PERCENTAGE,
  UPDATE_SIDE_SELECTION,
  UPDATE_STOP_LOSS_PERCENT,
  UPDATE_TAKE_PROFIT_PERCENT,
  USER_INPUT_LEVERAGE
};

