import qs from 'qs';

import { API_URL } from '../constants/constants';

const METHODS = ['get', 'post', 'put', 'patch', 'delete'];

// Helper function to format URL
export const formatUrl = path => {
  const adjustedPath = path.startsWith('/') ? path : `/${path}`;
  const baseUrl = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;
  return `${baseUrl}${adjustedPath}`;
};

const getFetchOptions = (method, data, headers) => {
  const options = {
    method,
    headers: new Headers(headers),
  };

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
  if (method !== 'get' && METHODS.includes(method)) {
    options.body = JSON.stringify(data);
    options.headers.append('Content-Type', 'application/json');
  }

  return options;
};

function parseResponse(response) {
  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.includes('application/json')) {
    return response.json();
  }
  if (contentType.includes('text/')) {
    return response.text();
  }
  return response.blob();
}

class ApiClient {
  constructor() {
    this.headers = {};
  }

  async makeRequest(
    method,
    path,
    { params, data, headers = {}, keepHeaders, qsOptions } = {}
  ) {
    const url = formatUrl(path) + (params ? `?${qs.stringify(params, qsOptions)}` : '');
    const fetchOptions = getFetchOptions(method, data, { ...this.headers, ...headers });
    let response = {};
    try {
      response = await fetch(url, fetchOptions);
    } catch (fetchError) {
      // fetch can throw error "Failed to fetch", catching that and throwing in a similar structure to other errors.
      const structuredFetchError = new Error('Failed to fetch');
      structuredFetchError.response = {
        body: {
          error: {
            code: fetchError?.message || 'unknown_fetch_error',
          },
        },
      };
      throw structuredFetchError;
    }

    const responseBody = await parseResponse(response);
    if (response.ok) {
      return keepHeaders
        ? { headers: response.headers, body: responseBody, params }
        : responseBody;
    }
    const error = new Error('HTTP error');
    error.response = {
      body: responseBody,
    };
    error.status = response?.status;
    throw error;
  }

  setHeader(key, value) {
    this.headers[key] = value;
  }

  unsetHeader() {
    this.headers = {};
  }
}

// Define HTTP methods
METHODS.forEach(method => {
  ApiClient.prototype[method] = function makeRequest(path, options) {
    return this.makeRequest(method, path, options);
  };
});

export default ApiClient;
