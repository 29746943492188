import keyMirror from 'helpers/keymirror';

export const UPDATE_TABLE_FILTER = 'UPDATE_TABLE_FILTER';
export const TAB_REACTIVE = 'TAB_REACTIVE'; // when tab goes from inactive to active
export const TAB_INACTIVE = 'TAB_INACTIVE'; // when tab goes from active to inactive
export const SAVE_CHART = 'SAVE_CHART';

export const OPEN_ADD_ADDRESSMNGT_MODAL = 'OPEN_ADD_ADDRESSMNGT_MODAL';
export const SOCKET_DISCONNECTED_ON_PAGE_INACTIVE =
  'SOCKET_DISCONNECTED_ON_PAGE_INACTIVE';
export const DISPATCH_HOLDINGS_API = 'DISPATCH_HOLDINGS_API';
export const DISPATCH_OPTION_LEADERBOARD_API = 'OPTION_LEADERBOARD_API';
export const TOGGLE_MOBILE_HEADER_DROPDOWN = 'TOGGLE_MOBILE_HEADER_DROPDOWN';
export const SHOW_KYC_UPGRADE_STRIP = 'SHOW_KYC_UPGRADE_STRIP';
export const SHOW_CONVERT_BLOCKED_POPUP = 'SHOW_CONVERT_BLOCKED_POPUP';
export const FETCH_LEADERBOARD_EVENT_DETAILS = 'FETCH_LEADERBOARD_EVENT_DETAILS';
export const SHOW_OTP_SUCCESS_POPUP = 'SHOW_OTP_SUCCESS_POPUP';
export const SET_COUNTRY_FROM_IP = 'SET_COUNTRY_FROM_IP';
export const TOGGLE_PHONE_VERIFICATION_MODAL = 'TOGGLE_PHONE_VERIFICATION_MODAL';

const actionTypes = keyMirror({
  COUNTRY_FROM_IP: null,
});

export default actionTypes;
