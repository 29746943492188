import createAction from 'helpers/createAction';

import {
  RESET_ALL_PAGINATION,
  RESET_PAGINATION,
  UPDATE_PAGINATION_PAGE,
  UPDATE_PAGINATION_ROWS_PER_PAGE,
} from '../actionTypes/pagination';

export const updatePaginationPage = createAction(UPDATE_PAGINATION_PAGE);

export const updatePaginationRowsPerPage = createAction(UPDATE_PAGINATION_ROWS_PER_PAGE);

export const resetPagination = createAction(RESET_PAGINATION);

export const resetAllPagination = createAction(RESET_ALL_PAGINATION);
