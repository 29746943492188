import { CURRENCY_STYLE } from 'constants/currency';
import { cropAfterDecimals } from 'helpers';
import { formatFiat, prettifyINR, prettifyUSD } from 'helpers/currency';

const DEFAULT_MAX_PRECISION = 2;
const DEFAULT_MIN_PRECISION = 2;

const formatAnalyticsValue = (value: number, options: Intl.NumberFormatOptions = {}) => {
  const { currency, maximumFractionDigits, minimumFractionDigits, ...restOptions } =
    options;

  const minPrecsision = Number.isInteger(value)
    ? 0
    : minimumFractionDigits ?? DEFAULT_MIN_PRECISION;
  const maxPrecision = maximumFractionDigits ?? DEFAULT_MAX_PRECISION;
  const croppedValue = cropAfterDecimals(value, maxPrecision);

  if (!currency) {
    return croppedValue.toString();
  }

  const formattingOptions = {
    style: 'currency',
    minimumFractionDigits: minPrecsision,
    maximumFractionDigits: maxPrecision,
    ...restOptions,
  };

  if (currency === CURRENCY_STYLE.INDIAN) {
    return prettifyINR(croppedValue, formattingOptions);
  }

  if (currency === CURRENCY_STYLE.USD || currency === 'USDT') {
    return prettifyUSD(croppedValue, formattingOptions);
  }

  return formatFiat(currency, croppedValue, 'en', formattingOptions);
};

export default formatAnalyticsValue;
