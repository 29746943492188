export const OPTIONS_CHAIN_UNDERLYING_ASSETS = 'OPTIONS_CHAIN_UNDERLYING_ASSETS';
export const OPTIONS_CHAIN_TOGGLE_PRICE_FILTER = 'OPTIONS_CHAIN_TOGGLE_PRICE_FILTER';
export const OPTIONS_CHAIN_TOGGLE_EXPIRY_DATE = 'OPTIONS_CHAIN_TOGGLE_EXPIRY_DATE';
export const OPTIONS_CHAIN_CHART_NAVIGATION = 'OPTIONS_CHAIN_CHART_NAVIGATION';
export const TOGGLE_OPTIONS_CHAIN_VISUALISATION = 'TOGGLE_OPTIONS_CHAIN_VISUALISATION';
export const OPTIONS_TRADE_SELECTED_COLUMN = 'OPTIONS_TRADE_SELECTED_COLUMN';
export const OPTIONS_TRADE_CHANGE_COLUMN = 'OPTIONS_TRADE_CHANGE_COLUMN';
export const OPTIONS_TRADE_CHANGE_COLUMN_SORT = 'OPTIONS_TRADE_CHANGE_COLUMN_SORT';
export const OPTIONS_TRADE_CHANGE_MAX_CALL_OI = 'OPTIONS_TRADE_CHANGE_MAX_CALL_OI';
export const OPTIONS_TRADE_CHANGE_MAX_PUT_OI = 'OPTIONS_TRADE_CHANGE_MAX_PUT_OI';
export const OPTIONS_TRADE_CLOSEST_STRIKE = 'OPTIONS_TRADE_CLOSEST_STRIKE';
