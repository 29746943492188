import { matchPath } from 'react-router';

import { any } from 'helpers/ramda';

// Check whether to render a component on a particular routes or not.
export const showComponentOnSelectedRoute = matchRoutes => location => {
  return any(path => matchPath(location.pathname, path), matchRoutes);
};

export const postMessageToMobileApp = (messageType, payload) => {
  try {
    if (window.isRNWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          messageType,
          payload,
        })
      );
    }
  } catch (error) {}
};
