import keyMirror from '../helpers/keymirror';

const actionTypes = keyMirror({
  BALANCES: null,
  INVEST_BALANCES: null,
  ADDRESS: null,
  PENDING_DEPOSITS: null,
  CHANGE_MARGIN: null,
  GET_CONVERSION_CONFIG: null,
  GET_CONVERSTION_RATE: null,
  CONVERT_CURRENCY: null,
  GET_ONMETA_QOUTE: null,
  CREATE_ONMETA_ORDER: null,
  WITHDRAWAL_RESEND_CONFIRMATION: null,
  FIAT_DEPOSITS: null,
  GET_REMITANO_PAYMENT_URL: null,
  GET_REMITANO_CURRENCIES: null,
  GET_REMITANO_CURRENCY_EXCHANGE_RATES: null,
  CREATE_SIMPLEX_PAYMENT_QUOTE: null,
  CREATE_SIMPLEX_PAYMENT_REQUEST: null,
  GET_SIMPLEX_SUPPORTED_FIAT_CURRENCIES: null,
  FIAT_WITHDRAWAL_HISTORY: null,
  NEOFI_ORDER_URL: null,
  GET_NEOFI_QUOTE: null,
  ONRAMPER_ORDER_URL: null,
  ONRAMPER_CURRENCY_LIST: null,
  DOWNLOAD_OFFRAMP_INVOICE_ONMETA: null,
  MUDREX_QUOTE: null,
  MUDREX_SIGNATURE: null,
});

export const OPEN_CURRENCY_CONVERSION_POPUP = 'OPEN_CURRENCY_CONVERSION_POPUP';
export const CLOSE_CURRENCY_CONVERSION_POPUP = 'CLOSE_CURRENCY_CONVERSION_POPUP';

export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const UPDATE_BALANCE = 'UPDATE_BALANCE';
// export const UPDATE_PORTFOLIO_MARGIN_PARAMS = 'UPDATE_PORTFOLIO_MARGIN_PARAMS';

export default actionTypes;
