/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable camelcase */

import { useTranslation } from 'react-i18next';

import {
  getFormattedLiquidationPrice,
  getOrderMetricsLabel,
} from 'components/placeorder/helper';
import { getPreciseFeeCredits } from 'components/trade_fee_credits/trade_fee_info';
import { MARGIN_TYPES, OFFER_TYPES_MAPPING } from 'constants/constants';
import { MARGIN_MODE } from 'constants/enums';
import { calculateLiquidationPrice } from 'helpers/calculator_formulas';
import {
  getOrderValue,
  round,
  round_by_contract_size,
  round_by_tick_size,
} from 'helpers/formulas';
import {
  getZeroFeeOfferEstimatedFee,
  isEligibleForZeroFeeOptionsOffer,
} from 'helpers/offers';
import { indexBy, values } from 'helpers/ramda';
import {
  calculateSpotImpactPrice,
  spotQuoteNotional,
  spotUnderlyingNotional,
} from 'helpers/spotFormulas';
import {
  assetPrecision,
  convertExponentialToDecimal,
  cropAfterDecimals,
  // cropAfterDecimals,
  discountCalculator,
  getDurationFromNow,
  isNan,
  isOptions,
  negativeBalanceCheck,
  pluralize,
} from 'helpers/utils';
import { defaultToHiphen } from 'helpers/validations';
import useSelectedProductBalance from 'hooks/componentHooks/useSelectedProductBalance';
import i18n from 'i18n/config';
import { getLabelByContractType } from 'reducers/helpers/trade';
import {
  markPriceState,
  // orderbooksSelector,
  spotPriceState,
} from 'selectors';
import { Box } from 'UIKit';
// import { useAppSelector } from 'storeHooks';

const LocalizedValue = ({ localeKey, options }) => {
  const { t } = useTranslation(['trading']);
  return t(`trading:${localeKey}`, options);
};

const getEstimatedPremiumTobeReceived = ({
  price,
  contract_size,
  size,
  settling_asset,
  isBuyActionState,
}) => {
  const premium = round(price * contract_size * size, settling_asset.minimum_precision);
  return {
    label: (
      <LocalizedValue
        localeKey={
          isBuyActionState ? 'estimatedPremiumToBePaid' : 'estimatedPremiumToBeReceived'
        }
      />
    ),
    value: (
      <>
        {premium}{' '}
        <Box as="span" textColor="secondary">
          {settling_asset.symbol}
        </Box>
      </>
    ),
  };
};

export const getEstimatedTradingFee = ({
  price,
  contract_size,
  size,
  settling_asset,
  balances,
  vip_discount_factor,
  spotPrice,
  maker_commission,
  taker_commission,
  postOnly,
  settlement_date,
  side,
  activeOffers,
  appliedOffers,
}) => {
  const duration = getDurationFromNow(settlement_date);
  const isLessThan12Hours = duration.days() === 0 && duration.hours() < 12;
  const premium = Number(contract_size) * Math.abs(size) * Number(price);
  const notional = contract_size * Math.abs(size) * spotPrice;
  const balancesBySymbol = indexBy(balance => balance.asset.symbol, values(balances));
  const effective_commission = !postOnly ? taker_commission : maker_commission;
  const feeByNotional = Number(effective_commission) * Number(notional);

  const [, , feeCreditsUSDT] = getPreciseFeeCredits(balancesBySymbol);
  const tradingFee = Math.min(feeByNotional, 0.1 * premium);

  let estTradingFee;
  let bottomText;

  if (tradingFee !== 0.1 * Number(premium)) {
    estTradingFee = discountCalculator(Number(vip_discount_factor), tradingFee);
  } else {
    estTradingFee = tradingFee;
  }

  if (Number(feeCreditsUSDT) > 0) {
    const offset = Math.min(Number(0.25 * estTradingFee), feeCreditsUSDT);
    estTradingFee = Number(estTradingFee) - offset;
    bottomText = (
      <LocalizedValue
        localeKey="partialTradingFeeDeduction"
        options={{ offsetValue: cropAfterDecimals(offset, 2) }}
      />
    );
  } else {
    bottomText = null;
  }

  // No fee will be charged if buy order is placed within 12 hours of the settlement date.
  // Will remove this when offer gets over.
  if (
    isLessThan12Hours &&
    side === 'buy' &&
    isEligibleForZeroFeeOptionsOffer(activeOffers, appliedOffers)
  ) {
    const zeroFeeOffer = activeOffers[OFFER_TYPES_MAPPING.ZERO_FEE_OPTIONS_BUYING];
    const claimedZeroFeeOffer =
      appliedOffers[OFFER_TYPES_MAPPING.ZERO_FEE_OPTIONS_BUYING];
    const feeToBeCharged = getZeroFeeOfferEstimatedFee({
      offer: zeroFeeOffer,
      claimedOffer: claimedZeroFeeOffer,
      estimatedFee: estTradingFee,
    });
    const formattedFeeToBeCharged = cropAfterDecimals(
      feeToBeCharged,
      settling_asset && settling_asset.minimum_precision
        ? settling_asset.minimum_precision
        : 2
    );

    return {
      label: <LocalizedValue localeKey="estTradingFee" />,
      strikedValue:
        Number(estTradingFee) !== 0
          ? `${cropAfterDecimals(
              estTradingFee,
              settling_asset && settling_asset.minimum_precision
                ? settling_asset.minimum_precision
                : 2
            )} ${settling_asset.symbol}`
          : null,
      value: (
        <>
          {formattedFeeToBeCharged}{' '}
          <Box as="span" textColor="secondary">
            {settling_asset.symbol}
          </Box>
        </>
      ),
      className: 'trading-fee-estimate',
      bottomText: (
        <LocalizedValue
          localeKey={feeToBeCharged > 0 ? 'estTradingFee' : 'feeNotApplicable'}
        />
      ),
    };
  }

  estTradingFee = cropAfterDecimals(
    convertExponentialToDecimal(estTradingFee),
    settling_asset.minimum_precision
  );

  return {
    label: <LocalizedValue localeKey="estTradingFee" />,
    value: (
      <>
        {estTradingFee}{' '}
        <Box as="span" textColor="secondary">
          {settling_asset.symbol}
        </Box>
      </>
    ),
    className: 'trading-fee-estimate',
    bottomText,
  };
};

export const getConfirmationTable = ({
  selected_product,
  position,
  side,
  estimatedPortfolioMargin,
  orderPrice,
  orderSize,
  orderMargin,
  isBuyActionState,
  leverage,
  balances,
  marginType,
  vip_discount_factor,
  postOnly,
  appliedOffers,
  activeOffers,
  // roundedBalance,
}) => {
  const markPrice = markPriceState();
  const spotPrice = spotPriceState();
  const contractType = selected_product.contract_type;
  const assetSymbol = (() => {
    switch (selected_product.contract_type) {
      case 'move_options':
      case 'call_options':
      case 'put_options':
      case 'turbo_put_options':
      case 'turbo_call_options':
      case 'options_combos':
        return selected_product.underlying_asset.symbol;
      case 'spot':
        return isBuyActionState
          ? selected_product.underlying_asset.symbol
          : selected_product.quoting_asset.symbol;
      default:
        return selected_product.settling_asset.symbol;
    }
  })();

  const priceSymbol =
    contractType === 'interest_rate_swaps' ? '%' : selected_product.quoting_asset.symbol;
  const productTickSize = selected_product.tick_size;
  const assetId = selected_product.settling_asset.id;
  // const contractVal = parseFloat(selected_product.contract_value, 10);
  const positionSize = position ? parseFloat(position.size, 10) : 0;
  const positionDirection = side === 'buy' ? 1 : -1;
  const orderValue = round_by_contract_size(
    getOrderValue(
      selected_product.notional_type,
      selected_product.contract_value,
      orderPrice || markPrice,
      orderSize,
      selected_product.contract_type,
      spotPrice
    ),
    selected_product.contract_value
  );

  const isProductAnOption = isOptions(contractType);

  const oldPosition = {
    size: positionSize,
    margin: position ? parseFloat(position.margin, 10) : 0.0,
    entry_price: position ? parseFloat(position.entry_price, 10) : 1,
  };
  const newOrder = {
    size: orderSize * positionDirection,
    side,
    leverage,
    price: Number(orderPrice),
  };

  const { resultingPosition, liquidationPrice } = calculateLiquidationPrice(
    oldPosition,
    newOrder,
    markPrice,
    spotPrice,
    selected_product
  );

  const marginglabel = (() => {
    switch (contractType) {
      case 'interest_rate_swaps':
        return <LocalizedValue localeKey="cost" />;
      case 'put_options':
      case 'call_options':
      case 'move_options':
      case 'turbo_put_options':
      case 'turbo_call_options':
      case 'options_combos':
        // for option contract and sell (short) orders
        if (isProductAnOption && !isBuyActionState) {
          return <LocalizedValue localeKey="estMarginRequired" />;
        }
        return <LocalizedValue localeKey="margin" />;
      case 'spreads':
      default:
        if (marginType === MARGIN_TYPES.CROSS) {
          return <LocalizedValue localeKey="estimatedMarginMsg" />;
        }
        return <LocalizedValue localeKey="margin" />;
    }
  })();

  const roundedOrderMargin = cropAfterDecimals(
    orderMargin,
    selected_product.settling_asset.minimum_precision
  );

  const marginValue = (() => {
    switch (contractType) {
      case 'put_options':
      case 'call_options':
      case 'move_options':
      case 'options_combos':
        if (marginType === MARGIN_TYPES.CROSS || isBuyActionState) {
          return (
            <>
              {roundedOrderMargin}{' '}
              <Box as="span" textColor="secondary">
                {selected_product.settling_asset.symbol}
              </Box>
            </>
          );
        }
        return (
          <>
            {roundedOrderMargin}{' '}
            <Box as="span" textColor="secondary">
              {selected_product.settling_asset.symbol} @{' '}
              {cropAfterDecimals(Number(leverage), 2)}x
            </Box>
          </>
        );
      case 'turbo_put_options':
      case 'turbo_call_options':
        return `${roundedOrderMargin} ${selected_product.settling_asset.symbol}`;
      default:
        if (marginType === MARGIN_MODE.CROSS) {
          return (
            <>
              {roundedOrderMargin}{' '}
              <Box as="span" textColor="secondary">
                {selected_product.settling_asset.symbol}
              </Box>
            </>
          );
        }

        return (
          <>
            {roundedOrderMargin}{' '}
            <Box as="span" textColor="secondary">
              {selected_product.settling_asset.symbol} @{' '}
              {cropAfterDecimals(Number(leverage), 2)}x
            </Box>
          </>
        );
    }
  })();

  const getPrecision = assetPrecision(balances[assetId]?.asset);

  const orderMetricsLabel = getOrderMetricsLabel({
    contractType: selected_product.contract_type,
  });

  const formattedLiquidationPrice = getFormattedLiquidationPrice({
    isProductAnOption,
    isBuyActionState,
    liquidationPrice,
    productTickSize,
    priceSymbol,
    marginType,
  });

  let confirmationTable = [
    {
      label: <LocalizedValue localeKey={orderMetricsLabel} />,
      value: (
        <>
          {orderValue}{' '}
          <Box as="span" textColor="secondary">
            {assetSymbol}
          </Box>
        </>
      ),
      id: 'orderMetrics',
    },
    {
      label: marginglabel,
      value: marginValue,
      id: 'margin',
    },
    // {
    //   label: <LocalizedValue localeKey="availableBalance" />,
    //   value: (
    //     <>
    //       {roundedBalance}{' '}
    //       <Box as="span" textColor="secondary">
    //         {selected_product.settling_asset.symbol}
    //       </Box>
    //     </>
    //   ),
    //   id: 'availableBalance',
    // },
    {
      label: <LocalizedValue localeKey="estMargin" />,
      value: (
        <>
          {estimatedPortfolioMargin}{' '}
          <Box as="span" textColor="secondary">
            {selected_product?.settling_asset?.symbol}
          </Box>
        </>
      ),
      id: 'estimatedMargin',
    },
    {
      label: getLabelByContractType(contractType, i18n.t('trading:limitPrice')),
      value: (
        <>
          {cropAfterDecimals(orderPrice, getPrecision)}{' '}
          <Box as="span" textColor="secondary">
            {priceSymbol}
          </Box>
        </>
      ),
      // value: `${orderPrice} ${priceSymbol}`,
      id: 'executionPrice',
    },
    {
      label: getLabelByContractType(contractType, i18n.t('trading:estLiquidationPrice')),
      value: formattedLiquidationPrice,
      className: 'highlight',
      id: 'liquidationPrice',
    },
    {
      label: <LocalizedValue localeKey="posSizeAfterExec" />,
      value: (
        <>
          {Math.round(resultingPosition.netPosition)}{' '}
          <Box as="span" textColor="secondary">
            {pluralize({
              count: Math.round(resultingPosition.netPosition),
              noun: i18n.t('trading:contractConfirm'),
              exclusions: [1, -1, 0],
              isCountShown: false,
            })}
          </Box>
        </>
      ),
      id: 'positionSize',
    },
  ];

  if (marginType === MARGIN_TYPES.PORTFOLIO) {
    confirmationTable = confirmationTable.filter(
      item => item.id !== 'margin' && item.id !== 'liquidationPrice'
    );
  }
  if (marginType !== MARGIN_TYPES.PORTFOLIO) {
    confirmationTable = confirmationTable.filter(item => item.id !== 'estimatedMargin');
  }

  // isBuyActionState has been removed and introducing premium paid

  // for long orders (buy) we don't need to show premium for options
  if (isProductAnOption && !isBuyActionState) {
    confirmationTable.splice(
      2,
      0,
      getEstimatedPremiumTobeReceived({
        price: orderPrice,
        contract_size: selected_product.contract_value,
        size: orderSize,
        settling_asset: selected_product.settling_asset,
        isBuyActionState,
      })
    );
  }

  if (isProductAnOption) {
    confirmationTable.push(
      getEstimatedTradingFee({
        price: orderPrice,
        contract_size: selected_product.contract_value,
        size: orderSize,
        settling_asset: selected_product.settling_asset,
        balances,
        vip_discount_factor,
        spotPrice,
        maker_commission: selected_product.maker_commission_rate,
        taker_commission: selected_product.taker_commission_rate,
        postOnly,
        settlement_date: selected_product.settlement_time,
        side,
        appliedOffers,
        activeOffers,
      })
    );
    confirmationTable = confirmationTable.filter(
      ({ id }) => !['executionPrice'].includes(id)
    );
  }

  const priceBandingMsg =
    side === 'buy'
      ? i18n.t('trading:priceBandingMsgBuy')
      : i18n.t('trading:priceBandingMsgSell');

  return {
    confirmationTable,
    priceBandingMsg,
  };
};

export const marketPlaceOrderConfirmationTable = ({
  selected_product,
  position,
  side,
  orderSize,
  orderMargin,
  isBuyActionState,
  leverage,
  balances,
  estimatedExecPrice,
  marginType,
  vip_discount_factor,
  estimatedPortfolioMargin,
  appliedOffers,
  activeOffers,
  // multiCollateralData,
  // margin_mode,
  // roundedBalance,
}) => {
  const markPrice = markPriceState();
  const spotPrice = spotPriceState();
  const { contract_type: contractType } = selected_product;
  const assetSymbol = (() => {
    switch (contractType) {
      case 'move_options':
      case 'call_options':
      case 'put_options':
      case 'turbo_put_options':
      case 'turbo_call_options':
      case 'options_combos':
        return selected_product?.underlying_asset?.symbol;
      default:
        return selected_product?.settling_asset?.symbol;
    }
  })();
  const priceSymbol =
    contractType === 'interest_rate_swaps' ? '%' : selected_product.quoting_asset.symbol;
  const productTickSize = selected_product.tick_size;
  // const assetId = selected_product.settling_asset.id;
  // const contractVal = parseFloat(selected_product.contract_value, 10);
  const positionSize = position ? parseFloat(position.size, 10) : 0;
  const positionDirection = side === 'buy' ? 1 : -1;

  const oldPosition = {
    size: positionSize,
    margin: position ? parseFloat(position.margin, 10) : 0.0,
    entry_price: position ? parseFloat(position.entry_price, 10) : 1,
  };
  const newOrder = {
    size: orderSize * positionDirection,
    leverage,
    price: parseFloat(estimatedExecPrice, 10),
  };

  const { resultingPosition, liquidationPrice } = calculateLiquidationPrice(
    oldPosition,
    newOrder,
    markPrice,
    spotPrice,
    selected_product
  );

  const orderValue = round_by_contract_size(
    getOrderValue(
      selected_product.notional_type,
      selected_product.contract_value,
      markPrice,
      orderSize,
      selected_product.contract_type,
      spotPrice
    ),
    selected_product.contract_value
  );

  const isProductAnOption = isOptions(contractType);

  const marginglabel = (() => {
    switch (contractType) {
      case 'interest_rate_swaps':
        return 'Cost';
      case 'put_options':
      case 'call_options':
      case 'move_options':
      case 'turbo_put_options':
      case 'turbo_call_options':
      case 'options_combos':
        // for option contract and sell (short) orders
        if (isProductAnOption && !isBuyActionState) {
          return <LocalizedValue localeKey="estMarginRequired" />;
        }
        return <LocalizedValue localeKey="margin" />;
      default:
        if (marginType === MARGIN_TYPES.CROSS) {
          return <LocalizedValue localeKey="estimatedMarginMsg" />;
        }
        return <LocalizedValue localeKey="margin" />;
    }
  })();

  const roundedOrderMargin = cropAfterDecimals(
    orderMargin,
    selected_product?.settling_asset?.minimum_precision
  );

  const marginValue = (() => {
    switch (contractType) {
      case 'put_options':
      case 'call_options':
      case 'move_options':
      case 'options_combos':
        if (marginType === MARGIN_TYPES.CROSS || isBuyActionState) {
          return (
            <>
              {roundedOrderMargin}{' '}
              <Box as="span" textColor="secondary">
                {selected_product.settling_asset.symbol}
              </Box>
            </>
          );
        }
        return (
          <>
            {roundedOrderMargin}{' '}
            <Box as="span" textColor="secondary">
              {selected_product.settling_asset.symbol} @{' '}
              {cropAfterDecimals(Number(leverage), 2)}x
            </Box>
          </>
        );
      case 'turbo_put_options':
      case 'turbo_call_options':
        return (
          <>
            {roundedOrderMargin}{' '}
            <Box as="span" textColor="secondary">
              {selected_product.settling_asset.symbol}
            </Box>
          </>
        );
      default:
        if (marginType === MARGIN_TYPES.CROSS) {
          return (
            <>
              {roundedOrderMargin}{' '}
              <Box as="span" textColor="secondary">
                {selected_product.settling_asset.symbol}
              </Box>
            </>
          );
        }
        return (
          <>
            {roundedOrderMargin}{' '}
            <Box as="span" textColor="secondary">
              {selected_product.settling_asset.symbol} @{' '}
              {cropAfterDecimals(Number(leverage), 2)}x
            </Box>
          </>
        );
    }
  })();

  // const getPrecision = assetPrecision(balances[assetId]?.asset);
  // let balance = balances[assetId]?.available_balance;
  // if (margin_mode === MARGIN_TYPES.CROSS && contractType !== 'spot') {
  //   if (isOptions(contractType) && isBuyActionState) {
  //     balance = multiCollateralData?.available_margin_long_options;
  //   } else {
  //     balance = multiCollateralData?.available_margin_usdt;
  //   }
  // }
  // const roundedBalance = negativeBalanceCheck(balance, getPrecision);

  const orderMetricsLabel = getOrderMetricsLabel({
    contractType: selected_product.contract_type,
  });

  const formattedLiquidationPrice = getFormattedLiquidationPrice({
    isProductAnOption,
    isBuyActionState,
    liquidationPrice,
    productTickSize,
    priceSymbol,
    customFormatting: defaultToHiphen,
    marginType,
  });

  let confirmationTable = [
    {
      label: <LocalizedValue localeKey={orderMetricsLabel} />,
      value: (
        <>
          {orderValue}{' '}
          <Box as="span" textColor="secondary">
            {assetSymbol}
          </Box>
        </>
      ),
      id: 'orderMetrics',
    },
    {
      label: marginglabel,
      value: marginValue,
      id: 'margin',
    },
    // {
    //   label: <LocalizedValue localeKey="availableBalance" />,
    //   value: (
    //     <>
    //       {roundedBalance}{' '}
    //       <Box as="span" textColor="secondary">
    //         {selected_product.settling_asset.symbol}
    //       </Box>
    //     </>
    //   ),
    //   id: 'availableBalance',
    // },
    {
      label: <LocalizedValue localeKey="estMargin" />,
      value: (
        <>
          {defaultToHiphen(estimatedPortfolioMargin)}{' '}
          <Box as="span" textColor="secondary">
            {selected_product?.settling_asset?.symbol}
          </Box>
        </>
      ),

      id: 'estimatedMargin',
    },
    {
      label: getLabelByContractType(contractType, i18n.t('trading:estExecPrice')),
      value: (
        <>
          {defaultToHiphen(estimatedExecPrice)}{' '}
          <Box as="span" textColor="secondary">
            {priceSymbol}
          </Box>
        </>
      ),
      id: 'executionPrice',
    },
    {
      label: getLabelByContractType(contractType, i18n.t('trading:estLiquidationPrice')),
      value: formattedLiquidationPrice,
      className: 'highlight',
      id: 'liquidationPrice',
    },
    {
      label: <LocalizedValue localeKey="posSizeAfterExec" />,
      value: (
        <>
          {Math.round(resultingPosition.netPosition)}{' '}
          <Box as="span" textColor="secondary">
            {pluralize({
              count: Math.round(resultingPosition.netPosition),
              noun: i18n.t('trading:contractConfirm'),
              exclusions: [1, -1, 0],
              isCountShown: false,
            })}
          </Box>
        </>
      ),
      id: 'positionSize',
    },
  ];

  if (marginType === MARGIN_TYPES.PORTFOLIO) {
    confirmationTable = confirmationTable.filter(
      item => item.id !== 'margin' && item.id !== 'liquidationPrice'
    );
  }
  if (marginType !== MARGIN_TYPES.PORTFOLIO) {
    confirmationTable = confirmationTable.filter(item => item.id !== 'estimatedMargin');
  }
  // isBuyActionState has been removed and introducing premium received

  // for long orders (buy) we don't need to show premium for options
  if (isProductAnOption && !isBuyActionState) {
    confirmationTable.splice(
      2,
      0,
      getEstimatedPremiumTobeReceived({
        price: markPrice,
        contract_size: selected_product.contract_value,
        size: orderSize,
        settling_asset: selected_product.settling_asset,
        isBuyActionState,
      })
    );
  }

  if (isProductAnOption) {
    confirmationTable.push(
      getEstimatedTradingFee({
        price: markPrice,
        contract_size: selected_product.contract_value,
        size: orderSize,
        settling_asset: selected_product.settling_asset,
        balances,
        vip_discount_factor,
        spotPrice,
        maker_commission: selected_product.maker_commission_rate,
        taker_commission: selected_product.taker_commission_rate,
        postOnly: false,
        settlement_date: selected_product.settlement_time,
        side,
        appliedOffers,
        activeOffers,
      })
    );
  }

  const priceBandingMsg =
    side === 'buy'
      ? i18n.t('trading:marketOrderPriceBandingMsgBuy')
      : i18n.t('trading:marketOrderPriceBandingMsgSell');

  return {
    confirmationTable,
    priceBandingMsg,
  };
};

// call this when limit price is not available and have to impactprice from orderbook
export const spotMarketPlaceOrderConfirmationTable = ({
  selected_product,
  side,
  orderSize,
  isQuoteAsset,
  balances,
  isBuyActionState,
  orderbook,
}) => {
  const price = calculateSpotImpactPrice(
    orderbook,
    side,
    orderSize,
    selected_product,
    isQuoteAsset
  );

  const { confirmationTable, priceBandingMsg } = getSpotConfirmationTable({
    selected_product,
    balances,
    price,
    isQuoteAsset,
    isBuyActionState,
    orderSize,
  });
  return {
    confirmationTable,
    priceBandingMsg,
  };
};

// call this when limit price or stop price available is available
export const getSpotConfirmationTable = ({
  selected_product,
  balances,
  price,
  isQuoteAsset,
  isBuyActionState,
  orderSize,
  multiCollateralData,
  margin_mode,
}) => {
  const {
    underlying_asset: { symbol: underlyingSymbol, id: underlyingAssetId },
    quoting_asset: { symbol: quotingSymbol, id: quotingAssetId },
  } = selected_product;

  const balanceAssetId = isBuyActionState ? quotingAssetId : underlyingAssetId;

  const buyingCurrency = isBuyActionState ? underlyingSymbol : quotingSymbol;
  const sellingCurrency = isBuyActionState ? quotingSymbol : underlyingSymbol;
  const buy_precision = isBuyActionState
    ? assetPrecision(selected_product.underlying_asset)
    : assetPrecision(selected_product.quoting_asset);
  const sell_precision = isBuyActionState
    ? assetPrecision(selected_product.quoting_asset)
    : assetPrecision(selected_product.underlying_asset);

  const buyValue = isBuyActionState
    ? !isQuoteAsset
      ? orderSize
      : spotUnderlyingNotional(price, orderSize) // will get underlying
    : !isQuoteAsset
    ? spotQuoteNotional(price, orderSize)
    : orderSize; // will get quoting

  const sellValue = isBuyActionState
    ? !isQuoteAsset
      ? spotQuoteNotional(price, orderSize)
      : orderSize // will give quoting
    : !isQuoteAsset
    ? orderSize
    : spotUnderlyingNotional(price, orderSize); // will give underlying

  let balance = balances[balanceAssetId];
  const balanceSymbol = isBuyActionState ? quotingSymbol : underlyingSymbol;

  if (margin_mode === MARGIN_TYPES.CROSS) {
    if (balances[balanceAssetId]?.updated_at < multiCollateralData.updated_at) {
      const wallet = multiCollateralData?.wallets?.filter(
        obj => obj.asset_id === balanceAssetId
      );
      if (wallet.length > 0) {
        balance = wallet[0].available_balance;
      }
    }
  }

  const confirmationTable = [
    {
      label: `${buyingCurrency} ${i18n.t('trading:youWillGet')}`,
      value: `${negativeBalanceCheck(buyValue, buy_precision)}`,
    },
    {
      label: `${sellingCurrency} ${i18n.t('trading:youWillGive')}`,
      value: `${negativeBalanceCheck(sellValue, sell_precision)}`,
    },
    {
      label: <LocalizedValue localeKey="availableBalance" />,
      value: `${
        balance &&
        negativeBalanceCheck(balance.available_balance, assetPrecision(balance.asset))
      } ${balanceSymbol}`,
    },
  ];

  const priceBandingMsg = '';

  return {
    confirmationTable,
    priceBandingMsg,
  };
};

export const stopOrderConfirmationTable = ({
  selected_product,
  position,
  limitPrice,
  orderSize,
  leverage,
  side,
  // balances,
}) => {
  const spotPrice = spotPriceState();
  const markPrice = markPriceState();

  const price = limitPrice || markPrice;

  const assetSymbol = (() => {
    switch (selected_product.contract_type) {
      case 'move_options':
      case 'call_options':
      case 'put_options':
      case 'turbo_put_options':
      case 'turbo_call_options':
      case 'options_combos':
        return selected_product.underlying_asset.symbol;

      default:
        return selected_product.settling_asset.symbol;
    }
  })();

  // const assetId = selected_product.settling_asset.id;
  // const contractVal = parseFloat(selected_product.contract_value, 10);
  const positionSize = position ? parseFloat(position.size, 10) : 0;
  const positionDirection = side === 'buy' ? 1 : -1;
  const orderValue = round_by_contract_size(
    getOrderValue(
      selected_product.notional_type,
      selected_product.contract_value,
      price,
      orderSize,
      selected_product.contract_type,
      spotPrice
    ),
    selected_product.contract_value
  );

  const oldPosition = {
    size: positionSize,
    margin: position ? parseFloat(position.margin, 10) : 0.0,
    entry_price: position ? parseFloat(position.entry_price, 10) : 1,
  };
  const newOrder = {
    size: orderSize * positionDirection,
    leverage,
    price: Number(price),
  };

  const { resultingPosition } = calculateLiquidationPrice(
    oldPosition,
    newOrder,
    price,
    spotPrice,
    selected_product
  );

  // const getPrecision = assetPrecision(balances[assetId].asset);
  const orderMetricsLabel = getOrderMetricsLabel({
    contractType: selected_product.contract_type,
  });

  const confirmationTable = [
    {
      label: <LocalizedValue localeKey={orderMetricsLabel} />,
      value: (
        <>
          {orderValue}{' '}
          <Box as="span" textColor="secondary">
            {assetSymbol}
          </Box>
        </>
      ),
      id: 'orderMetrics',
    },
    // {
    //   label: <LocalizedValue localeKey="availableBalance" />,
    //   value: (
    //     <>
    //       {balances[assetId] &&
    //         negativeBalanceCheck(balances[assetId].available_balance, getPrecision)}{' '}
    //       <Box as="span" textColor="secondary">
    //         {selected_product.settling_asset.symbol}
    //       </Box>
    //     </>
    //   ),
    //   id: 'availableBalance',
    // },
    {
      label: <LocalizedValue localeKey="posSizeAfterExec" />,
      value: (
        <>
          {Math.round(resultingPosition.netPosition)}{' '}
          <Box as="span" textColor="secondary">
            {pluralize({
              count: Math.round(resultingPosition.netPosition),
              noun: i18n.t('trading:contractConfirm'),
              exclusions: [1, -1, 0],
              isCountShown: false,
            })}
          </Box>
        </>
      ),
      id: 'posSizeAfterExec',
    },
  ];

  return { confirmationTable };
};

export const getAssetSymbol = selectedProduct => {
  switch (selectedProduct.contract_type) {
    case 'move_options':
    case 'call_options':
    case 'put_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'options_combos':
      return selectedProduct.underlying_asset?.symbol;
    default:
      return selectedProduct.settling_asset?.symbol;
  }
};

export const calculateTakeProfitEstPrice = ({ val, side, orderPrice, tick_size }) => {
  if (!val || !orderPrice || isNan(orderPrice) || !(Number(orderPrice) > 0)) {
    return '';
  }
  if (side === 'buy') {
    return round_by_tick_size(orderPrice * (1 + val / 100), tick_size);
  }
  return round_by_tick_size(orderPrice * (1 - val / 100), tick_size);
};

export const calculateStopLossEstPrice = ({ val, side, orderPrice, tick_size }) => {
  if (!val || !orderPrice || isNan(orderPrice) || !(Number(orderPrice) > 0)) {
    return '';
  }
  if (side === 'buy') {
    return round_by_tick_size(orderPrice * (1 - val / 100), tick_size);
  }
  return round_by_tick_size(orderPrice * (1 + val / 100), tick_size);
};

export const calculateTakeProfitEstPercent = ({ val, side, orderPrice }) => {
  if (!val || !orderPrice || isNan(orderPrice) || !(Number(orderPrice) > 0)) {
    return '';
  }
  if (side === 'buy') {
    const estPercent = (((val - orderPrice) / orderPrice) * 100).toFixed(2);
    return estPercent > 0 ? estPercent : '';
  }
  const estPercent = (((orderPrice - val) / orderPrice) * 100).toFixed(2);
  return estPercent > 0 ? estPercent : '';
};

export const calculateStopLossEstPercent = ({ val, side, orderPrice }) => {
  if (!val || !orderPrice || isNan(orderPrice) || !(Number(orderPrice) > 0)) {
    return '';
  }
  if (side === 'buy') {
    const estPercent = (((orderPrice - val) / orderPrice) * 100).toFixed(2);
    return estPercent > 0 ? estPercent : '';
  }
  const estPercent = (((val - orderPrice) / orderPrice) * 100).toFixed(2);
  return estPercent > 0 ? estPercent : '';
};

export const isValidTakeProfitValue = ({ takeProfitEstPrice, orderPrice, side }) => {
  if (!takeProfitEstPrice) {
    return true;
  }

  if (side === 'buy' && Number(takeProfitEstPrice) > Number(orderPrice)) {
    return true;
  }

  if (side === 'sell' && Number(takeProfitEstPrice) < Number(orderPrice)) {
    return true;
  }

  return false;
};

export const isValidStopLossValue = ({ stopLossEstPrice, orderPrice, side }) => {
  if (!stopLossEstPrice) {
    return true;
  }

  if (side === 'buy' && Number(stopLossEstPrice) < Number(orderPrice)) {
    return true;
  }

  if (side === 'sell' && Number(stopLossEstPrice) > Number(orderPrice)) {
    return true;
  }

  return false;
};

export const availableBalanceHookForClassComponent = Component => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return function WrappedComponent(props) {
    const { selected_product, isBuyActionState } = props;
    const { roundedBalance } = useSelectedProductBalance(
      selected_product,
      isBuyActionState
    );
    return <Component {...props} roundedBalance={roundedBalance} />;
  };
};
