import { MARGIN_TYPES } from 'constants/constants';
import { getUnrealisedPnl, getPayOff, getIrsPayoff } from 'helpers/formulas';
import useOpenPositionExitPrice from 'hooks/componentHooks/useOpenPositionExitPrice';
import { spotPriceSelectorBySymbol } from 'selectors/priceSelectors';

// todo-suraj:move this to formula.js
// This is the functin for upl isolated & it's renamed as unrealised cashflow; there won't be any premium but only payoff
export const getBalanceUnrealizedPnl = (id, openPositionsList) => {
  // (!margin_mode || margin_mode === 'isolated'): This condition has been added bcz positions under portfolio are supposed to be ignored
  return openPositionsList
    .filter(
      position =>
        position?.product?.settling_asset?.symbol === id &&
        (!position.margin_mode || position.margin_mode !== MARGIN_TYPES.PORTFOLIO)
    )
    .reduce((acc, position) => {
      const spotPrice = spotPriceSelectorBySymbol(position.product.spot_index.symbol);

      const exitPrice = useOpenPositionExitPrice(position);

      // const positionObj = {
      //   realized_funding: position.realized_funding,
      //   realized_pnl: position.realized_pnl,
      //   size: position.size,
      //   margin: position.margin,
      // };

      const unrealisedCashflow = (() => {
        switch (position.product?.contract_type) {
          case 'interest_rate_swaps':
            return getIrsPayoff(position.size, exitPrice, position.product, spotPrice);
          case 'move_options':
          case 'put_options':
          case 'call_options':
          case 'options_combos':
            return getPayOff(position.product?.contract_value, position.size, exitPrice);
          default:
            return getUnrealisedPnl(
              position.product?.notional_type,
              position.product?.contract_value,
              position.entry_price,
              exitPrice,
              position.size,
              position.product?.contract_type
            );
        }
      })();
      return acc + unrealisedCashflow;
    }, 0);
};

/**
 *
 * @param {object} balances
 * @param {array} openPositionsList
 * @param {function} getMarkPrice
 * @param {Number} portfolio_upl
 * @param {Number} [portfolioEnabledAsset]
 * @returns balanceEquity of that asset
 */
export const getBalanceEquity = (
  // eslint-disable-next-line default-param-last
  balances = {},
  openPositionsList,
  getMarkPrice,
  // eslint-disable-next-line default-param-last
  portfolio_upl = 0,
  portfolioEnabledAsset
) => {
  const unrealised_cashflow = getBalanceUnrealizedPnl(
    balances?.asset?.symbol,
    openPositionsList,
    getMarkPrice
  );
  const portfolio_cashflow =
    portfolioEnabledAsset === balances?.asset?.id ? Number(portfolio_upl) : 0;

  const balanceEquity =
    Number(unrealised_cashflow) + Number(balances.balance) + portfolio_cashflow;
  return balanceEquity;
};
