import keyMirror from 'helpers/keymirror';
import { AuthActionTypes } from 'types/Auth';

export enum LoginViaQrActionTypes {
  OPEN_SCANNER = 'LOGIN_VIA_QR/OPEN_SCANNER',
  SET_TOKEN = 'LOGIN_VIA_QR/SET_TOKEN',
  REFRESH_TOKEN = 'LOGIN_VIA_QR/REFRESH_TOKEN',
  SET_QR_TOKEN_STATUS = 'LOGIN_VIA_QR/SET_QR_TOKEN_STATUS',
  LOGIN_AUTHORIZED = 'LOGIN_VIA_QR/LOGIN_AUTHORIZED',
  SET_AUTHORIZED_USER = 'LOGIN_VIA_QR/SET_AUTHORIZED_USER',

  // #region API ACTION TYPES
  CREATE_TOKEN = 'LOGIN_VIA_QR/CREATE_TOKEN',
  GET_TOKEN_DETAILS = 'LOGIN_VIA_QR/GET_TOKEN_DETAILS',
  // #endregion
}

export enum LoginViaBiometricsActionTypes {
  LOGIN_AUTHORIZED = 'LOGIN_VIA_BIOMETRICS/AUTHORIZED',
  SWITCH_TO_SUBACCOUNT = 'LOGIN_VIA_BIOMETRICS/SWITCH_TO_SUBACCOUNT',
  SET_AUTHORIZED_USER = 'LOGIN_VIA_BIOMETRICS/SET_AUTHORIZED_USER',
  CLEAN_UP_SESSION = 'LOGIN_VIA_BIOMETRICS/CLEAN_UP_AUTHORIZED_USER',
}

const ActionTypes = {
  // #region Login Via QR
  [LoginViaQrActionTypes.CREATE_TOKEN]: null,
  [LoginViaQrActionTypes.GET_TOKEN_DETAILS]: null,
  // #endregion
};

const AUTH_ACTION_TYPES: AuthActionTypes = keyMirror(ActionTypes);

export default AUTH_ACTION_TYPES;
