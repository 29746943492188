import { TRANSACTION_LOGS } from 'constants/api';
import { pick } from 'helpers/ramda';

import { authAction } from './user';

export const transactionAction = (types, params, payload) =>
  authAction({
    types,
    params,
    promise: ({ request }) =>
      request.get(TRANSACTION_LOGS.GET_WALLET_TRANSACTIONS, {
        params: payload,
        keepHeaders: true,
      }),
  });

export const getTicker = types => symbols => ({
  types,
  promise: ({ request }) => request.get(`/v2/tickers/${symbols}`),
});

export const allTickers = types => contractTypes => ({
  types,
  promise: ({ request }) => request.get(`/v2/tickers?contract_types=${contractTypes}`),
});

/*
  When an operation such as editing a quantity in an holdings table happens,
  It forces data getter actions to get fresh data but it doesn't have filterProductId (ID of product from filter menu).
  It doesn't work when a filter has been applied.

  Now when data getter actions are called, we pass holdingsTableFilter argument (a reducer which contains filterproductID of each holdings table and is constantly updated).
  And each action then accesses its current filterProductId.

  References:
  epics/trade (Calling actions forcefully on particular action-types and injecting holdingsTableFilter)
  actions/trade#getOpenOrders (Usecase of this helper function)
  reducers/holdingsTableFilter (Reducer which contains filterproductID of each holdings table)
*/
export const getHoldingsFilterId = (payload, tableName) => {
  const { holdingsTableFilter } = payload;
  return holdingsTableFilter ? holdingsTableFilter[tableName] : payload.product_ids;
};

export const filterHoldingsParams = (payload, productId) => {
  return pick(
    [
      'page_num',
      'page_size',
      'contract_types',
      'after',
      'product_ids',
      'before',
      'start_time',
      'end_time',
    ],
    payload
  );
};

export const fiatCryptoConversionParams = payload => {
  return pick(
    ['fiatCurrency', 'cryptoCurrency', 'paymentMethod', 'fiatAmount', 'network'],
    payload
  );
};
