import keyMirror from 'helpers/keymirror';

const OFFRAMP_ACTION_TYPES = keyMirror({
  FETCH_FIAT_WITHDRAWAL_ASSETS: null,
  FETCH_USER_BANK_DETAILS: null,
  FETCH_USER_UPI_DETAILS: null,
  SUBMIT_CREATE_ALPYNE_QUOTATION: null,
  SUBMIT_CREATE_ONMETA_QUOTATION: null,
  SUBMIT_CREATE_MUDREX_QUATATION: null,
  SUBMIT_BANK_DETAILS_REGISTER_SEND_OTP: null,
  SUBMIT_BANK_DETAILS_REGISTER_VALIDATE_OTP: null,
  SUBMIT_BANK_DETAILS_REGISTER_RESEND_OTP: null,
  SUBMIT_UPI_DETAILS_REGISTER_SEND_OTP: null,
  SUBMIT_UPI_DETAILS_REGISTER_VALIDATE_OTP: null,
  SUBMIT_UPI_DETAILS_REGISTER_RESEND_OTP: null,
  SUBMIT_BANK_DETAILS_DELETE: null,
  SUBMIT_UPI_DETAILS_DELETE: null,
  SUBMIT_FIAT_WITHDRAWAL_CREATE: null,
  SUBMIT_FIAT_WITHDRAWAL_CONFIRMATION_CODE: null,
});

export { OFFRAMP_ACTION_TYPES };
