import keyMirror from '../helpers/keymirror';

export const UNAUTHORIZED = 'UNAUTHORIZED';
export const RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED';
export const USER_CONNECTED = 'USER_CONNECTED';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_LOGIN_REDIRECTION = 'USER_LOGIN_REDIRECTION';
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL';
export const USER_DISCONNECTED = 'USER_DISCONNECTED';
// export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
// export const UPDATE_HEARTBEAT_TS = 'UPDATE_HEARTBEAT_TS';
export const SIGNUP_API_BLOCKED = 'SIGNUP_API_BLOCKED';
export const SIGNUP_CLICKED = 'SIGNUP_CLICKED';
export const LOGIN_API_BLOCKED = 'LOGIN_API_BLOCKED';
export const LOGIN_CLICKED = 'LOGIN_CLICKED';
export const SWITCH_TO_MAIN_ACCOUNT = 'SWITCH_TO_MAIN_ACCOUNT';
export const SHOW_KYC_REFRESH_POPUP = 'SHOW_KYC_REFRESH_POPUP';
export const SHOW_REDUCE_ONLY_POPUP = 'SHOW_REDUCE_ONLY_POPUP';
export const CLOSE_PASSWORD_RESET_POPUP = 'CLOSE_PASSWORD_RESET_POPUP';
export const CLOSE_2FA_RESET_POPUP = 'CLOSE_2FA_RESET_POPUP';
export const SET_PHONE_VERIFICATION_TOKEN = 'SET_PHONE_VERIFICATION_TOKEN';

export const SET_TRACKIER_ID = 'USER/SET_TRACKIER_ID';

const actionTypes = keyMirror({
  LOGIN: null,
  REFRESH_TOKEN: null,
  LOGOUT: null,
  SIGNUP: null,
  CHECK_REFERRAL_CODE: null,
  UPDATE_PROFILE: null,
  GET_KYC_STATUS: null,
  GET_IDFY_LINK: null,
  GET_KYC_CERTIFICATES: null,
  GET_MOBILE_VERIFICATION_OTP: null,
  VERIFY_MOBILE_OTP: null,
  RESEND_MOBILE_OTP: null,
  CHANGE_PASSWORD: null,
  CHANGE_PASSWORD_NEW: null,
  CHANGE_PASSWORD_VALIDATION: null,
  CHANGE_PASSWORD_VERIFICATION_CODE: null,
  ENABLE_MFA: null,
  ENABLE_MFA_SEND_EMAIL_OTP: null,
  RESET_MFA: null,
  DISABLE_MFA: null,
  MARK_INTRO_SEEN: null,
  FORGOT_PASSWORD: null,
  RESET_PASSWORD: null,
  CREATE_API_KEY: null,
  UPDATE_API_KEY: null,
  GET_API_KEYS: null,
  DELETE_API_KEY: null,
  MY_REFERRALS: null,
  APPLY_PROMO: null,
  REMOVE_PROMO: null,
  GET_APPLIED_PROMOS: null,
  GET_ACTIVITY_LIST: null,
  LOGINMFA: null,
  LOGIN_EMAIL_VERIFICATION: null,
  PHONE_OTP_VERIFICATION: null,
  RESEND_LOGIN_EMAIL_CODE: null,
  RESEND_LINK: null,
  EMAIL_VERIFICATION_TOKEN: null,
  UPDATE_PREFERENCES: null,
  GET_OFFERS: null,
  CREATE_WITHDRAWAL_ADDRESS: null,
  UPDATE_WITHDRAWAL_ADDRESS: null,
  GET_WITHDRAWAL_ADDRESSES: null,
  DELETE_WITHDRAWAL_ADDRESS: null,
  WITHDRAWAL_PENDING_COUNT: null,
  GET_ACCOUNT_ACTIVITY: null,
  GET_ACTIVE_SESSIONS: null,
  LOGOUT_FROM_ALL_DEVICES: null,
  GET_USER_PROFILE: null,
  GET_ACCOUNT_ANALYTICS_PNL_SUMMARY: null,
  GET_ACCOUNT_ANALYTICS_DETO_CHART: null,
  GET_ACCOUNT_ANALYTICS_PNL_CHART: null,
  GET_ACCOUNT_ANALYTICS_ACTIVITY: null,
  GET_ACCOUNT_ANALYTICS_STATISTICS: null,
  GET_ACCOUNT_ANALYTICS_DAILY_EQUITY_CHANGE: null,
  GET_ACCOUNT_ANALYTICS_PNL_CHART_DATA: null,
  GET_ACCOUNT_ANALYTICS_VOLUME: null,
  GET_ACCOUNT_ANALYTICS_FEES_PAID: null,
  CREATE_SUB_ACCOUNT: null,
  UPDATE_SUB_ACCOUNT: null,
  GET_SUB_ACCOUNTS: null,
  GET_ACCOUNT_TOKEN: null,
  TRANSFER_FUNDS_SUB_ACCOUNTS: null,
  TRANSFER_FUNDS_HISTORY_SUB_ACCOUNTS: null,
  UNVERIFIED_USERS_WITHDRAWAL_LIMIT: null,
  GET_KYC_DOCUMENTS: null,
  GET_CONSENT_POPUP_DETAILS: null,
  REFRESH_KYC: null,
  ADD_PHONE_NUMBER: null,
  VERIFY_PHONE_NUMBER_OTP: null,
  RESEND_PHONE_NUMBER_OTP: null,
  EMAIL_VERIFICATION_WITH_LINK: null,
  GET_KYC_VENDOR_LINK: null,
  SEND_OTP_ON_PHONE_NUMBER: null,
  FRESH_DESK_TICKET_REQUEST_OTP: null,
  FRESH_DESK_RESEND_OTP: null,
  FRESH_DESK_TICKET_VALIDATION: null,
  ACCOUNT_EQUITY_CHART: null,
  DEPOSIT_WITHDRAWALS_CHART: null,
  COUNTRY_FROM_IP: null,
  SHARE_KYC_DATA_ONMETA: null,
  CREATE_ALPYNE_USER_KYC: null,
  EMAIL_VERIFICATION_WITH_OAUTH: null,
  GET_TRADE_HISTORY: null,
  FETCH_TRADE_HISTORY_RECORDS: null,
  IP_ADDRESS: null,
  SHOW_KYC_SHARE_CONSENT_POPUP: null,
  BANK_ACCOUNT_ADDED: null,
  DELETE_ACCOUNT: null,
  GENERATE_RECOVERY_OTP: null,
  VERIFY_RECOVERY_OTP_AND_GET_VENDOR: null,
  DELETE_ACCOUNT_CONFIRMATION: null,
});

export const TRANSFER_CONSTANTS = {
  RESET_TRANSFER_FUND_HISTORY: 'RESET_TRANSFER_FUND_HISTORY',
};

export default actionTypes;
