import React from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { PopoverBase } from 'components/popover';
import { IS_INDIAN_EXCHANGE } from 'constants/constants';
import Popover from 'containers/Popover';
import { cropAfterDecimals } from 'helpers/utils';
import subscribe from 'HOC/subscribe';
import { balanceBySymbolSelector } from 'selectors';
import { walletSpotPriceBySymbol } from 'selectors/priceSelectors';

import iconPath from '../../styles/assets/percentage-icon-compressed.png';
import { computeFreeTradeNotional } from './free_trade_notional';
import { TradeFeeInfoWrapper } from './trade_fee_info_styles';

const mapStateToProps = state => {
  return {
    balancesBySymbol: balanceBySymbolSelector(state),
  };
};

/**
 * Check balancesBySymbol{} and return trading_fee_credit_precise for BTC and USDC
 * @param balancesBySymbol
 * @returns {[*, *]}
 */
export const getPreciseFeeCredits = balancesBySymbol => {
  let feeCreditsBTC = balancesBySymbol?.BTC?.trading_fee_credit_precise ?? 0;
  let feeCreditsUSDC = balancesBySymbol?.USDC?.trading_fee_credit_precise ?? 0;
  const feeCreditsUSDT = balancesBySymbol?.USDT?.trading_fee_credit_precise ?? 0;
  const feeCreditsUSD = balancesBySymbol?.USD?.trading_fee_credit_precise ?? 0;

  if (IS_INDIAN_EXCHANGE) {
    feeCreditsBTC = 0;
    feeCreditsUSDC = 0;
  }

  return [
    feeCreditsBTC,
    feeCreditsUSDC,
    !IS_INDIAN_EXCHANGE ? feeCreditsUSDT : feeCreditsUSD,
  ];
};

/**
 * Determine whether the trading fee message should be displayed or not using a set threshold
 * ($10 = default threshold)
 * @param freeTradeNotional
 * @param threshold
 * @returns {boolean}
 */
export const shouldDisplayTradeFeeMessage = (freeTradeNotional, threshold = 10) => {
  if (!freeTradeNotional) {
    return false;
  }
  return freeTradeNotional > threshold;
};

const TradeFeeInfo = subscribe(props => {
  const { t } = useTranslation(['trading']);

  const { balancesBySymbol } = props;
  const DEXBTUSD = walletSpotPriceBySymbol('.DEXBTUSDT');

  if (
    Object.entries(balancesBySymbol).length > 0 &&
    DEXBTUSD !== undefined &&
    DEXBTUSD !== 0
  ) {
    const [feeCreditsBTC, feeCreditsUSDC, feeCreditsUSDT] =
      getPreciseFeeCredits(balancesBySymbol);
    const computedValue = computeFreeTradeNotional(
      feeCreditsBTC,
      feeCreditsUSDC,
      feeCreditsUSDT,
      DEXBTUSD
    );

    const tradeText = t('trading:tradeForFree', {
      tradingFee: computedValue,
    });

    if (shouldDisplayTradeFeeMessage(computedValue)) {
      return (
        <TradeFeeInfoWrapper>
          <img src={iconPath} alt="Percentage Icon" className="percentage-icon" />
          <p>
            {tradeText}.
            <Popover
              body={`${t('trading:tradingCreditsPopover')} : ${cropAfterDecimals(
                feeCreditsUSDT,
                2
              )} USDT`}
              shouldEnableClickListener
            >
              {thisProps => <PopoverBase {...thisProps} />}
            </Popover>
          </p>
        </TradeFeeInfoWrapper>
      );
    }
    return null;
  }
  return null;
});

export default connect(mapStateToProps)(TradeFeeInfo);
