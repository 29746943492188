import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router';
import { combineEpics, ofType } from 'redux-observable';
import { EMPTY, from, timer } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';

// import { noOp } from 'actions/other';
import { subscribeTicker, unsubscribeTicker } from 'actions/socket';
// import {
//   get24HrTicker,
//   getAllTickers,
//   initiateTicker,
//   killTickerTimer,
//   pollOptionsTicker,
//   pollProductTicker,
// } from 'actions/trade';
// import { TAB_CHANGE } from 'actionTypes/appDrawer';
// import {
//   TOGGLE_MOBILE_HEADER_DROPDOWN,
//   TAB_INACTIVE,
//   TAB_REACTIVE,
// } from 'actionTypes/other';
import { getAllTickers, getTopFuturesMarketsData, initiateTicker, killTickerTimer } from 'actions/trade';
import { TOGGLE_MOBILE_HEADER_DROPDOWN } from 'actionTypes/other';
import { SOCKET_CONNECTED } from 'actionTypes/socket';
import { TRADE_CONSTANTS } from 'actionTypes/trade';
import { IS_INDIAN_EXCHANGE, VANILLA_SETTLING_ASSET } from 'constants/constants';
import { socketActiveSelector } from 'selectors/socketSelectors';
// import { SOCKET_CONNECTED } from 'actionTypes/socket';
// import { socketActiveSelector } from 'selectors/socketSelectors';
// import { APP_DRAWER_TAB_ID_MAPPING } from 'reducers/initialstate/appDrawer';
// import { selectedProductSelector } from 'selectors';

// import { TICKER_DELAY } from '../constants/constants';

const tickerIntializerEpic = action$ =>
  action$.pipe(
    ofType(TRADE_CONSTANTS.INITIATE_TICKER),
    switchMap(action => {
      const { contractType, frequencyTime } = action.payload;
      return timer(0, frequencyTime).pipe(
        takeUntil(action$.ofType(TRADE_CONSTANTS.KILL_TICKER_TIMER)),
        map(() => {
          const apiContractType = (() => {
            switch (contractType) {
              case 'futures':
                return 'futures,perpetual_futures';
              case 'options':
              case 'options_chain':
                return 'put_options,call_options';
              case 'futuresAndOptions':
                return 'futures,perpetual_futures,put_options,call_options,spot';
              case 'spreads':
                return 'interest_rate_swaps,spreads';
              case 'allNonOptions':
                return 'futures,perpetual_futures,spot,move_options,options_combos,interest_rate_swaps,spreads';
              case 'optionsChainAndMoveOptions':
                return 'put_options,call_options,move_options';
              case 'all':
              default:
                return 'futures,perpetual_futures,spot,move_options,options_combos,put_options,call_options,interest_rate_swaps,spreads';
            }
          })();
          return getAllTickers(apiContractType);
        })
      );
    })
  );

// const pollOptionsTickerEpic = action$ =>
//   action$.pipe(
//     ofType(TRADE_CONSTANTS.POLL_OPTIONS_TICKER),
//     switchMap(action => {
//       const { frequencyTime } = action.payload;
//       return timer(0, frequencyTime).pipe(
//         map(() =>
//           getAllTickers('put_options,call_options,turbo_put_options,turbo_call_options')
//         ),
//         takeUntil(action$.ofType(TRADE_CONSTANTS.KILL_TICKER_TIMER))
//       );
//     })
//   );

const mobileTickerCalls = params => {
  const { pageName, contractType } = params;

  if (contractType === 'options_analytics') {
    return [
      // killTickerTimer(),
      initiateTicker({
        contractType: 'optionsChainAndMoveOptions',
        frequencyTime: 10 * 1000,
      }),
      unsubscribeTicker(),
      // subscribeTicker(['call_options', 'put_options', 'move_options']),
    ];
  }

  if (contractType === 'home') {
    return [
      // killTickerTimer(),
      // initiateTicker({
      //   contractType: 'futuresAndOptions',
      //   frequencyTime: 5 * 1000,
      // }),
      killTickerTimer(),
      unsubscribeTicker(),
      subscribeTicker(IS_INDIAN_EXCHANGE ? ['BTCUSD', 'ETHUSD'] : ['BTCUSDT', 'ETHUSDT']),
    ];
  }

  switch (pageName) {
    case 'markets':
      if (contractType === 'options_chain' || contractType === 'options') {
        return [
          // killTickerTimer(),
          // pollOptionsTicker({ frequencyTime: 2500 }),
          // initiateTicker({
          //   contractType: 'allNonOptions',
          //   frequencyTime: 10000,
          // }),
          killTickerTimer(),
          unsubscribeTicker(),
          subscribeTicker(['call_options', 'put_options']),
        ];
      }

      if (contractType === 'futures') {
        return [
          killTickerTimer(),
          unsubscribeTicker(),
          subscribeTicker(['futures', 'perpetual_futures']),
        ];
      }

      return [
        // killTickerTimer(),
        // initiateTicker({
        //   contractType: 'allNonOptions',
        //   frequencyTime: 2500,
        // }),
        // pollOptionsTicker({ frequencyTime: 10000 }),
        killTickerTimer(),
        unsubscribeTicker(),
        subscribeTicker([`${contractType}`]),
      ];
    case 'trade':
      if (contractType === 'basketOrder') {
        // return [
        //   killTickerTimer(),
        //   initiateTicker({
        //     contractType: 'futuresAndOptions',
        //     frequencyTime: 2500,
        //   }),
        // ];
        return [killTickerTimer(), unsubscribeTicker(), subscribeTicker()];
      }
      return [
        killTickerTimer(),
        unsubscribeTicker(),
        subscribeTicker([`${params.productSymbol}`]),
      ];
    case 'balances':
      return [
        // killTickerTimer(),
        // initiateTicker({
        //   contractType: 'all',
        //   frequencyTime: 2500,
        // }),
        killTickerTimer(),
        unsubscribeTicker(),
        subscribeTicker(),
      ];
    default:
      return [killTickerTimer(), unsubscribeTicker(), subscribeTicker()];
  }
};

// const mobileTickerCalls = () => [];

const desktopTickerCalls = params => {
  const { pageName, contractType } = params;

  if (contractType === 'options_analytics') {
    return [
      // killTickerTimer(),
      initiateTicker({
        contractType: 'optionsChainAndMoveOptions',
        frequencyTime: 10 * 1000,
      }),
      unsubscribeTicker(),
      // subscribeTicker(['call_options', 'put_options', 'move_options']),
    ];
  }

  switch (pageName) {
    case 'balances':
      return [
        killTickerTimer(),
        // initiateTicker({
        //   contractType: 'all',
        //   frequencyTime: 2500,
        // }),
        unsubscribeTicker(),
      ];
    case 'markets': {
      const btcContract = `BTC${VANILLA_SETTLING_ASSET}`;
      const ethContract = `ETH${VANILLA_SETTLING_ASSET}`;

      if (contractType === 'options_chain') {
        return [
          killTickerTimer(),
          unsubscribeTicker(),
          subscribeTicker([btcContract, ethContract, 'call_options', 'put_options']),
        ];
      }

      if (contractType === 'futures') {
        return [
          killTickerTimer(),
          unsubscribeTicker(),
          subscribeTicker([btcContract, ethContract, 'futures', 'perpetual_futures']),
        ];
      }

      return [
        killTickerTimer(),
        unsubscribeTicker(),
        subscribeTicker([btcContract, ethContract, `${contractType}`]),
      ];
    }
    case 'trade': {
      const symbol = params?.productSymbol;
      if (!symbol) {
        return EMPTY;
      }
      if (contractType === 'options_chain' || contractType === 'options') {
        const tickerSymbol = `${symbol.split('-')[1]}-${symbol.split('-')[3]}`;
        return [
          // killTickerTimer(),
          // pollOptionsTicker({ frequencyTime: 2500 }),
          // initiateTicker({
          //   contractType: 'allNonOptions',
          //   frequencyTime: 10000,
          // }),
          killTickerTimer(),
          unsubscribeTicker(),
          subscribeTicker([tickerSymbol]),
        ];
      }
      return [
        // killTickerTimer(),
        // initiateTicker({
        //   contractType: 'allNonOptions',
        //   frequencyTime: 2500,
        // }),
        // pollOptionsTicker({ frequencyTime: 10000 }),
        killTickerTimer(),
        unsubscribeTicker(),
        subscribeTicker([symbol]),
      ];
    }
    default:
      return [killTickerTimer(), unsubscribeTicker(), subscribeTicker()];
  }
};

// const pollTickerByTabChange = action$ =>
//   action$.pipe(
//     ofType(TAB_CHANGE),
//     map(({ payload }) => payload),
//     mergeMap(tabId => {
//       console.log('DEBUG tabID', tabId);
//       if (tabId === APP_DRAWER_TAB_ID_MAPPING.POSITIONS) {
//         return from([
//           killTickerTimer(),
//           initiateTicker({
//             contractType: 'all',
//             frequencyTime: 5000,
//           }),
//         ]);
//       }
//       return EMPTY;
//     })
//   );

const subscribeTickerEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ payload }) => payload.location.pathname),
    map(path => {
      let matchedPath = matchPath(
        path,
        '/:contractType?/:pageName?/:assetSymbol?/:productSymbol?'
      );

      // if (!matchedPath) {
      //   matchedPath = matchPath(path, '/easy_options');
      // }
      if (!matchedPath) {
        matchedPath = matchPath(
          path,
          '/tradingview/:contractType/:assetSymbol/:productSymbol'
        );
      }
      return matchedPath;
    }),
    filter(Boolean),
    // switchMap to handle unsubscribing and resubscribing based on location changes
    switchMap(matchedPath =>
      // Combine the socketActiveSelector stream with the current matchedPath
      state$.pipe(
        map(socketActiveSelector),
        // Filter only when socket is active
        filter(socketIsActive => socketIsActive),
        take(1), // Take only the first active instance to proceed
        map(() => matchedPath)
      )
    ),
    mergeMap(({ params }) => {
      // const { pageName, contractType } = params;
      const mediaQueryList = window.matchMedia(`(max-width: 1024px)`);
      if (mediaQueryList.matches) {
        return from(mobileTickerCalls(params));
      }
      return from(desktopTickerCalls(params));
    })
  );

// resubscribe ticker on tab reactive based on route
const resubscribeTickerEpic = (action$, state$) =>
  action$.pipe(
    ofType(SOCKET_CONNECTED),
    map(() => state$.value.router.location.pathname),
    map(path => {
      let matchedPath = matchPath(
        path,
        '/:contractType?/:pageName?/:assetSymbol?/:productSymbol?'
      );

      // if (!matchedPath) {
      //   matchedPath = matchPath(path, '/easy_options');
      // }
      if (!matchedPath) {
        matchedPath = matchPath(
          path,
          '/tradingview/:contractType/:assetSymbol/:productSymbol'
        );
      }
      return matchedPath;
    }),
    mergeMap(({ params }) => {
      const mediaQueryList = window.matchMedia(`(max-width: 1024px)`);
      if (mediaQueryList.matches) {
        return from(mobileTickerCalls(params));
      }
      return from(desktopTickerCalls(params));
    })
  );

const subscribePositionTickerEpic = action$ =>
  action$.pipe(
    ofType(TRADE_CONSTANTS.POLL_ALL_OPEN_POSITION_TICKER),
    switchMap(action => {
      const { payload } = action;
      // return timer(0, 5000).pipe(
      //   map(() => get24HrTicker(commaSeparatedSymbols)),
      //   takeUntil(action$.ofType(TRADE_CONSTANTS.KILL_ALL_OPEN_POSITION_TICKER_TIMER))
      // );
      return [unsubscribeTicker(), subscribeTicker(payload)];
    })
  );

// ticker polling for individual product
// const pollProductTickerEpic = action$ =>
//   action$.pipe(
//     ofType(TRADE_CONSTANTS.POLL_PRODUCT_TICKER),
//     switchMap(action => {
//       const { payload } = action;
//       return timer(0, 5000).pipe(
//         map(() => (payload.symbol ? get24HrTicker(payload.symbol) : noOp())),
//         takeUntil(action$.ofType(TRADE_CONSTANTS.KILL_TICKER_TIMER))
//       );
//     })
//   );

const contractDropdownTickerEpic = action$ =>
  action$.pipe(
    ofType(TOGGLE_MOBILE_HEADER_DROPDOWN),
    mergeMap(action => {
      const { payload } = action;
      return payload
        ? from([
            (killTickerTimer(),
            initiateTicker({
              contractType: 'all',
              frequencyTime: 5000,
            })),
          ])
        : from([killTickerTimer()]);
    })
  );

const pollTopGainersLosersEpic = action$ =>
  action$.pipe(
    ofType(TRADE_CONSTANTS.POLL_TOP_GAINERS_LOSERS),
    switchMap(action => {
      return timer(0, 10000).pipe(
        map(() => getTopFuturesMarketsData({ params: { sort_by: 'top_gainers' } })),
        takeUntil(action$.ofType(TRADE_CONSTANTS.KILL_TOP_GAINERS_LOSERS_TIMER))
      );
    })
  );

// const disconnectTickerOnTabInactive = action$ =>
//   action$.pipe(
//     ofType(TAB_INACTIVE),
//     switchMap(() => {
//       return timer(TICKER_DELAY).pipe(
//         map(() => {
//           return killTickerTimer();
//         }),
//         takeUntil(action$.ofType(TAB_REACTIVE, TRADE_CONSTANTS.KILL_TICKER_TIMER))
//       );
//     })
//   );

// const repollTickerOnTabReactive = action$ =>
//   action$.pipe(
//     ofType(TAB_REACTIVE),
//     map(() => {
//       return window?.location?.pathname;
//     }),
//     map(pathWithApp => {
//       const path = pathWithApp.includes('/app') ? pathWithApp?.slice(4) : pathWithApp;
//       const matchedPath = matchPath(
//         path,
//         '/:contractType?/:pageName?/:assetSymbol?/:productSymbol?'
//       );
//       return matchedPath;
//     }),
//     filter(Boolean),
//     mergeMap(({ params }) => {
//       const mediaQueryList = window.matchMedia(`(max-width: 1024px)`);
//       if (mediaQueryList.matches) {
//         return from(mobileTickerCalls(params));
//       }
//       return from(desktopTickerCalls(params));
//     })
//   );

export default combineEpics(
  tickerIntializerEpic,
  // pollTickerByContractTypeEpic,
  // pollOptionsTickerEpic,
  subscribeTickerEpic,
  subscribePositionTickerEpic,
  contractDropdownTickerEpic,
  resubscribeTickerEpic,
  pollTopGainersLosersEpic
  // pollProductTickerEpic
  // mobileContractDropdownTickerEpic,
  // disconnectTickerOnTabInactive,
  // repollTickerOnTabReactive,
  // pollTickerByTabChange
);
