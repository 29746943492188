import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: {
        cacheBust: Date.now(),
      },
    },
    fallbackLng: 'en',
    lng: 'en',
    partialBundledLanguages: true,
    ns: [
      'common',
      'alerts',
      'errors',
      'auth',
      'markets',
      'headerFooter',
      'trading',
      'account',
      'portfolioMargin',
      'easyOptions',
      'basketOrders',
      'errorBoundary',
      'optionsAnalyticsDashboard',
      'homepage',
      'validations',
    ],
    defaultNS: 'common',
  });

i18n.on('languageChanged', language => {
  if (language) {
    // i18n.changeLanguage(language);
  }
});

export default i18n;
