/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ElementType,
  ReactNode,
  CSSProperties,
  forwardRef,
  Ref,
  JSXElementConstructor,
  ComponentPropsWithRef,
} from 'react';

import cx from 'classnames';

import { devWarning } from 'helpers/logger';

import styles from './box.module.scss';

type PropsOf<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<E, ComponentPropsWithRef<E>>;

/**
 * FontSizeSm(px) -> 8 | 10 | 12 | 12.1 | 13 | 14 | 15
 */
type FontSizeSm = 'sm0' | 'sm1' | 'sm2' | 'sm2-fix' | 'sm3' | 'sm4' | 'sm5';
/**
 * FontSizeMd(px) -> 16 | 17 | 18 | 19 | 20
 */
type FontSizeMd = 'md1' | 'md2' | 'md3' | 'md4' | 'md5';
/**
 * FontSizeLg(px) -> 22 | 24 | 26 | 28
 */
type FontSizeLg = 'lg1' | 'lg2' | 'lg3' | 'lg4' | 'lg5';
/**
 * FontSizeXl(px) -> 30 | 32 | 34 | 36 | 38
 */
type FontSizeXl = 'xl1' | 'xl2' | 'xl3' | 'xl4' | 'xl5';

interface BoxCustomProps<T extends ElementType = ElementType> {
  children?: ReactNode;
  className?: string;
  /**
   * option to conditionally render component
   */
  renderWhen?: boolean;
  scrollY?: boolean;
  scrollX?: boolean;
  /**
   * @deprecated use data-testid
   */
  testId?: string;
  'data-testid'?: string;
  /**
   * Type of HTML Element. By default "div".
   */
  as?: T;
  /**
   * light: 300
   * regular: 400
   * semi-bold: 600
   * bold: 700
   */
  fontType?: 'light' | 'regular' | 'semi-bold' | 'bold';
  /**
   * @see _variables.scss file for more details
   */
  fontSize?: FontSizeSm | FontSizeMd | FontSizeLg | FontSizeXl;
  style?: CSSProperties;
  fullWidth?: boolean;
  relative?: boolean;
  absolute?: boolean;
  /**
   * No box-shadow, outline, user-select, background
   */
  isNaked?: boolean;
  /**
   * top: 0; bottom: 0; left: 0; right: 0
   */
  noInset?: boolean;
  /**
   * @see _mixins.scss file for more details
   */
  flexPosition?:
    | 'flex-column'
    | 'flex-row'
    | 'flex-center-align'
    | 'flex-row-spread'
    | 'flex-row-spread-center'
    | 'flex-col-spread'
    | 'flex-center-vert'
    | 'flex-center-column'
    | 'flex-start-both'
    | 'flex-end-both'
    | 'flex-align-right'
    | 'flex-align-left'
    | 'flex-justify-right'
    | 'flex-justify-left';
  flex?: 'flex' | 'inline';
  flexDir?: 'row' | 'column';
  justifyContent?: 'start' | 'end' | 'center' | 'between';
  alignItems?: 'start' | 'end' | 'center' | 'baseline';
  autoMargin?: 'left' | 'right' | 'both';
  wrap?: 'wrap' | 'nowrap';
  textColor?:
    | 'negative'
    | 'positive'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'tertiary-t2'
    | 'warning'
    | 'primaryTheme'
    | 'primaryThemeIndian'
    | 'secondaryAlt'
    | 'highlight';
  backgroundColor?: 'primaryBackground' | 'secondaryBackground' | 'tertiaryBackground';
  isHeading?: boolean;
  isText?: boolean;
  /** user-select: none */
  noSelect?: boolean;
  /**
   * Flex Gap. Needs to be flex
   */
  gap?: 2 | 4 | 6 | 8 | 10 | 16 | 20;
  /** Direction of content */
  direction?: 'rtl' | 'ltr';
  lineHeight?: 'initial' | 'inherit' | 'unset' | 'normal';
}

type BoxProps<E extends ElementType> = BoxCustomProps<E> &
  Omit<PropsOf<E>, keyof BoxCustomProps>;

const defaultElement = 'div';

/**
 * @summary A generic container component that can be used to wrap any other component.
 * @param  - as - The component to render as.
 * @param  - children - The children to render.
 * @param  - className - The class name to apply to the component.
 * @param  - renderWhen - Whether or not to render the component.
 * @param  - fontType - The font family to apply to the component.
 * @param  - fontSize - The font size to apply to the component.
 * @param  - textColor - The text color to apply to the component.
 * @param  - flexPosition - The flex position to apply to the component.
 * @returns A React component that renders a div or another element type.
 *
 * @example
 * ```tsx
 * <Box as="div" testId="box-test" fontType="light" textColor="negative">
 * ```
 *
 */
const Box = forwardRef(
  (
    {
      as,
      className,
      fontType,
      fontSize,
      textColor,
      isNaked,
      flex,
      alignItems,
      autoMargin,
      flexDir,
      justifyContent,
      flexPosition,
      wrap,
      renderWhen = true,
      scrollY,
      scrollX,
      testId,
      style,
      noInset,
      relative,
      absolute,
      fullWidth,
      'data-testid': tid,
      isHeading,
      isText,
      backgroundColor,
      gap,
      noSelect,
      direction,
      lineHeight,
      children,
      ...rest
    }: BoxCustomProps,
    ref: Ref<Element>
  ) => {
    const Component: ElementType = as || 'div';

    if (!renderWhen) {
      return null;
    }

    devWarning(
      !(Boolean(relative) && Boolean(absolute)),
      'Box',
      'Both absolute & relative positions used'
    );

    const testIDMissing = (as === 'button' || as === 'a') && !testId && !tid;
    devWarning(!testIDMissing, 'Box', 'data-testid missing');
    const fontClass = `font-size-${fontSize}`;

    return (
      // @ts-ignore
      (<Component
        ref={ref}
        className={cx({
          [styles[fontType!]]: fontType,
          [fontClass]: fontSize,
          [styles[textColor!]]: textColor,
          'd-flex': flex === 'flex',
          'd-inline-flex': flex === 'inline',
          'justify-content-start': justifyContent === 'start',
          'justify-content-end': justifyContent === 'end',
          'justify-content-center': justifyContent === 'center',
          'justify-content-between': justifyContent === 'between',
          'align-items-start': alignItems === 'start',
          'align-items-end': alignItems === 'end',
          'align-items-center': alignItems === 'center',
          'align-items-baseline': alignItems === 'baseline',
          'ml-auto': autoMargin === 'left',
          'mr-auto': autoMargin === 'right',
          'mx-auto': autoMargin === 'both',
          'flex-wrap': wrap === 'wrap',
          'flex-nowrap': wrap === 'nowrap',
          [styles['flex-row']]: flexDir === 'row',
          [styles['flex-column']]: flexDir === 'column',
          [styles[flexPosition!]]: flexPosition,
          [styles[`gap-${gap}`]]: gap,
          [styles.scrollYAuto]: scrollY,
          [styles.scrollXAuto]: scrollX,
          [styles.noInset]: noInset,
          [styles.heading]: isHeading,
          [styles.text]: isText,
          [styles[backgroundColor!]]: backgroundColor,
          [styles[direction!]]: direction,
          [styles[lineHeight!]]: lineHeight,
          'w-100': fullWidth,
          naked: isNaked,
          'disable-select': noSelect,
          relative,
          absolute,
          [className!]: className,
        })}
        style={style}
        data-testid={testId || tid || 'box'}
        {...rest}
      >
        {children}
      </Component>)
    );
  }
) as <E extends ElementType = typeof defaultElement>(props: BoxProps<E>) => JSX.Element;

export type { BoxProps, BoxCustomProps };
export default Box;
