import { round } from './../../helpers/formulas';

/**
 * Converts BTC to USD using current spot price
 * @param feeCreditsBTC
 * @param DEXBTUSD
 * @returns {number}
 */
const convertBTCToUSD = (feeCreditsBTC, DEXBTUSD) => {
  return DEXBTUSD * feeCreditsBTC;
};

/**
 * Compute the value for free trade notional
 * @param feeCreditsBTC
 * @param feeCreditsUSDC
 * @param DEXBTUSD
 * @param returnRoundedValue
 * @param precision
 * @returns {*|string|number}
 */
export const computeFreeTradeNotional = (
  feeCreditsBTC,
  feeCreditsUSDC,
  feeCreditsUSDT,
  DEXBTUSD,
  returnRoundedValue = true,
  precision = 0
) => {
  const convertedBTCValue = convertBTCToUSD(feeCreditsBTC, DEXBTUSD);

  if (!returnRoundedValue) {
    return (
      convertedBTCValue / 0.00075 +
      feeCreditsUSDC / 0.0025 +
      feeCreditsUSDT / 0.0005
    );
  }

  //todo-h: Ask Goyal about how floor/ceil works in round() // Awaiting reply on Github
  return round(
    convertedBTCValue / 0.00075 +
      feeCreditsUSDC / 0.0025 +
      feeCreditsUSDT / 0.0005,
    precision,
    'floor'
  );
};
