/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { createSelector } from 'reselect';

import { newSortedProductsList } from 'components/Header/partials/headerDropdown/newSortedProductsList';
import { CURRENCY_TYPE } from 'constants/enums';
import { getDecoratedMarketProducts } from 'decorators/marketProducts';
import {
  ascend,
  concat,
  filter,
  find,
  forEachObjIndexed,
  includes,
  map,
  path,
  pathOr,
  pipe,
  propSatisfies,
  __ as ramdaPlaceholder,
  reduce,
  sort,
  sortWith,
  uniq,
  values,
} from 'helpers/ramda';
import {
  calcPrecision,
  isFutures,
  isMoveOptions,
  isOptions,
  isTurbo,
  selectedOptionChainProductList,
  sortOnCreatedAtDesc,
} from 'helpers/utils';
import { Asset } from 'types/IAsset';
import { OpenOrder as IOpenOrder } from 'types/IOpenOrders';
import { OpenPosition } from 'types/IOpenPosition';
import { ContractType, Product } from 'types/IProducts';
import IState from 'types/Istore';
import { IProductObj, ProductById, Side } from 'types/ITrade';

import { isNotEmpty } from '../ramdax';
import {
  allOpenOrdersSelector,
  allOpenPositionsSelector,
  allOpenStopOrdersSelector,
  holdingsContractTypeSelector,
  holdingsFilterType,
  openPositionsSelector,
} from './holdingsSelectors';
import {
  favoriteIdsSelector,
  fillsSelector,
  openOrdersSelector,
  openStopOrdersSelector,
  orderHistorySelector,
  toggleWatchListTickerTapeSelector,
} from './otherSelectors';

export const tradeSelector = (state: IState) => state.trade;
export const tradingCreditsSelector = (state: IState) => state.trade.tradingCredits;
// export const openPositionsSelector = (state: IState) =>
//   state.holdings.openPositions

export const prodctsOnlySelector = (state: IState) => state.trade.products as ProductById;
export const selectedAssetSelector = (state: IState) => state.trade.selected_asset;
export const assetSelector = (state: IState) => state.trade.assets;
export const spotIndicesSelector = (state: IState) => state.trade.spot_indices;
export const chartHistorySelector = (state: IState) => state.trade.chartHistory;
export const roboStrategiesSelector = (state: IState) => state.trade.roboStrategies;
export const roboStrategiesFundByIdSelector = fund_id =>
  createSelector([roboStrategiesSelector], roboFunds =>
    roboFunds.find(roboFund => roboFund.id === fund_id)
  );
export const yieldStrategiesSelector = (state: IState) => state.trade.yieldStrategies;
export const fundsListSelector = (state: IState) => state.trade.fundsList;

export const stakingPoolSelector = (state: IState) => state.trade.stakingPool;
export const stakingPoolIdsSelector = createSelector(stakingPoolSelector, stakingPools =>
  stakingPools.map(({ id }) => id)
);
export const contractTypeSelector = (state: IState) => state.trade.selectedContractType;
export const assetHistorySelector = (state: IState) => state.assetHistory;
export const fiatCurrenciesListSelector = (state: IState) =>
  state.trade.fiatCurrenciesList;
export const fiatDepositAssetsListSelector = (state: IState) =>
  state.trade.fiatDepositAssetsList;
export const minMaxDepositAmountForInrSelector = createSelector(
  fiatDepositAssetsListSelector,
  fiatDepositAssetsList => {
    const inrDetails = fiatDepositAssetsList.find(
      item => item.asset_symbol === CURRENCY_TYPE.INR
    );
    return {
      minDepositAmount: inrDetails?.min_deposit_amount,
      maxDepositAmount: inrDetails?.max_deposit_amount,
    };
  }
);
export const showPortfolioHighlighterSelector = (state: IState) =>
  state.trade.showPortfolioHighlighter;

export const getSymbolFromRouteMatch = (_: any, prop: any) =>
  pathOr(null, ['match', 'params', 'productSymbol'], prop);

export const getFilterProductId = (_: any, prop: { filterProductId: any }) =>
  prop.filterProductId || 0;

export const productsSelector = createSelector(
  [prodctsOnlySelector, assetSelector, spotIndicesSelector],
  (products, assets, spotIndices) => {
    const productsList = {
      ...products,
    };
    forEachObjIndexed((value, key) => {
      if (productsList[key] && typeof productsList[key] === 'object') {
        productsList[key].underlying_asset =
          assets?.[value?.underlying_asset_id ?? 0] || {};
        productsList[key].settling_asset =
          assets?.[value?.settling_asset_id ?? (0 as number)] || {};
        productsList[key].quoting_asset =
          assets?.[value?.quoting_asset_id ?? (0 as number)] || {};
        productsList[key].spot_index =
          spotIndices?.[value?.spot_index_id ?? (0 as number)] || {};
      }
    }, productsList);
    return productsList;
  }
);

export const productsListSelector = createSelector([productsSelector], products => {
  const isLive = (product: { state: string }) => product.state === 'live';
  return newSortedProductsList(filter(isLive, values(products)));
});

export const futuresProductsSelector = createSelector(
  [productsListSelector],
  products => {
    const isFuturesContract = (product: { contract_type: string }) =>
      product.contract_type === 'futures' ||
      product.contract_type === 'perpetual_futures';
    return filter(isFuturesContract, products);
  }
);

export const productsWithoutCallPutSelector = createSelector(
  [productsSelector],
  products => {
    const isLive = (product: Product) =>
      product.state === 'live' &&
      product.contract_type !== 'call_options' &&
      product.contract_type !== 'put_options';
    return newSortedProductsList(filter(isLive, values(products)));
  }
);

// @ts-ignore
export const favoriteProductListSelector = createSelector(
  [favoriteIdsSelector, productsSelector],
  (favoriteIds, products) => {
    const productList: any[] = [];
    if (favoriteIds && favoriteIds.length > 0) {
      favoriteIds.forEach((id: string | number) => {
        const product = products[id];
        if (product) {
          productList.push(product);
        }
      });
    }
    return productList;
  }
);

// @ts-ignore
export const tickerTapeProductsSelector = createSelector(
  // @ts-ignore
  [productsListSelector, favoriteProductListSelector, toggleWatchListTickerTapeSelector],
  (products, favoriteProducts, toggleWatchListTickerTape) => {
    if (toggleWatchListTickerTape) {
      return favoriteProducts;
    }
    const isPerpetual = (product: { contract_type: string }) =>
      product.contract_type === 'perpetual_futures';
    return filter(isPerpetual, products);
  }
);

export const getProductByID = createSelector(
  [productsSelector, getFilterProductId],
  (products, product_id) => products[product_id]
);

export const selectedContracTypeOpenPositions = createSelector(
  [openPositionsSelector, holdingsContractTypeSelector],
  (openPositions, selectedContractType) => openPositions[selectedContractType]
);

// only used in mobile holdings filterDropdown
export const mobileFilterDropdownProductsList = createSelector(
  [productsListSelector, contractTypeSelector],
  (products, selectedContractType) => {
    const isSelectedContractType = (product: { contract_type: string | string[] }) => {
      if (selectedContractType === 'spreads') {
        return (
          product.contract_type === 'spreads' ||
          product.contract_type === 'interest_rate_swaps'
        );
      }
      return product.contract_type.includes(selectedContractType);
    };
    return filter(isSelectedContractType, products);
  }
);

export const productListLengthByContractType = (contractType: string) =>
  createSelector(
    [productsListSelector],
    products =>
      filter(product => {
        switch (contractType) {
          case 'options':
            return isOptions(product.contract_type);
          case 'move_options':
            return isMoveOptions(product.contract_type);
          case 'turbo_options':
            return isTurbo(product.contract_type);
          case 'futures':
            return isFutures(product.contract_type);
          default:
            return product.contract_type === contractType;
        }
      }, products).length
  );

export const OptionChainsProductList = createSelector(
  [productsListSelector],
  products => {
    return selectedOptionChainProductList(products);
  }
);
export const assetsListSelector = createSelector([assetSelector], assets => {
  return values(assets);
});

// export const orderbooksSelector = createSelector(
//   [tradeSelector],
//   trades => trades.orderbooks
// );

// underlying asset for dropdowns
export const underlyingAssetOptionsSelector = createSelector(
  [productsSelector],
  (products: ProductById) =>
    pipe(
      sort((product: Product) => -1 * Number(product?.underlying_asset?.sort_priority)),
      filter((product: Product) => isOptions(product.contract_type)),
      reduce<Product, any>((acc, p) => {
        const asset = {
          id: p?.underlying_asset?.id as number,
          value: p?.underlying_asset?.symbol as string,
          label: p?.underlying_asset?.symbol as string,
        };
        return [asset, ...acc];
      }, []),
      uniq
    )(values(products) as any)
);

// settling asset for dropdowns
export const settlingAssetSelector = createSelector(
  [productsSelector],
  (products: ProductById) =>
    pipe(
      sort((product: Product) => -1 * Number(product?.settling_asset?.sort_priority)),
      filter((product: Product) => product.contract_type !== 'spot'),
      reduce<Product, any>((acc, p) => {
        const asset = {
          id: p?.settling_asset?.id as number,
          value: p?.settling_asset?.symbol as string,
          label: p?.settling_asset?.symbol as string,
        };
        return [asset, ...acc];
      }, []),
      uniq
    )(values(products) as any)
);

export const sparklineSelector = (state: IState) => state.trade.sparklines;

export const isOnlineSelector = (state: IState) => state.trade.isOnline;

export const offlineTimeSelector = (state: IState) => state.trade.offlineTime;

export const socketDisconnectTimeSelector = (state: IState) =>
  state.trade.socketDisconnectTime;

export const isSocketReconnecting = (state: IState) => state.trade.isSocketReconnecting;

export const bracketModalOpenSelector = (state: IState) =>
  state.orderbook.isBracketModalOpen;

export const selectedProductSelector = createSelector(
  [tradeSelector],
  trade => trade.selected_product
);

export const selectDepositAssets = createSelector(
  [tradeSelector],
  trade => trade.depositAssets
);

export const selectDepositAssetsSymbols = createSelector([selectDepositAssets], assets =>
  Object.values(assets).map(asset => asset.symbol)
);

/**
 * Selects the Contract Type of currently selected product.
 * Refer ContractType enum in 'src/types/IProducts.ts' for all possible contract types.
 */
const selectedContractTypeSelector = createSelector(
  [selectedProductSelector],
  selectedProduct => selectedProduct?.contract_type
);

export const selectedProductIdSelector = createSelector(
  [selectedProductSelector],
  selectedProduct =>
    !selectedProduct || typeof selectedProduct?.id === 'undefined'
      ? null
      : selectedProduct.id.toString()
);

export const selectedContractCurrencySelector = createSelector(
  [selectedProductSelector],
  selectedProduct =>
    !selectedProduct || typeof selectedProduct?.contract_unit_currency === 'undefined'
      ? null
      : selectedProduct.contract_unit_currency
);

export const previousSelectedProductIdSelector = createSelector(
  [tradeSelector],
  trade => trade.previousSelectedProductId
);

export const previousSelectedProductSelector = createSelector(
  [previousSelectedProductIdSelector, productsSelector],
  (previousSelectedProductId, products) =>
    previousSelectedProductId ? products[previousSelectedProductId] : {}
);

const isEqualToProductId = (productId: string) => (x: IProductObj) =>
  typeof x.product.id !== 'undefined' && x.product.id.toString() === productId;

function filterOrdersByProductId(productId: string, orders: any) {
  return filter(({ product }) => product.id.toString() === productId, orders);
}

export const selectedProductOpenOrdersSelector = createSelector(
  [selectedProductIdSelector, allOpenOrdersSelector],
  (productId, openOrders) => {
    if (productId !== null) {
      return filterOrdersByProductId(productId, openOrders);
    }
    return [];
  }
);

export const selectedProductOpenStopOrdersSelector = createSelector(
  [selectedProductIdSelector, allOpenStopOrdersSelector],
  (productId, openStopOrders) => {
    if (productId !== null) {
      return filterOrdersByProductId(productId, openStopOrders);
    }
    return [];
  }
);

export const selectedProductPositionSelector = createSelector(
  [selectedProductIdSelector, allOpenPositionsSelector],
  (productId, openPositions) => {
    if (productId !== null) {
      return find(isEqualToProductId(productId), openPositions);
    }
    return [];
  }
);

export const positionByProductIdSelector = (productId: string | null) =>
  createSelector([allOpenPositionsSelector], openPositions => {
    if (productId !== null) {
      const pos = find(isEqualToProductId(productId), openPositions);
      return pos;
    }
    return {};
  });

export const selectedProductState = createSelector(
  [selectedProductIdSelector, productsSelector],
  (productId, products) => {
    if (productId !== null) {
      return products[productId as unknown as number];
    }

    return null;
  }
);

export const selectedProductContractType = createSelector(
  [selectedProductState],
  product => product?.contract_type
);

export const selectedProductSymbol = createSelector(
  [selectedProductState],
  product => product?.symbol
);

export const selectedProductSettlingAsset = createSelector(
  [selectedProductState, productsListSelector],
  (selectedProduct, productsList) => {
    if (selectedProduct) {
      return selectedProduct.settling_asset;
    }
    if (isNotEmpty(productsList)) {
      return productsList[0].settling_asset;
    }
    return null;
  }
);
export const selectedProductPricePrecisionState = createSelector(
  [selectedProductState],
  selectedProduct => selectedProduct && calcPrecision(selectedProduct.tick_size)
);

export const orderMarginSelector = (state: IState) => state.orderbook.order_margins;

export const prefilledValuesSelector = (state: { orderbook: { prefilledValues: any } }) =>
  state.orderbook.prefilledValues;

export const OrderMarginState = createSelector(
  [selectedProductIdSelector, orderMarginSelector],
  (selectedProductId, orderMargin) =>
    selectedProductId && path([selectedProductId, 'order_margin'], orderMargin)
);

export const leverageState: (state: any) => number = createSelector(
  [selectedProductIdSelector, state => state.orderbook.order_margins],
  (selectedProductId, orderMargin): number =>
    selectedProductId && path([selectedProductId, 'leverage'], orderMargin)
);

export const openPositionState = createSelector(
  [selectedProductIdSelector, allOpenPositionsSelector],
  (selectedProductId, openPositions) =>
    find(
      // tslint:disable-next-line: no-string-literal
      op => op.product.id.toString() === selectedProductId,
      openPositions
    )
);

export const lastTradesState = (state: { trade: { lastTrades: any } }) =>
  state.trade.lastTrades;

export const lastTradeState = createSelector(
  [selectedProductIdSelector, tradeSelector],
  (selectedProductId, trade) => {
    if (selectedProductId !== null) {
      return trade.lastTrades[selectedProductId];
    }
    return null;
  }
);

export const getPrefilledValuesState = createSelector(
  [selectedProductIdSelector, prefilledValuesSelector],
  (selectedProductId, prefilledValues) => {
    return selectedProductId && prefilledValues[selectedProductId];
  }
);

type GetOrdersBySide = (
  _productId: string | null,
  open_orders: IOpenOrder[],
  side: Side
) => IOpenOrder[];
const getOrdersBySide: GetOrdersBySide = (productId, open_orders, side) => {
  // @ts-ignore
  // After moving openOrder reducer to type and replacing open_orders arguement type to reducer state type,
  // remove ts-ignore
  // After moving openOrder to productID based map, it stores data in '0' key when not product_id is provided.
  // Moved to productId based map due to API filtering.
  // const { data = [] } = open_orders || {};

  return productId && open_orders.length
    ? filter(
        order => String(order?.product?.id) === productId && order.side === side,
        open_orders
      )
    : [];
};

export const openBuyOrdersState = createSelector(
  [openOrdersSelector, selectedProductIdSelector],
  (openOrders, selectedProductId) => {
    // const selectedContract =
    //   contractType === 'options_chain' || isMoveOptions(contractType)
    //     ? 'options'
    //     : contractType;
    // console.log('openOrders', openOrders, selectedContract);
    return getOrdersBySide(selectedProductId, openOrders.all.data, 'buy');
  }
);

export const openSellOrdersState = createSelector(
  [openOrdersSelector, selectedProductIdSelector],
  (openOrders, selectedProductId) => {
    // const selectedContract = contractType === 'options_chain' ? 'options' : contractType;
    return getOrdersBySide(selectedProductId, openOrders.all.data, 'sell');
  }
);

export const openHistoryState = createSelector(
  [orderHistorySelector, selectedProductIdSelector, holdingsFilterType],
  (orderHistory, productId, filterType) => {
    const productIdByFilterType = (filterType === 'selected' ? productId : '0') as string;
    return {
      ordersHistory: pathOr([], ['data', productIdByFilterType, 'data'], orderHistory),
      totalPages: pathOr(0, ['data', productIdByFilterType, 'totalPages'], orderHistory),
      currentPageNumber: pathOr(
        0,
        ['data', productIdByFilterType, 'currentPageNumber'],
        orderHistory
      ),
      apiPageSize: pathOr(
        0,
        ['data', productIdByFilterType, 'apiPageSize'],
        orderHistory
      ),
      after: pathOr(null, ['data', productIdByFilterType, 'after'], orderHistory),
      before: pathOr(null, ['data', productIdByFilterType, 'before'], orderHistory),
      isLoading: orderHistory.loading,
    };
  }
);

export const fillsState = createSelector(
  [fillsSelector, selectedProductIdSelector, holdingsFilterType],
  (fills, productId, filterType) => {
    const productIdByFilterType = (filterType === 'selected' ? productId : '0') as string;
    return {
      ordersFills: pathOr([], ['data', productIdByFilterType, 'data'], fills),
      totalPages: pathOr(0, ['data', productIdByFilterType, 'totalPages'], fills),
      currentPageNumber: pathOr(
        0,
        ['data', productIdByFilterType, 'currentPageNumber'],
        fills
      ),
      apiPageSize: pathOr(0, ['data', productIdByFilterType, 'apiPageSize'], fills),
      after: pathOr(null, ['data', productIdByFilterType, 'after'], fills),
      before: pathOr(null, ['data', productIdByFilterType, 'before'], fills),
      isLoading: fills.loading,
    };
  }
);

const getSpreadsContract = (orders: {
  spreads: { data: any };
  interest_rate_swaps: { data: any };
}) => {
  const data: any = concat(orders.spreads.data, orders.interest_rate_swaps.data);
  return sort(sortOnCreatedAtDesc, data);
};

export const openStopOrdersState = createSelector(
  [
    openStopOrdersSelector,
    getFilterProductId,
    holdingsContractTypeSelector,
    selectedProductIdSelector,
    holdingsFilterType,
  ],
  (stopOrders, productId, contractType, id, filterType) => {
    const isSelected = (data: { product_id: { toString: () => string | null } }) =>
      data.product_id.toString() === id;
    const data =
      // eslint-disable-next-line no-nested-ternary
      filterType === 'selected'
        ? filter(isSelected, stopOrders.all.data)
        : contractType === 'spreads'
        ? getSpreadsContract(stopOrders)
        : stopOrders[contractType].data;
    return {
      stopOrders: data,
      totalOrders: data.length,
      currentPageNumber: 0,
      // apiPageSize: R.path(['newData', contractType, 'apiPageSize'], openOrders),
      isLoading: false,
      allData: stopOrders?.all?.data,
    };
  }
);

export const openOrdersState = createSelector(
  [
    openOrdersSelector,
    getFilterProductId,
    holdingsContractTypeSelector,
    selectedProductIdSelector,
    holdingsFilterType,
  ],
  (openOrders, productId, contractType, id, filterType) => {
    const isSelected = (data: { product_id: { toString: () => string | null } }) =>
      data.product_id.toString() === id;
    const data =
      // eslint-disable-next-line no-nested-ternary
      filterType === 'selected'
        ? filter(isSelected, openOrders.all.data)
        : contractType === 'spreads'
        ? getSpreadsContract(openOrders)
        : openOrders[contractType].data;
    return {
      openOrders: data,
      totalOrders: data.length,
      currentPageNumber: 0,
      // apiPageSize: R.path(['newData', contractType, 'apiPageSize'], openOrders),
      isLoading: false,
      allData: openOrders?.all?.data,
    };
  }
);

export const selectedProductOpenPositionsSelector = createSelector(
  [selectedProductIdSelector, allOpenPositionsSelector],
  (productId, openPositions) => {
    return productId
      ? filter(
          // tslint:disable-next-line: no-string-literal
          (x: OpenPosition) =>
            typeof x.product.id !== 'undefined' && x.product.id.toString() === productId,
          openPositions
        )
      : [];
  }
);

export const contractValuePrecisionSelector = createSelector(
  [assetsListSelector, selectedProductState],
  (assetsList, selectedProduct) => {
    if (selectedProduct !== null && assetsList.length > 0) {
      const asset = find(
        // @ts-ignore
        item => item.symbol === selectedProduct.contract_unit_currency,
        assetsList
      ) as Asset;
      // @ts-ignore
      return asset ? asset.precision : 1;
    }
    return 1;
  }
);

export const openPositionsProductListSelector = createSelector(
  [selectedContracTypeOpenPositions, productsSelector],
  (positions, products) => positions.data.map(({ product: { id } }) => products[id])
);

export const openOrdersProductListSelector = createSelector(
  [openOrdersState, productsSelector],
  ({ openOrders }, products) => openOrders.map(({ product: { id } }) => products[id])
);

export const stopOrdersProductListSelector = createSelector(
  [openStopOrdersState, productsSelector],
  ({ stopOrders }, products) => stopOrders.map(({ product: { id } }) => products[id])
);

export const fillsProductListSelector = createSelector(
  [fillsState, productsSelector],
  ({ ordersFills }, products) => ordersFills.map(({ product: { id } }) => products[id])
);

export const orderHistoryProductListSelector = createSelector(
  [openHistoryState, productsSelector],
  ({ ordersHistory }, products) =>
    ordersHistory.map(({ product: { id } }) => products[id])
);

export const assetHistoryState = createSelector(
  [assetHistorySelector, assetSelector, productsSelector],
  (transactions, assets, products) => {
    const transactionsData = map(
      transaction => ({
        ...transaction,
        asset: assets[transaction.asset_id],
        product: products[transaction.product_id],
      }),
      transactions.data
    );
    return {
      data: transactionsData,
      pages: transactions.pages,
      totalCount: transactions.totalCount,
      pageNumber: transactions.pageNumber,
      pageSize: transactions.pageSize,
      isLoading: transactions.isLoading,
      before: transactions.before,
      after: transactions.after,
    };
  }
);

export const sortAssetProductPriority = sortWith([
  ascend(path(['underlying_asset', 'sort_priority'])),
]);

export const filteredMarketsProductSelector = createSelector(
  [productsListSelector, contractTypeSelector],
  (products, selectedContractType) => {
    const filterProducts = products.filter(product => {
      const { contract_type, state } = product;
      switch (selectedContractType) {
        case 'options':
          return contract_type === 'move_options' && state === 'live';
        case 'turbo_options':
          return isTurbo(contract_type) && state === 'live';
        case 'spreads':
          return (
            (contract_type === 'interest_rate_swaps' || contract_type === 'spreads') &&
            state === 'live'
          );
        default:
          return (
            product.contract_type.includes(selectedContractType) &&
            product.state === 'live'
          );
      }
    });
    // const sortedProductsList = R.pipe(
    //   sortAssetProductPriority
    //   // newSortedProductsList
    // )(filterProducts);

    const sortedProductsList = newSortedProductsList(filterProducts);
    return getDecoratedMarketProducts({
      productsList: sortedProductsList,
    });
  }
);

export const statsSelector = createSelector([tradeSelector], trade => trade.stats);

export const withdrawalLimitsSelector = createSelector(
  [tradeSelector],
  trade => trade.withdrawalLimits
);

const selectOptionsChainProductList = createSelector([productsSelector], products => {
  // @todo Need to fix types here.
  const transformer = pipe<[Record<number, Product>], Array<Product>, Array<Product>>(
    values,
    filter(
      propSatisfies(
        includes(ramdaPlaceholder, [ContractType.CallOptions, ContractType.PutOptions]),
        'contract_type'
      )
    )
  );

  return transformer(products);
});

const currencyDropdownOptionSelector = createSelector(
  [selectedProductSelector],
  selectedProduct => {
    const contractType = selectedProduct?.contract_type;
    const notionalType = selectedProduct?.notional_type;
    const quotingAsset = selectedProduct?.quoting_asset;
    const underlyingAsset = selectedProduct?.underlying_asset;
    const settlingAsset = selectedProduct?.settling_asset;

    let primaryCurrency = underlyingAsset?.symbol;
    let secondaryCurrency = settlingAsset?.symbol;
    if (
      (contractType === 'futures' || contractType === 'perpetual_futures') &&
      notionalType === 'inverse'
    ) {
      primaryCurrency = quotingAsset?.symbol;
    }
    if (contractType === 'futures' || contractType === 'perpetual_futures') {
      if (notionalType === 'inverse') {
        secondaryCurrency = underlyingAsset?.symbol;
      }
      secondaryCurrency = quotingAsset?.symbol;
    }
    if (isOptions(contractType)) {
      secondaryCurrency = settlingAsset?.symbol;
    }

    if (contractType === ContractType.Spot) {
      return [primaryCurrency ?? '', secondaryCurrency ?? ''];
    }
    return [primaryCurrency ?? '', secondaryCurrency ?? '', 'Cont'];
  }
);

export {
  currencyDropdownOptionSelector,
  selectedContractTypeSelector,
  selectOptionsChainProductList
};

