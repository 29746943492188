import React, { SVGProps } from 'react';

const InfoBracketIcon = (props: SVGProps<SVGSVGElement>) => {
  const { className } = props;
  return (
    (<svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      data-palette="InfoBracketIcon">
      <path
        fill="#FFF"
        d="M6 11.833C2.778 11.833.167 9.222.167 6S2.778.167 6 .167 11.833 2.778 11.833 6 9.222 11.833 6 11.833zm0-1.166c2.577 0 4.667-2.09 4.667-4.667S8.577 1.333 6 1.333 1.333 3.423 1.333 6 3.423 10.667 6 10.667zM5.417 7.75h1.166v1.167H5.417V7.75zm0-4.667h1.166v3.5H5.417v-3.5z"
      />
    </svg>)
  );
};

export default InfoBracketIcon;
