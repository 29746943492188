import { API_STATUS } from 'constants/enums';
import { GeneralData } from 'types/GeneralData';

const GENERAL_DATA: GeneralData = {
  general: {
    apiStatus: API_STATUS.INITIAL,
    data: null,
  },
};

export default GENERAL_DATA;
