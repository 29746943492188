import { ASSET_SYMBOL } from 'constants/constants';
import { MARGIN_MODE, SIDE } from 'constants/enums';
import {
  assetPrecision,
  computeMaxQuantity,
  convertOrderBook,
  decimalMultiplication,
  isOptions,
  negativeBalanceCheck,
} from 'helpers/assetUtils';
import { round_by_contract_size as roundByContractSize } from 'helpers/formulas';
import { divide, isEmpty } from 'helpers/ramda';
import { cropAfterDecimals, isFutures, isNan, isSpotContract } from 'helpers/utils';
import { IBuySell } from 'reducers/l2Orderbook';
import { markPriceState } from 'selectors/priceSelectors';
import StoreProxy from 'storeProxy';
import { OpenPosition } from 'types/IOpenPosition';
import { ContractType, Product } from 'types/IProducts';
import { IMultiCollateral } from 'types/IVariableStore';
import { IBalance } from 'types/IWallet';

import { notional } from './commonFormulae';
import { getCrossAvailablebalance } from './crossFormulae';
import {
  contractToAssetForFutures,
  marginCalculationForFutures,
  quantityToContractsForFutures,
} from './futuresFormulae';
import {
  calculateEstimateMarginForPlaceOrderOptions,
  contractsToQuantityForOptions,
  getBuySideOffsetMax,
  getEntryPriceToCalculateOffsetMax,
  getFeePerContract,
  quantityToContractsForOptions,
  sellSideOffsetMax,
} from './optionsFormulae';
import {
  convertOneContractToOtherInSpot,
  priceToCalculateQuantitySelector,
  spotBalanceSymbol,
  spotHintText,
  spotProductAvailableBalanceWithoutTradingCredit,
} from './spotFormulae';
import { marginCalculationForSpreads } from './spreads';

const decimalHandle = (inputedQuantity: number) => {
  switch (inputedQuantity?.toString?.()) {
    case '.':
    case '0.':
    case '.0':
      return 0;
    default:
      return inputedQuantity;
  }
};

const positionSizeForSelectedProduct = (
  allOpenPositions: OpenPosition[],
  product: Product | null
) =>
  allOpenPositions.reduce((acc: number, position: OpenPosition) => {
    if (position?.product?.id === product?.id) {
      return acc + Number(position?.size);
    }
    return acc;
  }, 0);

const getBalanceSymbol = ({
  product,
  side,
  marginMode,
}: {
  product: Product;
  side: SIDE;
  marginMode: MARGIN_MODE;
}): string => {
  const contractType = product?.contract_type;
  let balanceSymbol = product?.settling_asset?.symbol;

  if (isSpotContract(contractType)) {
    balanceSymbol = spotBalanceSymbol({ side, product });
  }

  if (marginMode === MARGIN_MODE.CROSS && !isSpotContract(contractType)) {
    balanceSymbol = ASSET_SYMBOL;
  }
  return balanceSymbol ?? '';
};

const getAvailableBalance = ({
  marginMode,
  tradingCredits,
  multiCollateralData,
  productBalance,
  contractType,
  isBuyAction,
  balanceSymbol,
  side,
}: {
  marginMode: MARGIN_MODE;
  tradingCredits: Record<string, { amount: number }>;
  multiCollateralData: IMultiCollateral;
  productBalance: IBalance;
  contractType: ContractType;
  isBuyAction: boolean;
  balanceSymbol: string;
  side: SIDE;
}): number => {
  let balance: number = Number(productBalance?.available_balance);

  if (marginMode === MARGIN_MODE.CROSS) {
    balance = getCrossAvailablebalance({
      side,
      contractType,
      multiCollateralData,
      productBalance,
      balanceSymbol,
    });
  }

  /**
   * User should not be allowed to purchase through spot market using trading credits.
   * So deducting trading credits from balance.
   */
  if (contractType === ContractType.Spot && isBuyAction) {
    balance = spotProductAvailableBalanceWithoutTradingCredit({
      productBalance,
      tradingCredits,
      balance,
    });
  }

  return balance;
};

const getSelectedProductBalance = (
  product: Product | null,
  allBalances: { [symbol: string]: IBalance },
  marginMode: MARGIN_MODE,
  tradingCredits: Record<string, { amount: number }>,
  multiCollateralData: IMultiCollateral,
  side: SIDE
) => {
  const contractType = product?.contract_type ?? ContractType.Futures;
  const isBuyAction = side === SIDE.BUY;
  const balanceSymbol = getBalanceSymbol({
    product,
    side,
    marginMode,
  });

  const productBalance = allBalances?.[balanceSymbol ?? ''];
  const precision = productBalance && assetPrecision(productBalance?.asset ?? {});
  const balance = getAvailableBalance({
    marginMode,
    tradingCredits,
    multiCollateralData,
    productBalance,
    contractType,
    isBuyAction,
    balanceSymbol,
    side,
  });

  const roundedBalance = negativeBalanceCheck(balance, precision);

  return {
    balance,
    roundedBalance,
    balanceSymbol,
  };
};

const getOptionsOffsetMax = ({
  availableBalance,
  orderbook,
  product,
  limitPrice,
  side,
  spotPrice,
  takerCommissionRate,
  totalPositionSize,
  leverage,
}: {
  availableBalance: number;
  orderbook: IBuySell[];
  product: Product;
  limitPrice: number | null;
  side: SIDE;
  spotPrice: number;
  takerCommissionRate: number;
  totalPositionSize: number;
  leverage: number;
}) => {
  const contractValue = product?.contract_value;
  const contractType = product?.contract_type;

  const entryPrice = getEntryPriceToCalculateOffsetMax({
    availableBalance,
    orderbook,
    product,
    side,
    limitPrice,
  });
  const feePerContract = getFeePerContract({
    entryPrice,
    spotPrice,
    takerCommissionRate,
    contractValue,
  });

  if (side === SIDE.BUY) {
    return getBuySideOffsetMax({
      availableBalance,
      entryPrice,
      contractValue,
      feePerContract,
      totalPositionSize,
      product,
    });
  }
  return sellSideOffsetMax({
    availableBalance,
    contractValue,
    feePerContract,
    totalPositionSize,
    product,
    contractType,
    spotPrice,
    leverage,
  });
};

const getMaxQuantity = (
  product,
  orderbooks,
  side,
  leverage,
  { balance },
  allSpotPrice,
  allMarkPrice,
  totalPositionSize,
  limitPrice
): number => {
  const contractType = product?.contract_type;
  if (isSpotContract(contractType)) {
    return 0;
  }

  if (!product || isEmpty(balance)) {
    return 0;
  }

  const id = product?.id;
  const spotIndex = product?.spot_index;
  const contractValue = product?.contract_value;
  const takerCommissionRate = product?.taker_commission_rate;
  const notionalType = product?.notional_type;
  const spotPrice = allSpotPrice?.[spotIndex?.symbol];
  const markPrice = allMarkPrice?.[id];
  const availableBalance = Math.max(balance, 0.0);
  const orderbook = convertOrderBook(orderbooks, side);

  if (isOptions(contractType)) {
    return getOptionsOffsetMax({
      availableBalance,
      orderbook,
      product,
      limitPrice,
      side,
      spotPrice,
      takerCommissionRate,
      totalPositionSize,
      leverage,
    });
  }

  return computeMaxQuantity({
    availableBalance,
    entryPrice: limitPrice || Number(markPrice),
    leverage,
    isVanillaProduct: notionalType === 'vanilla',
    totalPositionSize,
    isBuyActionState: side === SIDE.BUY,
    commissionRate: takerCommissionRate,
    contractValue,
    product,
    spotPrice,
    markPrice,
  });
};

const getNonSpotMaxSize = (
  product: Product | null,
  orderbooks: { buy: IBuySell[]; sell: IBuySell[] },
  side: SIDE,
  leverage: number,
  { balance }: { balance: number },
  allSpotPrice: Record<string, number>,
  allMarkPrice: Record<string, number>,
  totalPositionSize: number,
  limitPrice: number | null
): number => {
  const contractType = product?.contract_type;
  if (contractType === 'spot') {
    return 0;
  }

  if (!product || isEmpty(balance)) {
    return 0;
  }

  const id = product?.id;
  const spotIndex = product?.spot_index;
  const contractValue = Number(product?.contract_value ?? '1');
  const takerCommissionRate = product?.taker_commission_rate;
  const notionalType = product?.notional_type;
  const spotPrice = allSpotPrice?.[spotIndex?.symbol];
  const markPrice = allMarkPrice?.[id];
  const availableBalance = Math.max(balance, 0.0);
  const orderbook = convertOrderBook(orderbooks, side);

  if (isOptions(contractType)) {
    return getOptionsOffsetMax({
      availableBalance,
      orderbook,
      product,
      limitPrice,
      side,
      spotPrice,
      takerCommissionRate,
      totalPositionSize,
      leverage,
    });
  }

  return computeMaxQuantity({
    availableBalance,
    entryPrice: limitPrice || Number(markPrice),
    leverage,
    isVanillaProduct: notionalType === 'vanilla',
    totalPositionSize,
    isBuyActionState: side === SIDE.BUY,
    commissionRate: takerCommissionRate,
    contractValue,
    product,
    spotPrice,
    markPrice,
  });
};

const convertQuantityValueToNumberOfContracts = (
  inputedQuantity: number,
  selectedProduct: Product,
  side: SIDE
) => {
  const settlingAsset = selectedProduct?.settling_asset;
  const settlingAssetSymbol = settlingAsset?.symbol;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const underlyingAssetSymbol = underlyingAsset?.symbol;
  const contractValue = Number(selectedProduct?.contract_value ?? '1');

  const state = StoreProxy.getState();
  const selectedCurrency = state.placeOrder?.dropdownValue;

  const markPrice = isNan(markPriceState()) ? 0 : Number(markPriceState());
  let price = markPrice;

  if (!inputedQuantity || !price || inputedQuantity === 0) return 0;
  const contractType = selectedProduct?.contract_type;
  if (!String(inputedQuantity).length) {
    return 0;
  }

  if (isSpotContract(contractType)) {
    price = priceToCalculateQuantitySelector({
      selectedProduct,
      dropDownValue: selectedCurrency,
      inputedQuantity,
      side,
    });
  }

  if (isFutures(contractType)) {
    return quantityToContractsForFutures({
      inputedQuantity,
      selectedProduct,
      selectedCurrency,
    });
  }

  if (isOptions(contractType)) {
    return quantityToContractsForOptions({
      inputedQuantity,
      selectedProduct,
      selectedCurrency,
    });
  }

  if (selectedCurrency === underlyingAssetSymbol) {
    return parseInt(String(divide(inputedQuantity, contractValue)), 10);
  }

  if (selectedCurrency === settlingAssetSymbol) {
    const cXp: number = Number(decimalMultiplication(contractValue, price));
    return parseInt(String(divide(inputedQuantity, cXp)), 10);
  }

  return parseInt(String(Number(decimalHandle(inputedQuantity))), 10);
};

/**
 * Calculate the default contract value (the value for first option in dropdown) based on the order size, contract value, product type, and the quoting and underlying assets.
 * i.e. if the first quantity input dropdown is BTC and second is USDT. this fn converts `number of contracts` to BTC
 * @param orderSize The size of the order in number of contracts.
 * @param contractValue The value of a single contract in the settling asset.
 * @param productType The type of the product, either 'inverse' or 'linear'.
 * @param quotingAsset The asset that the order is quoted in.
 * @param underlyingAsset The asset that the contract is settled in.
 * @returns The default contract value in the settling asset.
 */

const calculateDefaultContractValue = (
  orderSize: number,
  contractValue: number,
  productType: string,
  quotingAsset: { minimum_precision: number },
  underlyingAsset: { minimum_precision: number }
) => {
  // Early return for invalid orderSize to avoid unnecessary calculations
  if (!String(orderSize).length) return 0;

  // Use ternary operator for concise precision determination
  const precision =
    productType === 'inverse'
      ? quotingAsset.minimum_precision
      : underlyingAsset?.minimum_precision || 2;

  // Consolidate Number conversions and toFixed operation
  return Number((orderSize * contractValue).toFixed(precision));
};

/**
 * Convert the number of contracts to the selected currency from the quantity input dropdown.
 * @param qtyInContracts The number of contracts to convert.
 * @param selectedProduct The product to convert from.
 * @param asset Selected dropdown value from the quantity input dropdown.
 * @param price The price of the asset.
 * @returns The number of contracts in the selected currency.
 */

const convertNumberOfContractsToSelectedCurrency = (
  qtyInContracts: number,
  selectedProduct: Product,
  asset: string,
  side: SIDE
) => {
  if (asset === 'Cont') return qtyInContracts;

  const settlingAsset = selectedProduct?.settling_asset;
  const settlingAssetSymbol = settlingAsset?.symbol;
  const underlyingAsset = selectedProduct?.underlying_asset;
  const underlyingAssetSymbol = underlyingAsset?.symbol;
  const contractValue = Number(selectedProduct?.contract_value ?? '1');

  const contractType = selectedProduct?.contract_type;

  // Formulas
  if (isFutures(contractType)) {
    return contractToAssetForFutures({
      orderSize: qtyInContracts,
      selectedProduct,
      selectedCurrency: asset,
    });
  }

  if (isOptions(contractType)) {
    return contractsToQuantityForOptions({
      orderSize: qtyInContracts,
      selectedProduct,
      selectedCurrency: asset,
    });
  }

  if (isSpotContract(contractType)) {
    return convertOneContractToOtherInSpot({
      selectedProduct,
      selectedCurrency: asset,
      orderSize: qtyInContracts,
      side,
    });
  }

  const formula = qtyInContracts * contractValue;
  if (asset === underlyingAssetSymbol) {
    return cropAfterDecimals(formula, underlyingAsset?.minimum_precision);
  }

  if (asset === settlingAssetSymbol) {
    const markPrice = isNan(markPriceState()) ? 0 : Number(markPriceState());
    return cropAfterDecimals(formula * markPrice, settlingAsset?.minimum_precision);
  }

  return parseInt(String(Number(decimalHandle(qtyInContracts))), 10);
};

/**
 * Calculate the hint text to show in the quantity input.
 * @param quantityCurrency The currency of the quantity input dropdown.
 * @param selectedProduct The product to calculate the hint text for.
 * @param qtyInContracts The value of the quantity input dropdown in terms of contracts or the selected currency in spot.
 * @returns The hint text to show in the quantity input dropdown.
 */
const hintTextToShowInQuantityInput = (
  quantityCurrency: string,
  selectedProduct: Product | null,
  qtyInContracts: number | null,
  side: SIDE,
  actualQtyValue: number | null
): string | null => {
  if (actualQtyValue === null) {
    // placeholder and hint text were overlapping
    return null;
  }
  if (isSpotContract(selectedProduct?.contract_type)) {
    return spotHintText(quantityCurrency, selectedProduct ?? {}, qtyInContracts, side);
  }

  if (!qtyInContracts || qtyInContracts < 0) {
    return '';
  }

  return quantityCurrency !== 'Cont'
    ? `~${qtyInContracts} Cont`
    : `~${roundByContractSize(
        qtyInContracts * Number(selectedProduct?.contract_value),
        Number(selectedProduct?.contract_value ?? 0)
      )} ${selectedProduct?.contract_unit_currency}`;
};

const calculateEstimateMarginForPlaceOrder = (
  size: number,
  limitPrice: number | null,
  selectedProduct: Product | null,
  side: SIDE,
  leverage: number
) => {
  const price = limitPrice ?? markPriceState();

  const contractType = selectedProduct?.contract_type;

  if (
    isOptions(contractType) ||
    contractType === ContractType.TurboCallOptions ||
    contractType === ContractType.TurboPutOptions
  ) {
    return calculateEstimateMarginForPlaceOrderOptions(
      size,
      price, // should be premium
      selectedProduct,
      side,
      leverage
    );
  }

  if (contractType === ContractType.Spreads) {
    return marginCalculationForSpreads(size, leverage, selectedProduct);
  }

  if (isFutures(contractType)) {
    return marginCalculationForFutures(size, price, leverage, selectedProduct);
  }

  return notional(size, price, selectedProduct);
};

export {
  calculateDefaultContractValue,
  calculateEstimateMarginForPlaceOrder,
  convertNumberOfContractsToSelectedCurrency,
  convertQuantityValueToNumberOfContracts,
  decimalHandle,
  getAvailableBalance,
  getBalanceSymbol,
  getMaxQuantity,
  getNonSpotMaxSize,
  getOptionsOffsetMax,
  getSelectedProductBalance,
  hintTextToShowInQuantityInput,
  positionSizeForSelectedProduct,
};
