export const MOBILE_ORDER_BOOK_OVER_LAYOUT_CONSTANT = {
  SET_ACTIVE_ORDER_BOOK_PANEL_OVERLAY: 'SET_ACTIVE_ORDER_BOOK_PANEL_OVERLAY',
  SET_ACTIVE_ORDER_BOOK_MENU_OVERLAY: 'SET_ACTIVE_ORDER_BOOK_MENU_OVERLAY',
  SET_ACTIVE_ORDER_BOOK_INPUT_FOCUS_STATUS:
    'SET_ACTIVE_ORDER_BOOK_INPUT_FOCUS_STATUS',
  RESET_ACTIVE_ORDER_BOOK_INPUT_FOCUS_STATUS:
    'RESET_ACTIVE_ORDER_BOOK_INPUT_FOCUS_STATUS',
  TOGGLE_ACTIVE_ORDER_BOOK_PANEL_OVERLAY:
    'TOGGLE_ACTIVE_ORDER_BOOK_PANEL_OVERLAY',
  RESET: 'REST_MOBILE_ORDER_BOOK_OVERLAY',
  SET_OVERLAY_IN_PROGRESS: 'SET_OVERLAY_IN_PROGRESS',
  SET_OVERLAY_MANUAL_TOGGLE: 'SET_OVERLAY_MANUAL_TOGGLE',
  SET_OVERLAY_TARGET_ELEMENT: 'SET_OVERLAY_TARGET_ELEMENT',
};
