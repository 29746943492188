import keyMirror from '../helpers/keymirror';

export const STORE_UNREAD_BELL_ALERTS = 'STORE_UNREAD_BELL_ALERTS';
/**
 * @type {Record<string, Record<"FAIL" | "LOAD" | "SUCCESS", string>>}
 */
const actionTypes = keyMirror({
  MARK_AS_READ: null,
  GET_BELL_ALERTS: null,
});

export default actionTypes;
