import keyMirror from '../helpers/keymirror';

export const TOGGLE_BASKET_ORDERS_VIEW = 'TOGGLE_BASKET_ORDERS_VIEW';
export const CHANGE_BASKET_ORDERS_SCREEN = 'CHANGE_BASKET_ORDERS_SCREEN';
export const ADD_PRODUCT_TO_BASKET = 'ADD_PRODUCT_TO_BASKET';
export const DELETE_PRODUCT_FROM_BASKET = 'DELETE_PRODUCT_FROM_BASKET';
export const EDIT_ORDER_FROM_BASKET = 'EDIT_ORDER_FROM_BASKET';
export const DELETE_ALL_ORDERS_FROM_BASKET = 'DELETE_ALL_ORDERS_FROM_BASKET';
export const SUBSCRIBE_BASKET_ORDER_ORDERBOOK = 'SUBSCRIBE_BASKET_ORDER_ORDERBOOK';
export const UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK = 'UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK';
export const SHOW_BASKET_ERROR = 'SHOW_BASKET_ERROR';
export const DELETE_BASKET_RESULT = 'DELETE_BASKET_RESULT';
export const CHANGE_BASKET_ORDERS_CONTRACT_TYPE = 'CHANGE_BASKET_ORDERS_CONTRACT_TYPE';
export const CHANGE_BASKET_ORDERS_UNDERLYING_ASSET = 'CHANGE_BASKET_ORDERS_UNDERLYING_ASSET';
export const TOGGLE_BASKET_ORDER_MOBILE_ASSET_DROPDOWN = 'TOGGLE_BASKET_ORDER_MOBILE_ASSET_DROPDOWN';

/**
 * @type {Record<string, Record<"FAIL" | "LOAD" | "SUCCESS", string>>}
 */
const actionTypes = keyMirror({
  ESTIMATE_MARGIN: null,
  PLACE_ORDER: null,
});

export default actionTypes;
