import { ASSET_SYMBOL, MARGIN_TYPES } from 'constants/constants';
import { MarginFloorTypes } from 'types/IAsset';
import { ContractType, Product } from 'types/IProducts';

export interface EnabledPFs {
  [key: string]: MarginFloorTypes;
}

interface WarningMsgProps {
  marginMode: string;
  selectedProduct: Product;
  enabledPfs: EnabledPFs;
}

export const getWarningMessage = ({
  marginMode,
  selectedProduct,
  enabledPfs,
}: WarningMsgProps) => {
  const {
    settling_asset: settlingAsset,
    spot_index: spotIndex,
    contract_type: contractType,
  } = selectedProduct;
  let msgCode = '';

  switch (contractType) {
    case ContractType.Futures:
    case ContractType.PerpetualFutures:
    case ContractType.CallOptions:
    case ContractType.PutOptions:
      if (marginMode === MARGIN_TYPES.PORTFOLIO) {
        if (settlingAsset?.symbol === ASSET_SYMBOL && !enabledPfs[spotIndex?.symbol!]) {
          msgCode = 'pf_disabled';
        } else if (settlingAsset?.symbol !== ASSET_SYMBOL) {
          msgCode = 'only_isolated';
        }
      }

      if (marginMode === MARGIN_TYPES.CROSS && settlingAsset?.symbol !== ASSET_SYMBOL) {
        msgCode = 'only_isolated';
      }
      break;
    default:
      break;
  }

  return msgCode;
};
