import React from 'react';

import styled from 'styled-components';

const LoaderStyle = styled.div`
  position: relative;
  display: flex;
  width: 60px;
  height: 9px;

  &.login-loader {
    .lds-ellipsis {
      position: absolute;
      top: 11px;
      right: -17px;
      width: 4px;
      height: 4px;
      border-radius: 5px;
      background-color: ${props => props.theme.primaryTheme};
      color: ${props => props.theme.primaryTheme};
      animation: dotFlashing 0.5s infinite linear alternate;
      animation-delay: 0.25s;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        width: 4px;
        height: 4px;
        border-radius: 5px;
        background-color: ${props => props.theme.primaryTheme};
        color: ${props => props.theme.primaryTheme};
        animation: dotFlashing 0.5s infinite alternate;
      }

      &::before {
        left: -8px;
        animation-delay: 0s;
      }

      &::after {
        left: 8px;
        animation-delay: 0.5s;
      }

      @keyframes dotFlashing {
        0% {
          background-color: ${props => props.theme.primaryTheme};
        }
        50%,
        100% {
          background-color: ${props => props.theme.searchBorder};
        }
      }
    }
  }

  &.register-loader {
    position: absolute;
    top: -20px;
    left: 40%;
    .lds-ellipsis div {
      background-color: ${props => props.theme.primaryText};
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        background-color: ${props => props.theme.primaryText};
      }
    }
  }

  .lds-ellipsis {
    display: inline-block;
    width: 32px;
  }
  .lds-ellipsis div {
    position: absolute;
    /* top: 32px; */
    width: 9px;
    height: 9px;
    border-radius: 50%;
    /* margin-top: 12px;
    margin-left: 12px; */
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    background-color: ${props => props.theme.loaderChild1Color};
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    background-color: ${props => props.theme.loaderChild2Color};
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    background-color: ${props => props.theme.loaderChild3Color};
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
`;

export const LoginLoader = ({ className }) => (
  <LoaderStyle className={className}>
    <div className="lds-ellipsis" />
  </LoaderStyle>
);

const Loader = ({ className, 'data-testid': tid = '' }) => (
  <LoaderStyle className={className}>
    <div className="lds-ellipsis" data-testid={tid}>
      <div />
      <div />
      <div />
    </div>
  </LoaderStyle>
);

Loader.defaultProps = {
  className: '',
};

export default Loader;
