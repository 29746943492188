/* eslint-disable import/no-unresolved */
import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
import createReducer from 'helpers/createReducer';
import { getUniqListByKey } from 'helpers/utils';
import { IAssetHistory } from 'types/IAssetHistory';
// import { calcPages, extractPaginationHeaders } from './helpers/trade';

// Holds asset history data which shows up in transaction logs page.

const initialState: IAssetHistory = {
  data: [],
  pages: 0,
  totalCount: 0,
  pageSize: 0,
  pageNumber: 0,
  isLoading: true,
  assetIds: '',
  transactionTypes: '',
  startTime: '',
  endTime: '',
};

export default createReducer(initialState, {
  [TRADE.ASSET_HISTORY.LOAD]: (state: IAssetHistory, action) => {
    return {
      ...state,
      isLoading: !action.params.backgroundFetch,
    };
  },
  [TRADE.ASSET_HISTORY.SUCCESS]: (state: IAssetHistory, action) => {
    const { result, meta } = action.result.body;
    const {
      asset_ids: assetIds,
      end_time: endTime,
      start_time: startTime,
      transaction_types: transactionTypes,
    } = action.result.params;
    const updatedData = getUniqListByKey('uuid')([...state.data, ...result]);
    const data = {
      ...state,
      data: updatedData,
      isLoading: false,
      pages: meta.total_count / meta.limit,
      totalCount: meta.total_count,
      pageSize: meta.limit,
      pageNumber: meta.before ? state.pageNumber + 1 : 0,
      after: meta.after,
      before: meta.before,
      assetIds,
      transactionTypes,
      startTime,
      endTime,
    };
    return data;
  },
  [TRADE.ASSET_HISTORY.FAIL]: state => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [TRADE_CONSTANTS.RESET_ASSET_HISTORY]: state => {
    return {
      ...state,
      ...initialState,
      after: null,
      before: null,
    };
  },
});
