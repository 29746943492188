import styled from 'styled-components';

export const TradeFeeInfoWrapper = styled.div`
  display: flex;
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: ${props => props.theme.primaryBackground};
  color: ${props => props.theme.primaryText};
  border: none;
  justify-content: center;
  align-items: center;

  p {
    font-size: 10px;
    text-align: center;
    line-height: 2;
  }

  .percentage-icon {
    height: 15px;
    width: auto;
    margin: 3px 8px 0 0;
  }

  .learn-more-link {
    text-decoration: underline;
    color: ${props => props.theme.primaryTheme};
  }
`;
