/* eslint-disable camelcase */
/* eslint-disable import/named */
import { ReactNode } from 'react';

import * as R from 'ramda';

import {
  MIN_DESKTOP_ORDERBOOK_LEVELS_BOTH,
  MIN_DESKTOP_ORDERBOOK_LEVELS_SINGLE,
  MIN_MOBILE_ORDERBOOK_LEVELS_BOTH,
  MIN_MOBILE_ORDERBOOK_LEVELS_SINGLE,
  MIN_DESKTOP_SPOT_ORDERBOOK_LEVELS_SINGLE,
  MIN_OPTIONS_CHAIN_ORDERBOOK_LEVELS,
  MIN_DESKTOP_SPOT_ORDERBOOK_LEVELS_BOTH,
  ROUND_DOWN,
  ROUND_UP,
} from 'constants/constants';
import { calculate_depth_percentage, round_by_tick_size } from 'helpers/formulas';
import { optionsTypes, toFixed, calcPrecision, roundToNearest } from 'helpers/utils';
import { OrderbookTabType } from 'types/IOrderbook';
import { IBook, IProduct } from 'types/ITrade';

interface IOrderbookDataArgs {
  selectedProduct: IProduct;
  selectedPriceClubbingValue?: number;
  sellData: IBook[];
  buyData: IBook[];
  tabSelected: OrderbookTabType;
  isMobile: boolean;
}

const getMinOrderbookLevels = (
  isMobile: boolean,
  contractType: string,
  side: string,
  book: IBook[]
) => {
  const isOptionsChain = Object.values(optionsTypes)?.includes(contractType);
  const isSpot = contractType === 'spot';
  const bookLength = book.length;

  if (isMobile) {
    return side === 'both'
      ? R.max(MIN_MOBILE_ORDERBOOK_LEVELS_BOTH, bookLength)
      : R.max(MIN_MOBILE_ORDERBOOK_LEVELS_SINGLE, bookLength);
  }
  if (side === 'both') {
    return isSpot
      ? R.max(MIN_DESKTOP_SPOT_ORDERBOOK_LEVELS_BOTH, bookLength)
      : R.max(MIN_DESKTOP_ORDERBOOK_LEVELS_BOTH, bookLength);
  }
  if (isSpot) {
    return R.max(MIN_DESKTOP_SPOT_ORDERBOOK_LEVELS_SINGLE, bookLength);
  }
  if (isOptionsChain) {
    return R.max(MIN_OPTIONS_CHAIN_ORDERBOOK_LEVELS, bookLength);
  }
  return R.max(MIN_DESKTOP_ORDERBOOK_LEVELS_SINGLE, bookLength);
};

// function to the combine the value
const clubPriceLevels = (
  levels: IBook[],
  clubbingValue: number,
  side: OrderbookTabType,
  precision: number
) => {
  const floorOrCeil = side === 'buy' ? ROUND_DOWN : ROUND_UP;

  let book: any;
  book = R.map(
    level =>
      R.merge(level, {
        price: toFixed(precision)(
          roundToNearest(Number(level.price), clubbingValue, floorOrCeil)
        ),
      }),
    levels
  );

  book = R.mapObjIndexed(
    (levelsArr: any, price: string) => {
      const depths = levelsArr.map(level => Number(level.depth));
      return {
        size: R.sum(R.pluck('size')(levelsArr)),
        price,
        depth_size: Math.max(...depths), // maximum depth
      };
    },
    R.groupBy(
      (level: { size: number; price: string; depth: string }) => level.price,
      book
    )
  );
  const value = R.values(book);
  return value;
};

const getOrderbookData =
  ({
    selectedProduct,
    selectedPriceClubbingValue,
    sellData,
    buyData,
    tabSelected,
    isMobile,
  }: IOrderbookDataArgs) =>
  (side: OrderbookTabType) => {
    const precision = calcPrecision(selectedProduct.tick_size);
    const book = R.equals(side, 'sell') ? sellData : buyData;
    const minBothLevels = getMinOrderbookLevels(
      isMobile,
      selectedProduct.contract_type,
      'both',
      book
    );

    const minBuySellLevels = getMinOrderbookLevels(
      isMobile,
      selectedProduct.contract_type,
      'buy&sell',
      book
    );
    // TODO: Remove below condition
    // When we switch book type, tabSelected is first sell and then changes to desired state.
    // Debug why it first changes to sell and then remove below condition
    if (tabSelected === 'both' || tabSelected === side) {
      const minNumberLevels = R.equals(tabSelected, 'both')
        ? minBothLevels
        : minBuySellLevels;

      const clubbedPriceLevels = clubPriceLevels(
        book,
        selectedPriceClubbingValue,
        side,
        precision
      );

      const combined_book = isMobile
        ? clubbedPriceLevels
        : R.take(minNumberLevels, clubbedPriceLevels);

      let numEmptyRows = Math.max(0, minNumberLevels - combined_book.length);
      if (tabSelected === 'sell' && numEmptyRows < 2) {
        numEmptyRows += 2 - numEmptyRows;
      }
      const emptyRows = R.repeat({ isEmpty: true }, numEmptyRows);
      const paddedBook = calculate_depth_percentage(combined_book).concat(emptyRows);
      const sortedBook = side === 'sell' ? paddedBook.reverse() : paddedBook;
      return sortedBook;
    }
    return [];
  };

const getPopoverOptions = (msg: ReactNode) => ({
  leaveDelay: 0,
  body: msg,
});

const toggleRates = (price: any, ratesType: string, tickSize: any) => {
  if (Number(price) && price !== '-' && ratesType === '8hr') {
    const eightHourRate = (Number(price) * 8) / 8760;
    return round_by_tick_size(eightHourRate, tickSize, 'floor');
  }
  return round_by_tick_size(price, tickSize, 'floor');
};

export {
  getMinOrderbookLevels,
  clubPriceLevels,
  getOrderbookData,
  getPopoverOptions,
  toggleRates,
};
