/* eslint-disable import/no-unresolved */
import React from 'react';

import { STORAGE_KEYS } from 'constants/enums';
import {
  getDateTime,
  getHour,
  getMinute,
  getUnixTimestamp,
  startOf,
  subtract,
} from 'helpers/day';
import { isOptions, readValueFromStorage } from 'helpers/utils';

import { CHART_TABS } from './chart.constants';
import { OptionStyle } from './chart_style';

const getTimeResolution = () => {
  return {
    Minutes: {
      options: [
        { value: '1', label: '1m' },
        { value: '3', label: '3m' },
        { value: '5', label: '5m' },
        { value: '15', label: '15m' },
        { value: '30', label: '30m' },
      ],
      value: null,
      placeholder: 'm',
    },
    Hours: {
      placeholder: 'H',
      options: [
        { value: '60', label: '1H' },
        { value: '120', label: '2H' },
        { value: '240', label: '4H' },
        { value: '360', label: '6H' },
      ],
      value: null,
    },
    Days: {
      placeholder: 'D',
      options: [
        { value: 'D', label: '1D' },
        { value: '7D', label: '7D' },
      ],
      value: null,
    },
    // Weeks: {
    //   placeholder: 'W',
    //   options: [
    //     // { value: '1W', label: '1W' },
    //     { value: '2W', label: '2W' },
    //   ],
    //   value: null,
    // },
    // Months: {
    //   placeholder: 'M',
    //   options: [{ value: '30D', label: '1M' }],
    //   value: null,
    // },
    More: {
      placeholder: 'More',
      options: [
        { value: '2W', label: '2W' },
        { value: '30D', label: '1M' },
      ],
      value: null,
    },
  };
};

const getSectionTimeFrame = label => {
  const minutes = 'Minutes';
  const hours = 'Hours';
  const days = 'Days';
  const weeks = 'Weeks';
  const months = 'Months';
  const timeFrameMap = {
    '1m': minutes,
    '3m': minutes,
    '5m': minutes,
    '15m': minutes,
    '30m': minutes,
    '1H': hours,
    '2H': hours,
    '4H': hours,
    '6H': hours,
    '1D': days,
    '7D': days,
    '1W': weeks,
    '2W': weeks,
    '1M': months,
  };
  return timeFrameMap[label];
};

const getSubscriptionValue = value => {
  const resolutionMap = {
    1: '1m',
    5: '5m',
    3: '3m',
    15: '15m',
    30: '30m',
    60: '1h',
    120: '2h',
    240: '4h',
    360: '6h',
    D: '1d',
    '7D': '1w',
    '30D': '30d',
    '1W': '1w',
    '2W': '2w',
  };
  const resolution = resolutionMap[value] ?? value;
  return resolution;
};

const barTypes = () => {
  return [
    {
      label: (
        <OptionStyle>
          <div className="icon-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
            >
              <path d="M18 6h1v16h-1z" />
              <path d="M18.5 18H22v1h-3.5zM15 13h3.5v1H15zm-5-6h1v16h-1z" />
              <path d="M10.5 9H14v1h-3.5zM7 20h3.5v1H7z" />
            </svg>
          </div>
          <div className="labelRow-3Q0rdE8--">
            <div className="label-3Xqxy756-">Bars</div>
          </div>
        </OptionStyle>
      ),
      value: '0',
    },
    {
      label: (
        <OptionStyle>
          <div className="icon-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
            >
              <path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z" />
              <path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z" />
              <path d="M9 8v12h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5z" />
              <path d="M10 4h1v3.5h-1zm0 16.5h1V24h-1z" />
            </svg>
          </div>
          <div className="labelRow-3Q0rdE8--">
            <div className="label-3Xqxy756-">Candles</div>
          </div>
        </OptionStyle>
      ),
      value: '1',
    },
    {
      label: (
        <OptionStyle>
          {' '}
          <div className="icon-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
              fill="currentColor"
            >
              <path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z" />
              <path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z" />
              <path d="M9 8v11h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5z" />
              <path d="M10 4h1v5h-1zm0 14h1v5h-1zM8.5 9H10v1H8.5zM11 9h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11z" />
            </svg>
          </div>
          <div className="labelRow-3Q0rdE8--">
            <div className="label-3Xqxy756-">Hollow Candles</div>
          </div>{' '}
        </OptionStyle>
      ),
      value: '9',
    },
    {
      label: (
        <OptionStyle>
          <div className="icon-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
              fill="currentColor"
            >
              <path d="M9 8v12h3V8H9zm-1-.502C8 7.223 8.215 7 8.498 7h4.004c.275 0 .498.22.498.498v13.004a.493.493 0 0 1-.498.498H8.498A.496.496 0 0 1 8 20.502V7.498z" />
              <path d="M10 4h1v3.5h-1z" />
              <path d="M17 6v6h3V6h-3zm-1-.5c0-.276.215-.5.498-.5h4.004c.275 0 .498.23.498.5v7c0 .276-.215.5-.498.5h-4.004a.503.503 0 0 1-.498-.5v-7z" />
              <path d="M18 2h1v3.5h-1z" />
            </svg>
          </div>
          <div className="labelRow-3Q0rdE8--">
            <div className="label-3Xqxy756-">Heikin Ashi</div>
          </div>{' '}
        </OptionStyle>
      ),
      value: '8',
    },
    {
      label: (
        <OptionStyle>
          <div className="icon-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
            >
              <path
                fill="currentColor"
                d="M11.982 16.689L17.192 12h3.033l4.149-4.668-.748-.664L19.776 11h-2.968l-4.79 4.311L9
                  12.293l-4.354 4.353.708.708L9 13.707z"
              />
            </svg>
          </div>
          <div className="labelRow-3Q0rdE8--">
            <div className="label-3Xqxy756-">Line</div>
          </div>
        </OptionStyle>
      ),
      value: '2',
    },
    {
      label: (
        <OptionStyle>
          <div className="icon-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
              fill="currentColor"
            >
              <path d="M12.5 17.207L18.707 11h2l3.647-3.646-.708-.708L20.293 10h-2L12.5 15.793l-3-3-4.854 4.853.708.708L9.5 14.207z" />
              <path
                d="M9 16h1v1H9zm1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1H9zm2
                         0h1v1h-1zm-3-3h1v1H8zm-1 1h1v1H7zm-1 1h1v1H6zm2 0h1v1H8zm-1 1h1v1H7zm-2 0h1v1H5zm17-9h1v1h-1zm1-1h1v1h-1zm0
                         2h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1
                         1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-5-7h1v1h-1zm2 0h1v1h-1zm1-1h1v1h-1zm-2
                         2h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2
                          0h1v1h-1zm-1 1h1v1h-1zm-2-6h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1
                           1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-3-3h1v1h-1zm-1 1h1v1h-1zm-1
                           1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1z"
              />
            </svg>
          </div>
          <div className="labelRow-3Q0rdE8--">
            <div className="label-3Xqxy756-">Area</div>
          </div>{' '}
        </OptionStyle>
      ),
      value: '3',
    },
  ];
};

const calculateToTimeForMinutesResolution = ({ to, resolution }) => {
  // NOTE: we have to send the past time
  const toTimeInUTC = startOf(subtract(getDateTime(to * 1000), 1, 'minute'), 'minute');
  const minute = getMinute(toTimeInUTC);
  const remainder = Number(minute) % resolution;

  return getUnixTimestamp(subtract(toTimeInUTC, remainder, 'minutes'));
};

const calculateToTimeForHoursResolution = ({ to, resolution }) => {
  // NOTE: we have to send the past time
  const toTimeInUTC = startOf(subtract(getDateTime(to * 1000), 1, 'minute'), 'hour');
  const hour = getHour(getDateTime(to * 1000));
  const remainder = Number(hour) % resolution;
  // console.log('DEBUG calculateToTimeForHoursResolution', {
  //   hour,
  //   remainder,
  //   toTimeInUTC,
  //   toUTC: getDateTime(to * 1000),
  //   subtract: subtract(getDateTime(to * 1000), 1, 'minute'),
  //   resolution,
  //   getUnixTimestamp: getUnixTimestamp(subtract(toTimeInUTC, remainder, 'hours')),
  // });
  return getUnixTimestamp(subtract(toTimeInUTC, remainder, 'hours'));
};

const calculateToTime = ({ to, resolution }) => {
  switch (resolution) {
    case '1':
    case '3':
    case '5':
    case '15':
    case '30':
    case '60': {
      return calculateToTimeForMinutesResolution({
        to,
        resolution: Number(resolution),
      });
    }
    case '120':
    case '240':
    case '360': {
      return calculateToTimeForHoursResolution({
        to,
        resolution: Number(resolution) / 60,
      });
    }
    case 'D':
    case '7D':
    case '1W':
    case '2W':
    case '30D': {
      return getUnixTimestamp(
        startOf(subtract(getDateTime(to * 1000), 1, 'minute'), 'day')
      );
    }
    default: {
      // console.log('Default');
      return to;
    }
  }
};

const calculateFromTime = ({ countBack, to, resolution }) => {
  switch (resolution) {
    case 'D':
      return getUnixTimestamp(
        subtract(getDateTime(to * 1000), Number(countBack), 'days')
      );
    case '7D':
      return getUnixTimestamp(
        subtract(getDateTime(to * 1000), Number(countBack), 'days')
      );
    case '2W':
      return getUnixTimestamp(
        subtract(getDateTime(to * 1000), Number(countBack), 'days')
      );
    case '30D':
      return getUnixTimestamp(
        subtract(getDateTime(to * 1000), Number(countBack), 'weeks')
      );
    default:
      return getUnixTimestamp(
        startOf(
          subtract(getDateTime(to * 1000), Number(countBack * resolution), 'minutes'),
          'minute'
        )
      );
  }
};

const getChartCacheTTL = resolution => {
  switch (resolution) {
    case '1':
    case '3':
    case '15':
      return '1m';

    case '30':
    case '60':
    case '120':
    case '240':
    case '360':
      return '10m';

    case 'D':
      return '12h';

    default:
      return '1m';
  }
};

// const currentResolution = () =>
//   localStorage.getItem(STORAGE_KEYS.TRADINGVIEW_ACTIVE_RESOLUTION) !== null
//     ? JSON.parse(localStorage.getItem(STORAGE_KEYS.TRADINGVIEW_ACTIVE_RESOLUTION))
//     : { value: 60 };
const currentResolution = () => {
  const value = readValueFromStorage(STORAGE_KEYS.TRADINGVIEW_ACTIVE_RESOLUTION);
  return value || { value: 60 };
};

const getInitialVisibleRange = ({ resolution, contractType, currentActiveTab }) => {
  const currentTime = getDateTime();
  const isOptionsContract = isOptions(contractType);
  const totalCandles = isOptionsContract ? 50 : 200;
  const last1Day = currentTime.subtract(1, 'day');
  const last5Days = currentTime.subtract(5, 'days');

  if (currentActiveTab === CHART_TABS.FUNDING_CHART) {
    return getUnixTimestamp(last5Days);
  }

  if (isOptionsContract) {
    return getUnixTimestamp(last1Day);
  }

  switch (resolution) {
    case '1':
      return getUnixTimestamp(currentTime.subtract(totalCandles, 'minutes'));
    case '3':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 3, 'minutes'));
    case '5':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 5, 'minutes'));
    case '15':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 15, 'minutes'));
    case '30':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 30, 'minutes'));
    case '60':
      return getUnixTimestamp(currentTime.subtract(totalCandles, 'hours'));
    case '120':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 2, 'hours'));
    case '240':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 4, 'hours'));
    case '360':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 6, 'hours'));
    case 'D':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 1, 'days'));
    case '1W':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 7, 'days'));
    case '2W':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 14, 'days'));
    case '1M':
      return getUnixTimestamp(currentTime.subtract(totalCandles * 30, 'days'));
    default:
      return getUnixTimestamp(currentTime.subtract(totalCandles, 'hours'));
  }
};

// const getInitialVisibleRange = ({
//   localTvActiveResolution,
//   contractType,
//   currentActiveTab,
// }) => {
//   const currentTime = getDateTime();
//   const isOptionsContract = isOptions(contractType);
//   const totalCandles = isOptionsContract ? 50 : 200;
//   // const last30month = currentTime.subtract(30, 'months');
//   // const last1month = currentTime.subtract(1, 'month');
//   // const last4month = currentTime.subtract(4, 'months');
//   // const last1Week = currentTime.subtract(1, 'week');
//   const last5Days = currentTime.subtract(5, 'days');
//   // const last2Days = currentTime.subtract(2, 'day');
//   // const last1Day = currentTime.subtract(1, 'day');
//   // const last8Hours = currentTime.subtract(8, 'hours');
//   const last2Hours = currentTime.subtract(2, 'hours');

//   if (currentActiveTab === CHART_TABS.FUNDING_CHART) {
//     return getUnixTimestamp(last5Days);
//   }
//   // if (isOptionsContract) {
//   //   return getUnixTimestamp(last1Day);
//   // }

//   switch (localTvActiveResolution.section) {
//     case 'Minutes':
//       switch (Number(localTvActiveResolution.value)) {
//         case 1:
//           return getUnixTimestamp(currentTime.subtract(totalCandles, 'minutes'));
//         case 3:
//           // Handle case 3 minutes here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 3, 'minutes'));
//         case 5:
//           // Handle case 5 minutes here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 5, 'minutes'));
//         case 15:
//           // Handle case 15 minutes here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 15, 'minutes'));
//         case 30:
//           // Handle case 30 minutes here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 30, 'minutes'));
//         default:
//           break;
//       }
//       break;
//     case 'Hours':
//       switch (localTvActiveResolution.value) {
//         case '60':
//           return getUnixTimestamp(currentTime.subtract(totalCandles, 'hours'));
//         case '120':
//           // Handle case 120 hours here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 2, 'hours'));
//         case '240':
//           // Handle case 240 hours here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 4, 'hours'));
//         case '360':
//           // Handle case 360 hours here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 6, 'hours'));
//         default:
//           return getUnixTimestamp(last2Hours);
//       }
//     case 'Days':
//       switch (localTvActiveResolution.value) {
//         case 'D':
//           // Handle case 'D' days here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 1, 'days'));
//         case '7D':
//           // Handle case '7D' days here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 7, 'days'));
//         default:
//           return getUnixTimestamp(last2Hours);
//       }
//     case 'More':
//       switch (localTvActiveResolution.value) {
//         case '2W':
//           // Handle case '2W' here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 14, 'days'));
//         case '30D':
//           // Handle case '30D' here
//           // For example:
//           return getUnixTimestamp(currentTime.subtract(totalCandles * 30, 'days'));
//         default:
//           return getUnixTimestamp(last2Hours);
//       }
//     default:
//       return getUnixTimestamp(last2Hours);
//   }

// if (localTvActiveResolution.section === 'Minutes') {
//   if (Number(localTvActiveResolution.value) > 5) {
//     return getUnixTimestamp(last1Day);
//   }
//   return getUnixTimestamp(last2Hours);
// }

// if (localTvActiveResolution.section === 'Hours') {
//   return getUnixTimestamp(last1Week);
// }

// if (localTvActiveResolution.section === 'Days') {
//   if (localTvActiveResolution.value === '1') {
//     return getUnixTimestamp(last1month);
//   }
//   return getUnixTimestamp(last4month);
// }

// if (localTvActiveResolution.section === 'More') {
//   return getUnixTimestamp(last30month);
// }

// return getUnixTimestamp(last2Hours);

// if (currentTimeFrameValue >= 15) {
//   return getUnixTimestamp(last5Days);
// }
// if (currentTimeFrameValue < 15 && currentTimeFrameValue >= 3) {
//   return getUnixTimestamp(last8Hours);
// }
// return getUnixTimestamp(last2Hours);
// };

export {
  getTimeResolution,
  getSectionTimeFrame,
  getSubscriptionValue,
  barTypes,
  calculateToTime,
  calculateFromTime,
  getChartCacheTTL,
  currentResolution,
  getInitialVisibleRange,
};
