import { toUTC } from 'helpers/day';

enum TIME_IN_MS {
  ONE_SECOND = 1000,
  ONE_MINUTE = 60 * ONE_SECOND,
  ONE_HOUR = 60 * ONE_MINUTE,
  ONE_DAY = 24 * ONE_HOUR,
}

const OPTION_LEADERBOARD_EVENTS_DATES = {
  EVENT_START_DATE: toUTC('2023-03-29T00:00:00Z'),
  EVENT_END_DATE: toUTC('2023-04-05T00:00:00Z'),
  EVENT_RESULT_END_DATE: toUTC('2023-04-11T00:00:00Z'),
};

const HOURS_IN_A_DAY = 24;

const MINUTES_IN_AN_HOUR = 60;

const SECONDS_IN_A_MINUTE = 60;

const COMPLETE_30_DAY_OFFER_ROLLOUT_DATE = toUTC('2023-04-19T00:00:00Z');

const LOCALES = {
  INDIA: 'en-IN',
} as const;

const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export {
  TIME_IN_MS,
  HOURS_IN_A_DAY,
  MINUTES_IN_AN_HOUR,
  SECONDS_IN_A_MINUTE,
  OPTION_LEADERBOARD_EVENTS_DATES,
  COMPLETE_30_DAY_OFFER_ROLLOUT_DATE,
  LOCALES,
  WEEK_DAYS,
};
