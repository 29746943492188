export const CHART_TABS = {
  PRICE_CHART: 'price-chart',
  MARK_CHART: 'mark-chart',
  FUNDING_CHART: 'funding-chart',
  DEPTH_CHART: 'depth-chart',
  INDEX_PRICE_CHART: 'underlying-chart',
};

export const CONTRACT_TYPE = {
  OPTIONS_CHAIN: 'options_chain',
  FUTURES: 'futures',
  SPOT: 'spot',
  OPTIONS: 'options',
  OPTIONS_COMBOS: 'options_combos',
  MOVE_OPTIONS: 'move_options',
};
