import { updateProfile } from 'actions/user';
import { postMessageToMobileApp } from 'components/app/helpers';
import { POST_MESSAGE_TO_MOBILE_EVENTS } from 'constants/enums';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import { FEATURE_FLAG_IN_APP_REVIEW_SUPPORTED } from 'constants/rnApp';
import { useAppDispatch } from 'storeHooks';
import type { AllUnionMemberKeys } from 'types/utils';

import { timeNow, max, getDiff, getDateTime } from './day';
import { trackMixpanelEvent } from './mixpanel-init';

const MINIMUM_DAY_DIFFERENCE_BETWEEN_TRACKING = 30;
const VOLUME_THRESHOLD_FOR_TRACKING = 0;
const LOGIN_COUNT_FOR_TRACKING = 60;
const IN_APP_REVIEW_TRIGGER_DELAY = 2 * 60 * 1000; // 2 minutes
const DEPOSITS_COUNT_TO_TRACK = new Set([3, 10]);

/**
 * @description There is a limit enforced by app store and play store.
 * So we don't want to cause multiple triggers and reduce available quota limits.
 * Maintaining whether review was already tracked in current session.
 * If multiple triggers happen then only first is handled, rest are ignored.
 */
let hasInAppReviewTriggeredInCurrentSession = false;

/**
 * @description In tracking_info => app_review object, it will contain these fields.
 * Key will be these fields and value will be the time it was tracked.
 */
const IN_APP_REVIEW_KEYS = {
  FIRST_PROFITABLE_TRADE: 'first_profitable_trade',
  LOGIN_AND_VOLUME: 'login_and_volume',
  DC_OFFER_CLAIMED: 'dc_offer_claimed',
  DEPOSIT_COUNT_3: 'deposit_count_3',
  DEPOSIT_COUNT_10: 'deposit_count_10',
} as const;

const getDepositsCountKey = (depositsCount: number) => `deposit_count_${depositsCount}`;

/**
 * @description To check whether user is eligible for tracking.
 * Currently tracking will only be done if difference between current time and last tracked time is greater than 30 days.
 * Also this feature will only be available in latest android version so added checks for it.
 */
const isEligibleForInAppReview = (trackedInAppReviews: Record<string, string> = {}) => {
  if (
    !trackedInAppReviews ||
    typeof trackedInAppReviews !== 'object' ||
    !FEATURE_FLAG_IN_APP_REVIEW_SUPPORTED ||
    hasInAppReviewTriggeredInCurrentSession
  ) {
    return false;
  }

  const currentTime = timeNow();
  const allTrackedDates = Object.values(trackedInAppReviews);

  if (allTrackedDates.length === 0) {
    return true;
  }

  const lastTrackedDate =
    allTrackedDates.length > 1
      ? max(...allTrackedDates)
      : getDateTime(allTrackedDates[0]);

  const dayDifference = getDiff(currentTime, lastTrackedDate);

  return dayDifference > MINIMUM_DAY_DIFFERENCE_BETWEEN_TRACKING;
};

/**
 * @description Whenever user settles his open position,
 * if the pnl is positive for that trade then we need to show in app review.
 * But this should be done only for first profitable trade, rest of the trades should be ignored.
 */
const shouldShowAfterFirstProfitableTrade = (
  trackedInAppReviews: Record<string, string>
) => {
  if (!isEligibleForInAppReview(trackedInAppReviews)) {
    return false;
  }

  if (IN_APP_REVIEW_KEYS.FIRST_PROFITABLE_TRADE in trackedInAppReviews) {
    return false;
  }

  return true;
};

/**
 * @description If user login count and volume is above certain threshold then we need to request for in app review.
 */
const shouldShowForLoginCountAndVolume = (
  trackedInAppReviews: Record<string, string>,
  { loginCount, volume }: { loginCount: number; volume: number }
) => {
  if (!isEligibleForInAppReview(trackedInAppReviews)) {
    return false;
  }

  if (IN_APP_REVIEW_KEYS.LOGIN_AND_VOLUME in trackedInAppReviews) {
    return false;
  }

  return loginCount >= LOGIN_COUNT_FOR_TRACKING && volume > VOLUME_THRESHOLD_FOR_TRACKING;
};

/**
 * @description Whenever user claims delta cash related offer, we need to request for in app review.
 */
const shouldShowForDeltaCashOffer = (trackedInAppReviews: Record<string, string>) => {
  if (!isEligibleForInAppReview(trackedInAppReviews)) {
    return false;
  }

  if (IN_APP_REVIEW_KEYS.DC_OFFER_CLAIMED in trackedInAppReviews) {
    return false;
  }

  return true;
};

/**
 * @description If users deposit count is eligible for tracking then we show in app review.
 * Refer DEPOSITS_COUNT_TO_TRACK constant.
 */
const shouldShowForDepositsCount = (
  trackedInAppReviews: Record<string, string>,
  depositsCount: number
) => {
  if (
    !isEligibleForInAppReview(trackedInAppReviews) ||
    !DEPOSITS_COUNT_TO_TRACK.has(depositsCount)
  ) {
    return false;
  }

  const depositCountKey = getDepositsCountKey(depositsCount);

  if (depositCountKey in trackedInAppReviews) {
    return false;
  }

  return true;
};

/**
 * @description It will trigger in app review within mobile app by passing the message.
 * Also it will update the profile api so no more tracking happens for the same event.
 * There is a small delay added using setTimeout so profile update and in app review shows up after few mins.
 */
const triggerInAppReview = ({
  eventName,
  dispatch,
}: {
  eventName: AllUnionMemberKeys<typeof IN_APP_REVIEW_KEYS>;
  dispatch: ReturnType<typeof useAppDispatch>;
}) => {
  if (hasInAppReviewTriggeredInCurrentSession || !FEATURE_FLAG_IN_APP_REVIEW_SUPPORTED) {
    return;
  }

  hasInAppReviewTriggeredInCurrentSession = true;

  setTimeout(async () => {
    try {
      await dispatch(
        updateProfile({ app_review: { [eventName]: timeNow().toISOString() } }, false)
      );
      trackMixpanelEvent(MIXPANEL_EVENT_NAMES.IN_APP_REVIEW, { trigger: eventName });
      postMessageToMobileApp(POST_MESSAGE_TO_MOBILE_EVENTS.LAUNCH_IN_APP_REVIEW);
    } catch {}
  }, IN_APP_REVIEW_TRIGGER_DELAY);
};

export {
  isEligibleForInAppReview,
  shouldShowAfterFirstProfitableTrade,
  shouldShowForLoginCountAndVolume,
  shouldShowForDeltaCashOffer,
  shouldShowForDepositsCount,
  triggerInAppReview,
  getDepositsCountKey,
  IN_APP_REVIEW_KEYS,
  DEPOSITS_COUNT_TO_TRACK,
};
