import SETTINGS from 'actionTypes/settings';
import { WALLET_API } from 'constants/api';
import createAction from 'helpers/createAction';

export function getSettings() {
  return {
    types: SETTINGS.GET_SETTINGS,
    promise: ({ request }) => request.get(WALLET_API.SETTINGS),
  };
}

export const maintenanceStarted = createAction(SETTINGS.MAINTENANCE_STARTED);
export const maintenanceFinished = createAction(SETTINGS.MAINTENANCE_FINISHED);
export const maintenanceScheduled = createAction(SETTINGS.MAINTENANCE_SCHEDULED);
export const showMaintenanceBanner = createAction(SETTINGS.SHOW_MAINTENANCE_BANNER);
export const playWithdrawalVideo = createAction(SETTINGS.PLAY_WITHDRAWAL_VIDEO);
export const playDepositVideo = createAction(SETTINGS.PLAY_DEPOSIT_VIDEO);
