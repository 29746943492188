/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["^draft"] }] */
import { produce } from 'immer';

import ACCOUNT_ACTION_TYPES, {
  AntiPhishingActionType,
  DepositsActionTypeIndia,
  WithdrawalActionTypeIndia,
  WithdrawalHistoryActionType,
} from 'actionTypes/account';
import { API_STATUS } from 'constants/enums';
import { formatBankHistoryApiData } from 'helpers/account';
import { formatSecondsToHMS } from 'helpers/day';
import { getSecurityVerificationCodeError } from 'helpers/error';
import { pathOr } from 'helpers/ramda';
import i18n from 'i18n/config';
import Account, { ANTI_PHISHING_ERROR_KEY, ANTI_PHISHING_STATUS } from 'types/Account';
import { UnknownObject } from 'types/utils';

import INITIAL_STATE from './initialstate/account';

const account = produce((draft: Account, action) => {
  switch (action.type) {
    case AntiPhishingActionType.OPEN_CHANGE_CODE_MODAL: {
      draft.security.antiPhishing.status =
        draft.security.antiPhishing.status === ANTI_PHISHING_STATUS.OPEN_CHANGE_CODE_MODAL
          ? ANTI_PHISHING_STATUS.STANDBY
          : ANTI_PHISHING_STATUS.OPEN_CHANGE_CODE_MODAL;

      break;
    }

    case AntiPhishingActionType.TRIGGER_VERIFICATION: {
      draft.security.antiPhishing.status =
        ANTI_PHISHING_STATUS.SEND_VERIFICATION_CODE.LOAD;

      if (action.payload.code) {
        draft.security.antiPhishing.newCode = action.payload.code;
      }

      break;
    }

    case AntiPhishingActionType.TRIGGER_VERIFICATION_FAILED:
    case ACCOUNT_ACTION_TYPES[AntiPhishingActionType.SEND_VERIFICATION_CODE].FAIL: {
      draft.security.antiPhishing.status =
        ANTI_PHISHING_STATUS.SEND_VERIFICATION_CODE.FAIL;
      draft.security.antiPhishing.errors[ANTI_PHISHING_ERROR_KEY.NEW_CODE] =
        action?.payload ?? i18n.t('errors:utils.generic_with_contact_support');

      break;
    }

    case ACCOUNT_ACTION_TYPES[AntiPhishingActionType.SEND_VERIFICATION_CODE].LOAD: {
      draft.security.antiPhishing.errors = {};

      break;
    }

    case ACCOUNT_ACTION_TYPES[AntiPhishingActionType.SEND_VERIFICATION_CODE].SUCCESS: {
      draft.security.antiPhishing.status =
        ANTI_PHISHING_STATUS.SEND_VERIFICATION_CODE.SUCCESS;
      draft.security.antiPhishing.errors = {};

      break;
    }

    case ACCOUNT_ACTION_TYPES[AntiPhishingActionType.UPDATE_CODE].LOAD: {
      draft.security.antiPhishing.status = ANTI_PHISHING_STATUS.UPDATE_CODE.LOAD;
      draft.security.antiPhishing.errors = {};

      break;
    }

    case ACCOUNT_ACTION_TYPES[AntiPhishingActionType.UPDATE_CODE].FAIL: {
      draft.security.antiPhishing.status = ANTI_PHISHING_STATUS.UPDATE_CODE.FAIL;

      const apiResponse: { error: UnknownObject; success: boolean } = pathOr(
        { error: {}, success: false },
        ['response', 'body'],
        action.error
      );

      const verificationCodeError = getSecurityVerificationCodeError(apiResponse.error);

      if (!verificationCodeError) {
        const { code, context } = apiResponse.error;

        if (code === 'update_phishing_code_blocked') {
          draft.security.antiPhishing.errors[ANTI_PHISHING_ERROR_KEY.VERIFICATION] =
            i18n.t('account:antiPhishing.errors.update_phishing_code_blocked', {
              timeout: formatSecondsToHMS(context.time),
            });
          draft.security.antiPhishing.errorsMeta[ANTI_PHISHING_ERROR_KEY.VERIFICATION] =
            context;
        }

        break;
      }

      const { key, error } = verificationCodeError;

      draft.security.antiPhishing.errors[key] = error;

      break;
    }

    case AntiPhishingActionType.RESET:
    case ACCOUNT_ACTION_TYPES[AntiPhishingActionType.UPDATE_CODE].SUCCESS: {
      draft.security.antiPhishing = INITIAL_STATE.security.antiPhishing;

      break;
    }

    case ACCOUNT_ACTION_TYPES[WithdrawalHistoryActionType.FETCH_DATA].SUCCESS:
      draft.fiatWithdrawalHistory.apiStatus = API_STATUS.SUCCESS;
      draft.fiatWithdrawalHistory.tableData = formatBankHistoryApiData(
        action.result.result
      );
      break;

    case ACCOUNT_ACTION_TYPES[WithdrawalHistoryActionType.FETCH_DATA].LOAD:
      draft.fiatWithdrawalHistory.apiStatus = API_STATUS.LOADING;
      draft.fiatWithdrawalHistory.tableData = [];
      break;

    case ACCOUNT_ACTION_TYPES[WithdrawalHistoryActionType.FETCH_DATA].FAIL:
      draft.fiatWithdrawalHistory.apiStatus = API_STATUS.ERROR;
      draft.fiatWithdrawalHistory.tableData = [];
      break;

    case DepositsActionTypeIndia.REFETCH_DEPOSIT_HISTORY:
      draft.refetchDepositHistoryIndia = true;
      break;

    case ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.GET_DEPOSIT_HISTORY].SUCCESS:
    case ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.GET_DEPOSIT_HISTORY].FAIL:
      draft.refetchDepositHistoryIndia = false;
      break;

    case WithdrawalActionTypeIndia.REFETCH_WITHDRAWAL_HISTORY:
      draft.refetchWithdrawalHistoryIndia = true;
      break;

    case ACCOUNT_ACTION_TYPES[WithdrawalActionTypeIndia.GET_WITHDRAWAL_HISTORY].SUCCESS:
    case ACCOUNT_ACTION_TYPES[WithdrawalActionTypeIndia.GET_WITHDRAWAL_HISTORY].FAIL:
      draft.refetchWithdrawalHistoryIndia = false;
      break;

    default:
      break;
  }
}, INITIAL_STATE);

export default account;
