import keyMirror from '../helpers/keymirror';

export const AUTHENTICATE_SOCKET = 'AUTHENTICATE_SOCKET';
export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET';
export const RECONNECT_SOCKET = 'RECONNECT_SOCKET';
export const ACTIVATE_REST_MODE = 'ACTIVATE_REST_MODE';
export const DEACTIVATE_REST_MODE = 'DEACTIVATE_REST_MODE';
export const CONNECT_SELECTED_PRODUCT_CHANNEL = 'CONNECT_SELECTED_PRODUCT_CHANNEL';

export const RECONNECT_SOCKET_TAB_REACTIVE = 'RECONNECT_SOCKET_TAB_REACTIVE';

export const ENABLE_HEARTBEAT = 'ENABLE_HEARTBEAT';
export const DISABLE_HEARTBEAT = 'DISABLE_HEARTBEAT';
export const CHECK_HEARTBEAT = 'CHECK_HEARTBEAT';

// export const UPDATE_HEARTBEAT_TS = 'UPDATE_HEARTBEAT_TS';

export const SUBSCRIBE_FUNDING = 'SUBSCRIBE_FUNDING';
export const UNSUBSCRIBE_FUNDING = 'UNSUBSCRIBE_FUNDING';
export const SUBSCRIBE_L2_ORDERBOOK = 'SUBSCRIBE_L2_ORDERBOOK';
export const UNSUBSCRIBE_L2_ORDERBOOK = 'UNSUBSCRIBE_L2_ORDERBOOK';
export const SUBSCRIBE_BEST_BID_BEST_ASK = 'SUBSCRIBE_BEST_BID_BEST_ASK';
export const UNSUBSCRIBE_BEST_BID_BEST_ASK = 'UNSUBSCRIBE_BEST_BID_BEST_ASK';
export const SUBSCRIBE_MARK = 'SUBSCRIBE_MARK';
export const SUBSCRIBE_TRADING_NOTIFICATIONS = 'SUBSCRIBE_TRADING_NOTIFICATIONS';
export const SUBSCRIBE_ORDERS = 'SUBSCRIBE_ORDERS';
export const SUBSCRIBE_PUBLIC_CHANNELS = 'SUBSCRIBE_PUBLIC_CHANNELS';
export const SUBSCRIBE_RECENT_TRADE = 'SUBSCRIBE_RECENT_TRADE';
export const SUBSCRIBE_SPOT = 'SUBSCRIBE_SPOT';
export const SUBSCRIBE_TICKER = 'SUBSCRIBE_TICKER';
export const UNSUBSCRIBE_TICKER = 'UNSUBSCRIBE_TICKER';
export const SUBSCRIBE_OHLC_CANDEL = 'SUBSCRIBE_OHLC_CANDEL';
export const UNSUBSCRIBE_OHLC_CANDEL = 'UNSUBSCRIBE_OHLC_CANDEL';

export const UNSUBSCRIBE_ALL = 'UNSUBSCRIBE_ALL';
export const UNSUBSCRIBE_OB_RT = 'UNSUBSCRIBE_OB_RT';
export const UNSUBSCRIBE_PRIVATE = 'UNSUBSCRIBE_PRIVATE';

export const SUBSCRIBE_SELECTED_PRODUCT_MARK_DATA =
  'SUBSCRIBE_SELECTED_PRODUCT_MARK_DATA';
export const UNSUBSCRIBE_SELECTED_PRODUCT_MARK_DATA =
  'UNSUBSCRIBE_SELECTED_PRODUCT_MARK_DATA';

export const SUBSCRIBE_SELECTED_PRODUCT_SPOT = 'SUBSCRIBE_SELECTED_PRODUCT_SPOT';
export const UNSUBSCRIBE_SELECTED_PRODUCT_SPOT = 'UNSUBSCRIBE_SELECTED_PRODUCT_SPOT';

export const SUBSCRIBE_WALLET_SPOT = 'SUBSCRIBE_WALLET_SPOT';
export const UNSUBSCRIBE_WALLET_SPOT = 'UNSUBSCRIBE_WALLET_SPOT';
export const SUBSCRIBE_OPEN_POSITION_SPOT = 'SUBSCRIBE_OPEN_POSITION_SPOT';
export const UNSUBSCRIBE_OPEN_POSITION_SPOT = 'UNSUBSCRIBE_OPEN_POSITION_SPOT';

export const SUBSCRIBE_OPEN_POSITION_MARK = 'SUBSCRIBE_OPEN_POSITION_MARK';
export const UNSUBSCRIBE_MARK = 'UNSUBSCRIBE_MARK';

export const SUBSCRIBE_OPTION_CHAIN_MARK = 'SUBSCRIBE_OPTION_CHAIN_MARK';
export const UNSUBSCRIBE_OPTION_CHAIN_MARK = 'UNSUBSCRIBE_OPTION_CHAIN_MARK';

export const SUBSCRIBE_EASY_OPTIONS_ORDERBOOK = 'SUBSCRIBE_EASY_OPTIONS_ORDERBOOK';
export const UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK = 'UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK';

export const SUBSCRIBE_CONTRACT_ALL_RECENT_TRADES =
  'SUBSCRIBE_CONTRACT_ALL_CONTRACTS_RECENT_TRADES';
export const UNSUBSCRIBE_CONTRACT_ALL_RECENT_TRADES =
  'UNSUBSCRIBE_CONTRACT_ALL_CONTRACTS_RECENT_TRADES';

const actionTypes = keyMirror({
  IS_CONNECTED: null,
});

export const UPDATE_HEARTBEAT = 'UPDATE_HEARTBEAT';

export default actionTypes;
