const CONTRACT_FILTERS = {
  ALL: 'all',
  FUTURES: 'futures',
  OPTIONS: 'options',
} as const;

const YOUTUBE_SAVE_TRADING_FEE_IFRAME_URL =
  'https://www.youtube.com/embed/CrioiI0cTeg?si=n80HAtvAlIMbBKk-&autoplay=1';

const ACCOUNT_VALUE_CHART_INTERVAL = 6;

export {
  CONTRACT_FILTERS,
  YOUTUBE_SAVE_TRADING_FEE_IFRAME_URL,
  ACCOUNT_VALUE_CHART_INTERVAL,
};
