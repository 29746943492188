import keyMirror from '../helpers/keymirror';

const actionTypes = keyMirror({
  GET_SETTINGS: null,
  MAINTENANCE_STARTED: null,
  MAINTENANCE_FINISHED: null,
  MAINTENANCE_SCHEDULED: null,
  SHOW_MAINTENANCE_BANNER: null,
  PLAY_DEPOSIT_VIDEO: null,
  PLAY_WITHDRAWAL_VIDEO: null,
});

export default actionTypes;
