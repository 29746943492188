/**
 * @description Mapping of search params keys being used in the application, so they don't collide.
 * Also it will be easier to keep track of what params are available and we can easily change from here.
 */
const URL_SEARCH_PARAMS_KEYS = {
  HOMEPAGE: {
    // Being used to handle homepage mobile options, futures, leaderboard tabs.
    TABLE_SECTION_TABS: 'table_tab',
  },
  CHART_ORDERBOOK_MOBILE: {
    SECTION_TABS: 'chart_orderbook_tab',
  },
} as const;

export { URL_SEARCH_PARAMS_KEYS };
