/* eslint-disable camelcase */
import ORDER_BOOK, {
  BRACKET_ORDER_MODAL_OPEN,
  ORDER_BOOK_PRICE_CLICK,
  UPDATE_PRODUCT_LEVERAGE,
  ORDER_BOOK_STALE,
} from 'actionTypes/orderbook';
import { ORDERS } from 'constants/api';
import createAction from 'helpers/createAction';

import { authAction } from './user';

// eslint-disable-next-line camelcase
export function getOrderLeverage(product_id: string | number) {
  return authAction({
    types: ORDER_BOOK.GET_ORDER_LEVERAGE,
    payload: product_id,
    promise: ({ request }) => request.get(ORDERS.LEVERAGE(product_id)),
  });
}

export function changeOrderLeverage(
  product_id: string | number,
  leverage: string | number
) {
  return authAction({
    types: ORDER_BOOK.CHANGE_ORDER_LEVERAGE,
    payload: { product_id, leverage },
    promise: ({ request }) =>
      request.post(ORDERS.LEVERAGE(product_id), {
        data: { leverage },
      }),
  });
}

export function updateProductLeverage({
  product_id,
  leverage,
  order_margin,
  margin_mode,
}: {
  product_id: string | number;
  leverage: string | number;
  order_margin: string | number;
  margin_mode: string;
}) {
  return {
    type: UPDATE_PRODUCT_LEVERAGE,
    result: {
      product_id,
      leverage,
      order_margin,
      margin_mode,
    },
  };
}

export const selectOrderbookPrice = createAction(ORDER_BOOK_PRICE_CLICK);
export const bracketOderModalOpen = createAction(BRACKET_ORDER_MODAL_OPEN);
export const orderbookStale = createAction(ORDER_BOOK_STALE);
