/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import { EmailVerificationPayloadObject } from 'components/Auth/EmailVerificationWithLink/types';
import { EditSubUser } from 'components/sub_account/types';
import {
  ALPYNE_API,
  FRESHDESK_API,
  OAUTH,
  ONMETA_API,
  RECOVER_ACCOUNT,
  SECURITY,
  SUB_ACCOUNTS,
  USER_PROFILE,
  WALLET_API,
} from 'constants/api';
import { IS_RN_WEBVIEW, LOGIN_ERROR_CODES } from 'constants/constants';
import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
import { formatUrl } from 'helpers/apiClient';
import createAction from 'helpers/createAction';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { omit } from 'helpers/ramda';
import { addLogsToSentry } from 'helpers/sentry';
import {
  getMobileOperatingSystem,
  isAndroidOrIOSDevice,
  parseErrorMsg,
} from 'helpers/utils';
import { isTruthy } from 'ramdax';
import type {
  AccountAnalyticsApiArgs,
  DeleteAccountConfirmationPayload,
  DeleteAccountPayload,
  EmailVerificationWithOuathPayloadObject,
  FreshDeskTicketPayload,
  GenerateRecoveryOtpPayload,
  GetMobileOtpArgs,
  GetTradeHistoryArgs,
  GetTradeHistoryRecordsArgs,
  GetTradeIdeasArguments,
  KycVendorLinkApiArgs,
  ResendMobileOtpArgs,
  ShareKycDataOnmetaArgs,
  UpdateProfileArgs,
  VerifyMobileOtpArgs,
  VerifyRecoveryOtpPayload,
} from 'types/actions/user';
import IState, { CustomAction, ICustomDispatch, IGetState } from 'types/Istore';

// import { UPDATE_HEARTBEAT_TS } from '../actionTypes/user';

import BELL_ALERTS from '../actionTypes/bellAlerts';
import EASY_OPTIONS from '../actionTypes/easyOptions';
import USER, {
  CLOSE_2FA_RESET_POPUP,
  CLOSE_PASSWORD_RESET_POPUP,
  LOGIN_CLICKED,
  SET_PHONE_VERIFICATION_TOKEN,
  SHOW_KYC_REFRESH_POPUP,
  SHOW_REDUCE_ONLY_POPUP,
  // SOCKET_CONNECTED,
  SIGNUP_CLICKED,
  SWITCH_TO_MAIN_ACCOUNT,
  TRANSFER_CONSTANTS,
  USER_CONNECTED,
  USER_DISCONNECTED,
  USER_LOGIN_REDIRECTION,
  SET_TRACKIER_ID,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_SUCCESS,
} from '../actionTypes/user';
import VOLUME_HISTORY from '../actionTypes/volumeHistory';

// export const updateHeartbeatTs = createAction(UPDATE_HEARTBEAT_TS);
export const userConnected = createAction(USER_CONNECTED);
// export const socketConnected = createAction(SOCKET_CONNECTED);
export const userDisconnected = createAction(USER_DISCONNECTED);
export const userSignupSuccess = createAction(USER_SIGNUP_SUCCESS);
export const userLoginRedirection = createAction(USER_LOGIN_REDIRECTION);
export const userSignupFail = createAction(USER_SIGNUP_FAIL);

export const setUserTrackierId = createAction(SET_TRACKIER_ID);

export const isAuthenticated = (user: { token?: string }) => isTruthy(user?.token);

export function authAction(action: CustomAction) {
  return (dispatch: ICustomDispatch<IState>, getState: IGetState) => {
    const { user } = getState();

    if (user.token && isAuthenticated(user)) {
      return dispatch(action);
    }
    console.warn(
      'user is not authenticated and requesting a private resource',
      action.types.LOAD
    );
  };
}

export function loginClicked(payload: any) {
  return {
    type: LOGIN_CLICKED,
    payload,
  };
}

export function signupClicked(payload: any) {
  return {
    type: SIGNUP_CLICKED,
    payload,
  };
}

export function switchToMainAccount() {
  return {
    type: SWITCH_TO_MAIN_ACCOUNT,
  };
}

export function showKycRefreshPopup(payload: any) {
  return {
    type: SHOW_KYC_REFRESH_POPUP,
    payload,
  };
}

export function showReduceOnlyPopup(payload: any) {
  return {
    type: SHOW_REDUCE_ONLY_POPUP,
    payload,
  };
}

export function closePasswordResetModal() {
  return {
    type: CLOSE_PASSWORD_RESET_POPUP,
  };
}

export function close2faResetModal() {
  return {
    type: CLOSE_2FA_RESET_POPUP,
  };
}

export function login(
  email: string,
  password: string,
  device_id: string,
  recaptcha_response: string,
  extraData: {
    page_name: string;
    type: string;
  } = {
    page_name: '',
    type: '',
  }
): CustomAction {
  const payload = { email, password, device_id, recaptcha_response } as Record<
    string,
    any
  >;
  if (IS_RN_WEBVIEW) {
    const key = 'source';
    payload[key] = 'mobile_app';

    if (isAndroidOrIOSDevice()) {
      payload.device_os_type = getMobileOperatingSystem();
    }
  }
  return {
    types: USER.LOGIN,
    payload,
    promise: ({ request }) =>
      request
        .post(USER_PROFILE.LOGIN, {
          data: payload,
        })
        .catch(error => {
          const result = parseErrorMsg(error);
          const commonTrackingData = {
            email,
            error_code: result.error.code,
            ...extraData,
          };

          trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SIGN_IN.FAIL, commonTrackingData);

          if (!LOGIN_ERROR_CODES.includes(result.error.code)) {
            addLogsToSentry(
              {
                requestPayload: omit(['password'], payload),
                error: JSON.stringify(error),
                ...commonTrackingData,
              },
              'Login Unhandled Error'
            );
          }

          throw error;
        }),
  };
}

export function loginmfa(
  pre_2fa_token: string,
  mfa_code: string,
  email_otp: string
): CustomAction {
  const payload = { pre_2fa_token, mfa_code, email_otp };
  if (IS_RN_WEBVIEW) {
    const key = 'source';
    payload[key] = 'mobile_app';

    if (isAndroidOrIOSDevice()) {
      payload.device_os_type = getMobileOperatingSystem();
    }
  }
  return {
    types: USER.LOGINMFA,
    promise: ({ request }) => request.post(USER_PROFILE.LOGIN_MFA, { data: payload }),
  };
}

export function resendLoginEmailCode(
  pre_2fa_token: string,
  recaptcha_response: any
): CustomAction {
  const payload = {
    pre_2fa_token,
    recaptcha_response,
  };
  return {
    types: USER.RESEND_LOGIN_EMAIL_CODE,
    promise: ({ request }) =>
      request.post(USER_PROFILE.RESEND_LOGIN_EMAIL_CODE, { data: payload }),
  };
}

export function sendLoginMobileOTP(pre_2fa_token: string): CustomAction {
  const payload = {
    pre_2fa_token,
  };

  return {
    types: USER.SEND_OTP_ON_PHONE_NUMBER,
    promise: ({ request }) =>
      request.post(USER_PROFILE.SEND_OTP_ON_PHONE_NUMBER, { data: payload }),
  };
}

export function loginEmailVerification(
  pre_2fa_token: string,
  email_otp: string,
  recaptcha_response: any,
  phoneOTPVerification: boolean = false
): CustomAction {
  const payload = {
    pre_2fa_token,
    recaptcha_response,
  } as Record<string, any>;

  if (IS_RN_WEBVIEW && isAndroidOrIOSDevice()) {
    payload.device_os_type = getMobileOperatingSystem();
  }

  let uri = USER_PROFILE.LOGIN_EMAIL_VERIFICATION;

  if (phoneOTPVerification) {
    uri = USER_PROFILE.PHONE_OTP_VERIFICATION;
    payload.phone_otp = email_otp;
  } else {
    payload.email_otp = email_otp;
  }

  const types = phoneOTPVerification
    ? USER.PHONE_OTP_VERIFICATION
    : USER.LOGIN_EMAIL_VERIFICATION;

  return {
    types,
    promise: ({ request }) => request.post(uri, { data: payload }),
  };
}

export function oAuthLoginSuccess(response) {
  return {
    type: USER.LOGIN_EMAIL_VERIFICATION.SUCCESS,
    result: response,
  };
}

export function signup(data): CustomAction {
  return {
    types: USER.SIGNUP,
    email: data.email,
    promise: ({ request }) => request.post(USER_PROFILE.REGISTER_SIGNUP, { data }),
  };
}

export function checkReferralCode(referralCode: string): CustomAction {
  return {
    types: USER.CHECK_REFERRAL_CODE,
    promise: ({ request }) =>
      request.get(USER_PROFILE.CHECK_REFERRAL_CODE, {
        params: {
          referral_code: referralCode,
        },
      }),
  };
}

export function logout() {
  let data = {};
  const deviceId = localStorage.getItem('mobileDeviceId');
  if (IS_RN_WEBVIEW && deviceId) {
    data = { device_id: deviceId };
    localStorage.removeItem('mobileDeviceId');
  }

  return authAction({
    types: USER.LOGOUT,
    promise: ({ request }) =>
      request.post(USER_PROFILE.LOGOUT, {
        data,
      }),
  });
}

export const logoutFromSubAccount = token => {
  const deviceId = localStorage.getItem('mobileDeviceId');
  if (IS_RN_WEBVIEW && deviceId) {
    localStorage.removeItem('mobileDeviceId');
  }

  return fetch(formatUrl(USER_PROFILE.LOGOUT), {
    headers: { Authorization: `${token}` },
    method: 'POST',
    ...(IS_RN_WEBVIEW && deviceId
      ? { body: JSON.stringify({ device_id: deviceId }) }
      : {}),
  });
};

export function updateProfile(apiData: UpdateProfileArgs, showAlert = true) {
  return authAction({
    types: USER.UPDATE_PROFILE,
    promise: ({ request }) => request.post(USER_PROFILE.UPDATE, { data: apiData }),
    showAlert,
  });
}

export function addPhoneNumber(data: GetMobileOtpArgs) {
  return authAction({
    types: USER.ADD_PHONE_NUMBER,
    promise: ({ request }) => request.post(USER_PROFILE.ADD_PHONE_NUMBER, { data }),
  });
}

export function addPhoneVerificationToken(data: string) {
  return {
    type: SET_PHONE_VERIFICATION_TOKEN,
    payload: data,
  };
}

export function verifyMobileNumber(data: VerifyMobileOtpArgs) {
  return authAction({
    types: USER.VERIFY_PHONE_NUMBER_OTP,
    promise: ({ request }) =>
      request.post(USER_PROFILE.VERIFY_PHONE_NUMBER_OTP, { data }),
  });
}

export function resendPhoneNumberOTP(data: ResendMobileOtpArgs) {
  return authAction({
    types: USER.RESEND_PHONE_NUMBER_OTP,
    promise: ({ request }) =>
      request.post(USER_PROFILE.RESEND_PHONE_NUMBER_OTP, { data }),
  });
}

export function getBellAlerts() {
  return authAction({
    types: BELL_ALERTS.GET_BELL_ALERTS,
    promise: ({ request }) => request.get(USER_PROFILE.BELL_ALERTS),
  });
}

export function getVolumeHistory(params) {
  return {
    types: VOLUME_HISTORY.GET_VOLUME_HISTORY,
    promise: ({ request }) =>
      request.get(`${USER_PROFILE.VOLUME_HISTORY}?timeperiod=${params}`),
  };
}

export function getTradeIdeas(params: GetTradeIdeasArguments) {
  return authAction({
    types: EASY_OPTIONS.GET_TRADE_IDEAS,
    promise: ({ request }) => request.get(USER_PROFILE.EASY_OPTIONS, { params }),
  });
}

export function markAsRead(commonBellAlertIds: number[], userBellAlertIds: number[]) {
  return authAction({
    types: BELL_ALERTS.MARK_AS_READ,
    promise: ({ request }) =>
      request.post(USER_PROFILE.BELL_ALERTS_READ, {
        data: {
          bell_alert_ids: commonBellAlertIds,
          user_bell_alert_ids: userBellAlertIds,
        },
      }),
  });
}

export function getUserKycDocuments() {
  return authAction({
    types: USER.GET_KYC_DOCUMENTS,
    promise: ({ request }) => request.get(USER_PROFILE.GET_KYC_DOCUMENTS),
  });
}

export function getConsentPopupDetails(vendor: string) {
  return authAction({
    types: USER.GET_CONSENT_POPUP_DETAILS,
    promise: ({ request }) =>
      request.post(USER_PROFILE.GET_CONSENT_POPUP_DETAILS, {
        data: {
          vendor,
        },
      }),
  });
}

export function refreshKyc() {
  return authAction({
    types: USER.REFRESH_KYC,
    promise: ({ request }) => request.post(USER_PROFILE.REFRESH_KYC),
  });
}

export function getMobileOtp(data: GetMobileOtpArgs) {
  return {
    types: USER.GET_MOBILE_VERIFICATION_OTP,
    promise: ({ request }) => request.post(USER_PROFILE.PHONE_NUMBER, { data }),
  };
}

export function verifyMobileOtp(data: VerifyMobileOtpArgs) {
  return {
    types: USER.VERIFY_MOBILE_OTP,
    promise: ({ request }) => request.post(USER_PROFILE.VERIFY_PHONE_NUMBER, { data }),
  };
}

export function resendMobileOtp(data: ResendMobileOtpArgs) {
  return {
    types: USER.RESEND_MOBILE_OTP,
    promise: ({ request }) => request.post(USER_PROFILE.RESEND_PHONE_NUMBER, { data }),
  };
}

export function getKycStatus() {
  return authAction({
    types: USER.GET_KYC_STATUS,
    promise: ({ request }) => request.get(USER_PROFILE.KYC_STATUS),
  });
}

export function getWithdrawalsPendingCount() {
  return {
    types: USER.WITHDRAWAL_PENDING_COUNT,
    promise: ({ request }) => request.get(USER_PROFILE.WITHDRAWAL_PENDING),
  };
}

export function getKycCertificates() {
  return authAction({
    types: USER.GET_KYC_CERTIFICATES,
    promise: ({ request }) => request.get(USER_PROFILE.KYC_CERTIFICATES),
  });
}

export function changePassword(data) {
  return authAction({
    types: USER.CHANGE_PASSWORD,
    promise: ({ request }) => request.post(USER_PROFILE.CHANGE_PASSWORD, { data }),
  });
}

export function changePasswordNew(data) {
  return authAction({
    types: USER.CHANGE_PASSWORD_NEW,
    promise: ({ request }) => request.post(USER_PROFILE.CHANGE_PASSWORD_NEW, { data }),
  });
}

export function changePasswordOtpValidation(data) {
  return authAction({
    types: USER.CHANGE_PASSWORD_VALIDATION,
    promise: ({ request }) =>
      request.post(USER_PROFILE.CHANGE_PASSWORD_VALIDATION, { data }),
  });
}

export function changePasswordVerificationCode(data) {
  return authAction({
    types: USER.CHANGE_PASSWORD_VERIFICATION_CODE,
    promise: ({ request }) =>
      request.post(USER_PROFILE.CHANGE_PASSWORD_VERIFICATION_CODE, { data }),
  });
}

export function enableMFA({ otp, googleSecret, email_code }) {
  return authAction({
    types: USER.ENABLE_MFA,
    promise: ({ request }) =>
      request.post(USER_PROFILE.ENABLE_MFA, {
        data: {
          mfa_code: otp,
          email_code,
          google_secret: googleSecret,
        },
      }),
  });
}

export function enableMFASendEmailCode() {
  return authAction({
    types: USER.ENABLE_MFA_SEND_EMAIL_OTP,
    promise: ({ request }) => request.post(USER_PROFILE.ENABLE_MFA_SEND_EMAIL_CODE),
  });
}

export function disableMFA(mfa_code) {
  return authAction({
    types: USER.DISABLE_MFA,
    promise: ({ request }) =>
      request.post(USER_PROFILE.DISABLE_MFA, {
        data: {
          mfa_code,
        },
      }),
  });
}

interface ResetMfaPayload {
  mfa_code: string;
  google_secret: string;
  new_mfa_code: string;
  email_code: string;
}

export function resetMFA(payload: ResetMfaPayload) {
  return authAction({
    types: USER.RESET_MFA,
    promise: ({ request }) =>
      request.post(USER_PROFILE.RESET_MFA, {
        data: payload,
      }),
  });
}

export function getUnverifiedUsersWithdrawalLimit() {
  return authAction({
    types: USER.UNVERIFIED_USERS_WITHDRAWAL_LIMIT,
    promise: ({ request }) => request.get(USER_PROFILE.UNVERIFIED_USERS_WITHDRAWAL_LIMIT),
  });
}

// Deprecated
export function markIntroAsSeen(): CustomAction {
  return {
    types: USER.MARK_INTRO_SEEN,
    promise: ({ request }) => request.post('/profile/seenIntro'),
  };
}

export function forgotPassword(email: string, recaptcha_response: string): CustomAction {
  return {
    types: USER.FORGOT_PASSWORD,
    promise: ({ request }) =>
      request.post(USER_PROFILE.FORGOT_PASSWORD, {
        data: {
          email,
          recaptcha_response,
        },
      }),
  };
}

export function resetPassword(
  email: string,
  code: string | number,
  new_password: string
): CustomAction {
  return {
    types: USER.RESET_PASSWORD,
    promise: ({ request }) =>
      request.post(USER_PROFILE.RESET_PASSWORD, {
        data: {
          email,
          code,
          new_password,
        },
      }),
  };
}

export function sendCodeApiKey() {
  return authAction({
    fetch_main_acc_token: true,
    promise: ({ request }) => request.post(USER_PROFILE.SEND_CODE_API_KEY, {}),
  });
}

export function createApiKey(data) {
  return authAction({
    types: USER.CREATE_API_KEY,
    fetch_main_acc_token: true,
    promise: ({ request }) => request.post(USER_PROFILE.CREATE_API_KEY, { data }),
  });
}

export function updateAPIKey(data) {
  return authAction({
    types: USER.UPDATE_API_KEY,
    fetch_main_acc_token: true,
    promise: ({ request }) => request.put(USER_PROFILE.CREATE_API_KEY, { data }),
  });
}

export function getApiKeys() {
  return authAction({
    types: USER.GET_API_KEYS,
    promise: ({ request }) => request.get(USER_PROFILE.API_KEYS),
  });
}

export function deleteApiKey(data) {
  return authAction({
    types: USER.DELETE_API_KEY,
    fetch_main_acc_token: true,
    promise: ({ request }) => request.delete(USER_PROFILE.DELETE_API_KEY, { data }),
  });
}

export function getReferralData() {
  return authAction({
    types: USER.MY_REFERRALS,
    promise: ({ request }) => request.get(USER_PROFILE.MY_REFERRALS),
  });
}

export function getOffers(): CustomAction {
  return {
    types: USER.GET_OFFERS,
    promise: ({ request }) => request.get(USER_PROFILE.OFFERS),
  };
}

export function applyPromo(data): CustomAction {
  return {
    types: USER.APPLY_PROMO,
    promise: ({ request }) => request.post(USER_PROFILE.OFFERS_APPLY_PROMO, { data }),
  };
}

export function removePromo(data): CustomAction {
  return {
    types: USER.REMOVE_PROMO,
    promise: ({ request }) => request.post(USER_PROFILE.OFFERS_REMOVE_PROMO, { data }),
  };
}

export function getAppliedPromos(): CustomAction {
  return {
    types: USER.GET_APPLIED_PROMOS,
    promise: ({ request }) => request.get(USER_PROFILE.OFFERS_APPLIED),
  };
}

export function getActivityList() {
  return authAction({
    types: USER.GET_ACCOUNT_ACTIVITY,
    promise: ({ request }) => request.get(USER_PROFILE.HISTORY),
  });
}

export function getActivieLogins() {
  return authAction({
    types: USER.GET_ACTIVE_SESSIONS,
    promise: ({ request }) => request.get(SECURITY.ACTIVE_LOGINS),
  });
}

export function getAddresses(user_id) {
  return authAction({
    types: USER.GET_WITHDRAWAL_ADDRESSES,
    promise: ({ request }) => request.get(SECURITY.ADDRESS(user_id)),
  });
}

export function createWithdrawalAddress(data) {
  return authAction({
    types: USER.CREATE_WITHDRAWAL_ADDRESS,
    promise: ({ request }) =>
      request.post(SECURITY.WITHDRAWAL_ADDRESS, {
        data,
      }),
  });
}

export function updateWithdrawalAddress(data: unknown) {
  return authAction({
    types: USER.UPDATE_WITHDRAWAL_ADDRESS,
    promise: ({ request }) =>
      request.put(SECURITY.WITHDRAWAL_ADDRESS, {
        data,
      }),
  });
}

export function deleteWithdrawalAddress(data) {
  return authAction({
    types: USER.DELETE_WITHDRAWAL_ADDRESS,
    params: data,
    promise: ({ request }) =>
      request.delete(SECURITY.WITHDRAWAL_ADDRESS, {
        data,
      }),
  });
}

export function logoutFromAllDevices() {
  let data = { device_id: '' };
  const deviceId = localStorage.getItem('mobileDeviceId');
  if (IS_RN_WEBVIEW && deviceId) {
    data = { device_id: deviceId };
  }

  return authAction({
    types: USER.LOGOUT_FROM_ALL_DEVICES,
    promise: ({ request }) =>
      request.post(SECURITY.LOGOUT_ALL, {
        data,
      }),
  });
}

export function resendLink(email): CustomAction {
  return {
    types: USER.RESEND_LINK,
    promise: ({ request }) =>
      request.post(USER_PROFILE.REGISTER_RESEND_VERIFICATION_CODE, {
        data: { email },
      }),
  };
}

/* eslint-disable no-param-reassign */
export function registerVerification(payload): CustomAction {
  const payloadWithTrackingKeys = { ...payload };
  if (IS_RN_WEBVIEW) {
    payloadWithTrackingKeys.source = 'mobile_app';

    if (isAndroidOrIOSDevice()) {
      payloadWithTrackingKeys.device_os_type = getMobileOperatingSystem();
    }
  }

  return {
    types: USER.LOGIN,
    promise: ({ request }) =>
      request.post(USER_PROFILE.REGISTER_VERIFY, {
        data: payloadWithTrackingKeys,
      }),
  };
}
/* eslint-enable */

export function updateUserPreference(preferences, payload) {
  // const payload = preferences.preferences.favorites ? 'favorites' : '';
  return authAction({
    types: USER.UPDATE_PREFERENCES,
    payload: payload ? { ...payload } : { disable_notification: false },
    promise: ({ request }) =>
      request.post(USER_PROFILE.UPDATE_USER_PREFERENCES, {
        data: preferences,
      }),
  });
}

// eslint-disable-next-line camelcase
export function refreshToken(use_main_token = false) {
  return authAction({
    types: USER.REFRESH_TOKEN,
    // eslint-disable-next-line camelcase
    fetch_main_acc_token: use_main_token,

    promise: ({ request }) => request.get(USER_PROFILE.REFRESH_TOKEN),
  });
}

export function getUserProfile() {
  return authAction({
    types: USER.GET_USER_PROFILE,
    promise: ({ request }) => request.get(USER_PROFILE.GET_USER_PROFILE),
  });
}

export function getAccountAnalyticsPNLSummary() {
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_PNL_SUMMARY,
    promise: ({ request }) => request.get(USER_PROFILE.GET_ACCOUNT_ANALYTICS_PNL_SUMMARY),
  });
}

export function getAccountAnalyticsPNLChartData(payload: AccountAnalyticsApiArgs) {
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_PNL_CHART_DATA,
    promise: ({ request }) =>
      request.get(USER_PROFILE.GET_ACCOUNT_ANALYTICS_PNL_CHART_DATA, payload),
  });
}

export function getAccountAnalyticsVolume(payload: AccountAnalyticsApiArgs) {
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_VOLUME,
    promise: ({ request }) =>
      request.get(USER_PROFILE.GET_ACCOUNT_ANALYTICS_VOLUME, payload),
  });
}
export function getAccountAnalyticsFeesPaid(payload: AccountAnalyticsApiArgs) {
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_FEES_PAID,
    promise: ({ request }) =>
      request.get(USER_PROFILE.GET_ACCOUNT_ANALYTICS_FEES_PAID, payload),
  });
}

export function getAccountAnalyticsKeyMetrics() {
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_DAILY_EQUITY_CHANGE,
    promise: ({ request }) =>
      request.get(USER_PROFILE.GET_ACCOUNT_ANALYTICS_DAILY_EQUITY_CHANGE),
  });
}

export function getAccountAnalyticsStatistics(payload: AccountAnalyticsApiArgs) {
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_STATISTICS,
    promise: ({ request }) =>
      request.get(USER_PROFILE.GET_ACCOUNT_ANALYTICS_STATISTICS, payload),
  });
}

export function emailVerificationUsingLink(payload: EmailVerificationPayloadObject) {
  return {
    types: USER.EMAIL_VERIFICATION_WITH_LINK,
    promise: ({ request }) =>
      request.post(USER_PROFILE.VERIFY_EMAIL_WITH_LINK, { data: payload }),
  };
}

export function emailVerificationWithOuath(
  payload: EmailVerificationWithOuathPayloadObject
) {
  return {
    types: USER.EMAIL_VERIFICATION_WITH_OAUTH,
    promise: ({ request }) => request.post(OAUTH.VERIFY_WITH_OAUTH, payload),
  };
}

export function getAccountAnalyticsDetoEarnChart(payload) {
  const { params } = payload;
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_DETO_CHART,
    promise: ({ request }) =>
      request.get(USER_PROFILE.GET_ACCOUNT_ANALYTICS_DETO_CHART, {
        params,
      }),
  });
}

export function getAccountAnalyticsPNLChart(payload) {
  const { params } = payload;
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_PNL_CHART,
    promise: ({ request }) =>
      request.get(USER_PROFILE.GET_ACCOUNT_ANALYTICS_PNL_CHART, {
        params,
      }),
  });
}

export function getAccountAnalyticsActivity(payload) {
  return authAction({
    types: USER.GET_ACCOUNT_ANALYTICS_ACTIVITY,
    promise: ({ request }) =>
      request.get(`${USER_PROFILE.GET_ACCOUNT_ANALYTICS_ACTIVITY}`, payload),
  });
}
export function getSubAccounts() {
  return authAction({
    types: USER.GET_SUB_ACCOUNTS,
    fetch_main_acc_token: true,
    promise: ({ request }) => request.get(SUB_ACCOUNTS.GET_SUB_ACCOUNTS),
  });
}

export function createSubAccount(data: any) {
  return authAction({
    types: USER.CREATE_SUB_ACCOUNT,
    fetch_main_acc_token: true,
    promise: ({ request }) =>
      request.post(SUB_ACCOUNTS.SUB_ACCOUNT, {
        data,
      }),
  });
}

export function transferFundsSubAccount(data) {
  return authAction({
    types: USER.TRANSFER_FUNDS_SUB_ACCOUNTS,
    fetch_main_acc_token: true,
    payload: data,
    promise: ({ request }) =>
      request.post(SUB_ACCOUNTS.TRNASFER_FUNDS_SUB_ACCOUNT, {
        data,
      }),
  });
}

export function getTransferFundsHistorySubAccount(payload) {
  return authAction({
    types: USER.TRANSFER_FUNDS_HISTORY_SUB_ACCOUNTS,
    params: {
      backgroundFetch: payload.backgroundFetch,
    },
    promise: ({ request }) =>
      request.get(SUB_ACCOUNTS.TRNASFER_FUNDS_HISTORY_SUB_ACCOUNT, {
        params: payload,
        keepHeaders: true,
      }),
  });
}

export function getSubAccountWalletBalance(data) {
  return authAction({
    types: USER.GET_BALANCE_SUB_ACCOUNT,
    fetch_main_acc_token: true,
    promise: ({ request }) =>
      request.get(WALLET_API.WITHDRAWAL_LIMITS, {
        params: {
          asset_symbol: data.asset_symbol,
          sub_account_user_id: data.sub_account_user_id,
        },
      }),
  });
}

export function getAccountToken(data) {
  return authAction({
    types: USER.GET_ACCOUNT_TOKEN,
    fetch_main_acc_token: true,
    promise: ({ request }) =>
      request.post(SUB_ACCOUNTS.GET_ACCOUNT_TOKEN, {
        data,
      }),
  });
}

export function updateSubAccount(data: EditSubUser) {
  return authAction({
    types: USER.UPDATE_SUB_ACCOUNT,
    fetch_main_acc_token: true,
    promise: ({ request }) =>
      request.put(SUB_ACCOUNTS.SUB_ACCOUNT, {
        data,
      }),
  });
}
export function resetTransferFundHistory() {
  return {
    type: TRANSFER_CONSTANTS.RESET_TRANSFER_FUND_HISTORY,
    payload: [],
  };
}

export function getKYCVendorLink(args?: KycVendorLinkApiArgs) {
  return authAction({
    types: USER.GET_KYC_VENDOR_LINK,
    promise: ({ request }) => request.get(USER_PROFILE.GET_KYC_VENDOR_LINK, args),
  });
}

const createAlpyneUserKYC = () => {
  return authAction({
    types: USER.CREATE_ALPYNE_USER_KYC,
    promise: ({ request }) => request.post(ALPYNE_API.CREATE_USER),
  });
};

const shareKycDataOnmeta = (payload: ShareKycDataOnmetaArgs) => {
  return authAction({
    types: USER.SHARE_KYC_DATA_ONMETA,
    promise: ({ request }) => request.post(ONMETA_API.SHARE_KYC_DATA_ONRAMP, payload),
  });
};

export const getTradeHistory = (params: GetTradeHistoryArgs) => {
  return authAction({
    types: USER.GET_TRADE_HISTORY,
    promise: ({ request }) => request.get(USER_PROFILE.GET_TRADE_HISTORY, params),
  });
};

export const getTradeHistoryRecords = (params: GetTradeHistoryRecordsArgs) => {
  return authAction({
    types: USER.FETCH_TRADE_HISTORY_RECORDS,
    promise: ({ request }) =>
      request.get(USER_PROFILE.FETCH_TRADE_HISTORY_RECORDS, params),
  });
};

function raiseFreshdeskTicket() {
  return authAction({
    types: USER.FRESH_DESK_TICKET_REQUEST_OTP,
    promise: ({ request }) => request.post(FRESHDESK_API.SEND_OTP),
  });
}

function resendFreshDeskTicketOtp() {
  return authAction({
    types: USER.FRESH_DESK_TICKET_REQUEST_OTP,
    promise: ({ request }) => request.post(FRESHDESK_API.RESEND_OTP),
  });
}

function validateFreshDeskOtp(payload: FreshDeskTicketPayload) {
  return authAction({
    types: USER.FRESH_DESK_TICKET_VALIDATION,
    promise: ({ request }) => request.post(FRESHDESK_API.VALIDATE_OTP, payload),
  });
}

function getAccountEquityChartData(payload: AccountAnalyticsApiArgs) {
  return authAction({
    types: USER.ACCOUNT_EQUITY_CHART,
    promise: ({ request }) =>
      request.get(USER_PROFILE.ACCOUNT_ANALYTICS_EQUITY_CHART, payload),
  });
}

function getDepositAndWithdrawalsChartData(payload: AccountAnalyticsApiArgs) {
  return authAction({
    types: USER.DEPOSIT_WITHDRAWALS_CHART,
    promise: ({ request }) => request.get(USER_PROFILE.DEPOSIT_AND_WITHDRAWALS, payload),
  });
}

function fetchUserIpAddress() {
  return authAction({
    types: USER.IP_ADDRESS,
    promise: ({ request }) => request.get(USER_PROFILE.IP_ADDRESS),
  });
}

const showKycShareConsentPopup = (payload: {
  show: boolean;
  showWelcomeScreen?: boolean;
}) => {
  return {
    type: USER.SHOW_KYC_SHARE_CONSENT_POPUP,
    payload,
  };
};

const bankAccountAdded = (payload: { isBankAccountAdded: boolean }) => {
  return {
    type: USER.BANK_ACCOUNT_ADDED,
    payload,
  };
};

function deleteAccount(payload: DeleteAccountPayload) {
  return authAction({
    types: USER.DELETE_ACCOUNT,
    promise: ({ request }) => request.post(USER_PROFILE.DELETE_ACCOUNT, payload),
  });
}

function deleteAccountConfirmation(payload: DeleteAccountConfirmationPayload) {
  return authAction({
    types: USER.DELETE_ACCOUNT_CONFIRMATION,
    promise: ({ request }) =>
      request.get(USER_PROFILE.DELETE_ACCOUNT_CONFIRMATION, payload),
  });
}

function generateRecoverOtp(payload: GenerateRecoveryOtpPayload) {
  return {
    types: USER.GENERATE_RECOVERY_OTP,
    promise: ({ request }) =>
      request.get(RECOVER_ACCOUNT.GENERATE_OTP, {
        params: payload.data,
        qsOptions: { encode: false },
      }),
  };
}

function verifyRecoveryOtp(payload: VerifyRecoveryOtpPayload) {
  return {
    types: USER.VERIFY_RECOVERY_OTP_AND_GET_VENDOR,
    promise: ({ request }) =>
      request.get(RECOVER_ACCOUNT.VERIFY_AND_GET_VENDOR, {
        params: payload.data,
        qsOptions: { encode: false },
      }),
  };
}

export {
  bankAccountAdded,
  createAlpyneUserKYC,
  deleteAccount,
  fetchUserIpAddress,
  getAccountEquityChartData,
  getDepositAndWithdrawalsChartData,
  raiseFreshdeskTicket,
  resendFreshDeskTicketOtp,
  shareKycDataOnmeta,
  showKycShareConsentPopup,
  validateFreshDeskOtp,
  generateRecoverOtp,
  verifyRecoveryOtp,
  deleteAccountConfirmation,
};
