import React from 'react';

import { compose } from 'recompose';

import { is } from 'helpers/ramda';

const getResult = (key, props) => (is(Function, key) ? key(props) : key);

const Render = React.memo(props => {
  const { when, children, else: Else } = props;
  return getResult(when, props) ? children : getResult(Else, props);
});

// Render.propTypes = {
//   when: PropTypes.any.isRequired,
//   children: PropTypes.any.isRequired,
//   else: PropTypes.any,
// };

const enhance = compose(React.memo);

export default enhance(Render);
