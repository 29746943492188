import keyMirror from 'helpers/keymirror';

const UPDATE_FUNDING_CHART_DATA = 'UPDATE_FUNDING_CHART_DATA';
const UPDATE_DURATION = 'UPDATE_DURATION';
const UPDATE_CONTRACT_TYPE = 'UPDATE_CONTRACT_TYPE';
const UPDATE_SELECTED_CURRENCY = 'UPDATE_SELECTED_CURRENCY';

const PNL_ANALYTICS_API_ACTION_TYPES = keyMirror({
  FETCH_FUNDING_CHART_DATA: null,
});

export {
  UPDATE_FUNDING_CHART_DATA,
  UPDATE_DURATION,
  UPDATE_CONTRACT_TYPE,
  PNL_ANALYTICS_API_ACTION_TYPES,
  UPDATE_SELECTED_CURRENCY,
};
