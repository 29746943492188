import AUTH_ACTION_TYPES, {
  LoginViaBiometricsActionTypes,
  LoginViaQrActionTypes,
} from 'actionTypes/auth';
import { AUTH } from 'constants/api';
import createAction from 'helpers/createAction';
import { CustomAction } from 'types/Istore';

// #region Login Via QR Action Types
export const openLoginViaQrMobileScanner = createAction(
  LoginViaQrActionTypes.OPEN_SCANNER
);

export const refreshLoginViaQrToken = createAction(LoginViaQrActionTypes.REFRESH_TOKEN);

export const setLoginViaQrTokenStatus = createAction(
  LoginViaQrActionTypes.SET_QR_TOKEN_STATUS
);

export const qrLoginAuthorizedByUser = createAction(
  LoginViaQrActionTypes.LOGIN_AUTHORIZED
);

export const setUserAuthorizedByLoginViaQr = createAction(
  LoginViaQrActionTypes.SET_AUTHORIZED_USER
);
// #endregion

// #region Login Via Biometrics Action Types
export const biometricsLoginAuthorizedByUser = createAction(
  LoginViaBiometricsActionTypes.LOGIN_AUTHORIZED
);

export const biometricsLoginSwitchToSubaccount = createAction(
  LoginViaBiometricsActionTypes.SWITCH_TO_SUBACCOUNT
);

export const setUserAuthorizedByLoginViaBiometrics = createAction(
  LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER
);

export const cleanUpSessionByLoginViaBiometrics = createAction(
  LoginViaBiometricsActionTypes.CLEAN_UP_SESSION
);
// #endregion

// #region Login Via QR API Action Types
export function createLoginViaQrToken(
  device: string,
  recaptchaToken: string
): CustomAction {
  return {
    types: AUTH_ACTION_TYPES[LoginViaQrActionTypes.CREATE_TOKEN],
    promise: ({ request }) =>
      request.post(AUTH.LOGIN.CREATE_LOGIN_VIA_QR_TOKEN, {
        data: { device, recaptcha_response: recaptchaToken },
      }),
  };
}

export function getLoginViaQrTokenDetails(token: string): CustomAction {
  return {
    types: AUTH_ACTION_TYPES[LoginViaQrActionTypes.GET_TOKEN_DETAILS],
    promise: ({ request }) =>
      request.get(AUTH.LOGIN.GET_LOGIN_VIA_QR_TOKEN_DETAILS, {
        params: { token },
      }),
  };
}
// #endregion
