import { ASSET_SYMBOL } from 'constants/constants';
import { divide, max, reduceWhile } from 'helpers/ramda';
import { ContractType, Product } from 'types/IProducts';

const getProfitProbabilityDensity = (probability: number) => {
  if (probability < 33) {
    return 'loss';
  }
  if (probability < 66) {
    return 'neutral';
  }
  return 'profit';
};

const getPotentialProfitDensity = (profit: number) => {
  return profit > 0 ? 'profit' : 'loss';
};

const getNegativeSignBeforeSymbol = (value: number, symbol = ASSET_SYMBOL) => {
  return value < 0 ? `-${Math.abs(value)} ${symbol}` : `${value} ${symbol}`;
};

const getImpactFromPremium = (premium: number, orderbook: any, product: Product) => {
  if (!orderbook) {
    return {
      impactPrice: 0,
      totalContracts: 0,
    };
  }
  const iterator = (acc: any, book: any) => {
    return {
      avgNumerator:
        (acc.avgNumerator + Number(book.price) * Number(book.size)) *
        Number(product.contract_value),
      avgDenominator:
        (acc.avgDenominator + Number(book.size)) * Number(product.contract_value),
    };
  };
  const predicate = (acc: { avgNumerator: number }) => {
    return Number(acc.avgNumerator) <= Number(premium);
  };

  const impact = reduceWhile(
    predicate,
    iterator,
    {
      avgNumerator: 0,
      avgDenominator: 0,
    },
    orderbook
  );

  const pricePerUnderlying = divide(impact.avgNumerator, impact.avgDenominator);
  const pricePerContract = Number(pricePerUnderlying) * Number(product.contract_value);
  const totalContracts = premium / pricePerContract;

  return {
    impactPrice: pricePerUnderlying,
    totalContracts: parseInt(String(totalContracts), 10),
  };
};

const projectedProfitByImpact = (
  targetPrice: number,
  strikePrice: number,
  impactPrice: number,
  product: Product,
  size: number
) => {
  let priceAtExpiration;
  const { contract_value: contractValue, contract_type: contractType } = product;
  const notional = size * Number(contractValue);
  if (contractType === ContractType.CallOptions) {
    priceAtExpiration = max(0, Number(targetPrice) - Number(strikePrice));
  }

  if (contractType === ContractType.PutOptions) {
    priceAtExpiration = max(0, Number(strikePrice) - Number(targetPrice));
  }

  return (Number(priceAtExpiration) - Number(impactPrice)) * notional;
};

const projectedProfitPercentage = (capitalValue: number, projectedProfit: number) => {
  return (projectedProfit / capitalValue) * 100;
};

export {
  getImpactFromPremium,
  getNegativeSignBeforeSymbol,
  getPotentialProfitDensity,
  getProfitProbabilityDensity,
  projectedProfitByImpact,
  projectedProfitPercentage,
};
