/* eslint-disable camelcase */
import { resetOpenPositionCancellations } from 'actions/cancellations';
import { updateProductLeverage } from 'actions/orderbook';
import {
  subscribeOpenPositionSpot,
  subscribeOpenPostionMark,
  subscribeSelecteProductSpot,
  subscribeTicker,
  unsubscribeMark,
  unsubscribeOpenPositionSpot,
} from 'actions/socket';
import {
  updatePosition,
  updateOpenOrders,
  updateStopOrders,
  getOpenOrders,
  getOpenStopOrders,
  getPositions,
  updateBracketOrder,
  deleteBracketOrder,
} from 'actions/trade';
import { updateBalance } from 'actions/wallet';
import { getAllContractsData } from 'components/holdings/helper';
import {
  clone,
  concat,
  eqBy,
  filter,
  forEach,
  isEmpty,
  path,
  prop,
  propEq,
  sort,
  uniqWith,
} from 'helpers/ramda';
import {
  getBoolean,
  getContractType,
  contractsObject,
  sortOnCreatedAtDesc,
} from 'helpers/utils';
import { isTruthy } from 'ramdax';
import { PRIVATE_SOCKET_ACTIONS } from 'reducers/helpers/trade';
import {
  updatePortfolioMargin,
  updateCrossMargin,
  updateMultiCollateral,
} from 'variableStore/actions';

import {
  handleOpenOrderReduceOnlyNotification,
  orderChannelNotifications,
  positionsChannelNotifications,
} from './notifications';
// import OpenPositions from 'components/open_positions';

/**
 * Adds product and meta to each order and filter it into three types i.e openOrders , stopOrders , bracketOrders
 */
export const filterOrdersAndAppendProduct = (snapshot, product, meta) => {
  const orders = { openOrders: [], stopOrders: [], bracketOrders: [] };
  const isOpenOrder = order => order.state === 'open';
  const isStopOrder = order =>
    order.state === 'pending' && order.stop_order_type !== 'liquidation_order';
  const hasBracketOrder = order => isTruthy(order.bracket_order);
  const appendProduct = order => ({ ...order, product, meta });

  for (let i = 0; i < snapshot.length; i++) {
    const order = appendProduct(snapshot[i]);
    if (isOpenOrder(order)) {
      orders.openOrders.push(order);
    } else if (isStopOrder(order)) {
      orders.stopOrders.push(order);
    }
    if (hasBracketOrder(order)) {
      orders.bracketOrders.push(order);
    }
  }

  return orders;
};

export const sortOrderAndConcatWithState = ({
  orders,
  orderType,
  contractType,
  productID,
  state,
}) => {
  return sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
    uniqWith(eqBy(prop('id')))(
      concat(
        orders,
        filter(
          obj => obj.product.id !== productID,
          path([orderType, [contractType], 'data'], state.holdings) || []
        )
      )
    )
  );
};

export const orderActionsMap = {
  // positions channel
  positions: (data, state) => {
    const { products, selected_product } = state.trade;

    switch (data.action) {
      case 'snapshot':
        const positionsSnapshot = uniqWith(eqBy(propEq('product_id')))(data.result);
        const openPositions = contractsObject();

        // Adding product and bracket order data
        forEach(position => {
          position.bracket_orders = {
            stop_loss_order: {
              limit_price: null,
              stop_price: null,
              trail_amount: null,
            },
            take_profit_order: {
              limit_price: null,
              stop_price: null,
              trail_amount: null,
            },
          };
          position.product = products[position.product_id];

          const contractType = getContractType(
            path(['product', 'contract_type'], position)
          );

          openPositions[contractType]?.data?.push(position);
        }, positionsSnapshot);

        // Sorting position on basis of created data
        forEach(
          key => openPositions[key].data.sort(sortOnCreatedAtDesc),
          Object.keys(openPositions)
        );

        openPositions.all = getAllContractsData(openPositions);

        const actions = [getPositions(openPositions)];
        positionsSnapshot.map(position => {
          actions.push(subscribeOpenPostionMark(position.product_symbol));
          actions.push(subscribeOpenPositionSpot(position.product.spot_index.symbol));
          return null;
        });

        return actions;

      default: {
        const { product_id } = data;
        const updatedPositionData = {
          ...data,
          product: products[product_id],
          adl_level: 1,
          auto_topup: getBoolean(data.auto_topup),
        };

        const actions = [];

        try {
          switch (data.action) {
            case PRIVATE_SOCKET_ACTIONS.CREATE:
              actions.push(subscribeOpenPostionMark(updatedPositionData.product.symbol));
              actions.push(
                subscribeOpenPositionSpot(updatedPositionData.product.spot_index.symbol)
              );
              break;
            case PRIVATE_SOCKET_ACTIONS.DELETE:
              if (selected_product) {
                if (Number(data.product_id) !== Number(selected_product.id)) {
                  actions.push(unsubscribeMark(data.product_symbol));
                  actions.push(
                    unsubscribeOpenPositionSpot(
                      updatedPositionData.product.spot_index.symbol
                    )
                  );
                  // actions.push(unsubscribeOpenPositionSpot(data.product))
                }
              } else {
                actions.push(unsubscribeMark(data.product_symbol));
                actions.push(
                  unsubscribeOpenPositionSpot(
                    updatedPositionData.product.spot_index.symbol
                  )
                );
              }
              break;
            default:
              actions.push([]);
          }
          // data.action === PRIVATE_SOCKET_ACTIONS.CREATE
          //   ? actions.push(subscribeOpenPostionMark(updatedPositionData.product.symbol))
          //   : data.action === PRIVATE_SOCKET_ACTIONS.DELETE && Number(data.product_id) !== Number(selected_product?.id)
          //   ? actions.push(unsubscribeMark(updatedPositionData.product.symbol))
          //   : actions.push([]);
        } catch (error) {
          console.error(
            'Mark price unsubscription/subscription failed due position update',
            error
          );
        }

        return [
          ...actions,
          resetOpenPositionCancellations([data]),
          updatePosition(updatedPositionData),
          data.reason === 'auto_topup'
            ? positionsChannelNotifications.auto_topup(data, state)
            : () => {},
        ];
      }
    }
  },
  // margins channels
  margins: (data, state) => {
    const { assets } = state.trade;
    const newBalanceObj = {
      asset: clone(assets[data.asset_id]),
      ...data,
      updated_at: new Date(),
    };
    return updateBalance(newBalanceObj);
  },
  // orders channels
  orders: (data, state) => {
    // let { products } = state.trade;
    switch (data.action) {
      case 'snapshot':
        const orderSnapshots = data.result;
        if (orderSnapshots && orderSnapshots.length > 0) {
          const productID = path(['result', 0, 'product_id'], data);
          const productObj = path(['trade', 'products', productID], state);
          const contractType = getContractType(productObj?.contract_type);

          const { openOrders, stopOrders, bracketOrders } = filterOrdersAndAppendProduct(
            orderSnapshots,
            productObj,
            data.meta
          );

          const sortedOpenOrders = sortOrderAndConcatWithState({
            orders: openOrders,
            orderType: 'openOrders',
            contractType,
            productID,
            state,
          });

          const sortedStopOrders = sortOrderAndConcatWithState({
            orders: stopOrders,
            orderType: 'openStopOrders',
            contractType,
            productID,
            state,
          });
          // Get pagination details here
          // R.forEach(obj => {
          //   obj.product = productObj;
          //   obj.meta = data.meta;
          //   // If the below flag is set, then it's a stopOrder

          //   if (obj.state === 'open') {
          //     openOrders.push(obj);
          //   } else if (
          //     obj.state === 'pending' &&
          //     obj.stop_order_type !== 'liquidation_order'
          //   ) {
          //     stopOrders.push(obj);
          //   }

          //   if (obj.bracket_order) {
          //     bracketOrders.push(obj);
          //   }
          // }, orderSnapshots);

          return [
            openOrders.length > 0 ? getOpenOrders(sortedOpenOrders) : () => {},
            stopOrders.length > 0 ? getOpenStopOrders(sortedStopOrders) : () => {},
            !isEmpty(bracketOrders) ? updateBracketOrder(bracketOrders) : () => {},
          ];
        }
        break;

      default:
        data.product = path(['products', [data.product_id]], state.trade);
        let actions = [data.bracket_order ? updateBracketOrder([data]) : () => {}];

        if (orderChannelNotifications[data.reason]) {
          actions = actions.concat(orderChannelNotifications[data.reason](data, state));
        }

        if (data.reduce_only) {
          actions = actions.concat(handleOpenOrderReduceOnlyNotification(data, state));
        }

        if (data.reason === 'stop_trigger') {
          // Stop order has triggered, delete it from stop orders and push to open orders
          actions.push(updateStopOrders(data));
          const newData = JSON.parse(JSON.stringify(data));
          newData.action = 'create';
          actions.push(updateOpenOrders(newData));
        } else if (data.state === 'closed' || data.state === 'cancelled') {
          // data.action = 'delete';
          actions.push(updateOpenOrders(data));
          actions.push(updateStopOrders(data));
          if (data.action === 'delete' && data.bracket_order) {
            actions.push(deleteBracketOrder(data));
          }
        } else if (data.state === 'open') {
          actions.push(updateOpenOrders(data));
          if (data.stop_order_type) {
            actions.push(updateStopOrders(data));
          }
        } else {
          // state: pending
          actions.push(updateStopOrders(data));
        }
        return actions;
    }
  },
  portfolio_margins: data => updatePortfolioMargin(data),
  multi_collateral: data => updateMultiCollateral(data),
  cross_margin: data => updateCrossMargin(data),
  user_product: data => updateProductLeverage(data),
};
