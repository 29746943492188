import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import useMediaQuery from 'hooks/useMediaQuery';
import useMount from 'hooks/useMount';

const ClickOutside = React.memo(props => {
  const { children, onOutsideClick, ...rest } = props;

  const node = useRef();

  const { whenBelowMd } = useMediaQuery();
  const event = whenBelowMd ? 'touchstart' : 'mousedown';

  const handleClick = e => {
    if (node.current && !node.current.contains(e.target)) {
      onOutsideClick(e);
    }
  };

  useMount(() => {
    document.addEventListener(event, handleClick);

    return () => document.removeEventListener(event, handleClick);
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    (<div ref={node} {...rest} data-palette="ClickOutside">
      {children}
    </div>)
  );
});

ClickOutside.propTypes = {
  onOutsideClick: PropTypes.func.isRequired,
};

export default ClickOutside;
