import keyMirror from '../helpers/keymirror';

export const ORDER_BOOK_PRICE_CLICK = 'ORDER_BOOK_PRICE_CLICK';
export const BRACKET_ORDER_MODAL_OPEN = 'BRACKET_ORDER_MODAL_OPEN';
export const UPDATE_BRACKET_ORDER_INFO = 'UPDATE_BRACKET_ORDER_INFO';
export const UPDATE_PRODUCT_LEVERAGE = 'UPDATE_PRODUCT_LEVERAGE';
const actionTypes = keyMirror({
  CHANGE_ORDER_LEVERAGE: null,
  GET_ORDER_LEVERAGE: null,
});
export const ORDER_BOOK_STALE = 'ORDER_BOOK_STALE';

export default actionTypes;
