export const LAYOUT_SETUP_CONSTANTS = {
  UPDATE_LAYOUT_SETUP: 'UPDATE_LAYOUT_SETUP',
  CHANGED_LAYOUT: 'CHANGED_LAYOUT',
  CHANGE_SHOW_RECENT_TRADE_PANEL: 'CHANGE_SHOW_RECENT_TRADE_PANEL',
  RESET_LAYOUT: 'RESET_LAYOUT',
  RESET_LAYOUT_CALLBACK: 'RESET_LAYOUT_CALLBACK',
  UPDATE_GRID_LAYOUT: 'UPDATE_GRID_LAYOUT',
  RESET_GRID_LAYOUT: 'RESET_GRID_LAYOUT',
  RESIZED_HOLDING_PANEL: 'RESIZED_HOLDING_PANEL',
};
