import formatAnalyticsValue from 'components/accountAnalytics/AccountAnalytics.helpers';
import { FundingRecord } from 'components/accountAnalytics/AccountAnalytics.types';
import { VANILLA_SETTLING_ASSET } from 'constants/constants';
import { CURRENCY_STYLE } from 'constants/currency';
import { STORAGE_KEYS } from 'constants/enums';
import { ENABLE_INR_TOGGLE_IN_ACCOUNT_ANALYTICS } from 'constants/featureFlag';
import { TIME_FORMATS } from 'constants/timeformats';
import {
  dateFormat,
  endOf,
  getDateTime,
  getTimestampInMS,
  getUnixTimestamp,
  startOf,
  toUTCLocal,
} from 'helpers/day';
import { groupBy } from 'helpers/ramda';

/**
 * Converts an array of funding records into a format suitable for charting.
 * @param records - The array of funding records.
 * @param valueMultiplier - The multiplier to apply to the funding values.
 * @returns An array of objects representing data points for the chart.
 */
const getSeriesDataPoint = (records: FundingRecord[], valueMultiplier: number) => {
  return records.map(record => ({
    x: getTimestampInMS(toUTCLocal(record.day)),
    y: Number(record.funding) * valueMultiplier,
  }));
};

/**
 * Groups funding records by week of the year.
 * @param records - The array of funding records.
 * @returns An object where each key is a timestamp representing the start of the week,
 * and each value is an array of records for that week.
 */
export const groupRecordsFormatByWeekYear = (records: FundingRecord[]) => {
  return groupBy(record => {
    // Convert the date to the start of the week and then to a Unix timestamp
    const timestamp = getUnixTimestamp(startOf(getDateTime(record.day), 'isoWeek'));
    return String(timestamp);
  }, records);
};

/**
 * Generates HTML content for tooltips in charts.
 * @returns A string containing the HTML for the tooltip.
 */
export const getTooltipContent = ({
  x,
  y,
  isAboveThreshold,
  styles,
  activeCurrency,
}: {
  x: number;
  y: number;
  isAboveThreshold: boolean;
  styles: { [key: string]: string };
  activeCurrency: string;
}): string => {
  const startOfWeek = startOf(getDateTime(x), 'week');
  const endOfWeek = endOf(getDateTime(x), 'week');

  const formattedStartTime = dateFormat(startOfWeek, TIME_FORMATS.Do_MMM_YYYY);
  const formattedEndTime = dateFormat(endOfWeek, TIME_FORMATS.Do_MMM_YYYY);

  const formattedDate = isAboveThreshold
    ? `${formattedStartTime} - ${formattedEndTime}`
    : dateFormat(getDateTime(x), TIME_FORMATS.Do_MMM_YYYY);

  const displayValue = formatAnalyticsValue(Number(y), {
    currency: activeCurrency,
    style: 'decimal',
  });

  return `
    <div class="${styles.tooltip}">
      <p class="${styles.pointDate}">${formattedDate}</p>
      <p class="${styles.amount}">${displayValue || y} ${activeCurrency}</p>
    </div>`;
};

/**
 * Retrieves the currency preference stored in local storage.
 * @returns The stored currency or null if not found.
 */
const getStoredCurrency = () => {
  const storedCurrency = localStorage.getItem(STORAGE_KEYS.FEAT_TOGGLE_CURRENCY);
  return storedCurrency ? JSON.parse(storedCurrency) : null;
};

/**
 * Determines the default currency based on feature flags.
 * @returns The default currency style or symbol.
 */
const getDefaultCurrency = () =>
  ENABLE_INR_TOGGLE_IN_ACCOUNT_ANALYTICS ? CURRENCY_STYLE.INDIAN : VANILLA_SETTLING_ASSET;

/**
 * Calculates the total funding amount from an array of funding records.
 * @param fundingRecords - The array of funding records.
 * @returns The total funding amount.
 */
const calculateTotalFunding = (fundingRecords: FundingRecord[]) =>
  fundingRecords.reduce((total, { funding }) => total + Number(funding), 0);

export {
  getSeriesDataPoint,
  getStoredCurrency,
  getDefaultCurrency,
  calculateTotalFunding,
};
