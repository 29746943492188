import keyMirror from '../helpers/keymirror';

const actionTypes = keyMirror({
  STRATEGIES: null,
  DEPLOY: null,
  STOP: null,
  ACTIVE_STRATEGIES: null,
});

export default actionTypes;
