/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import TRADE from '../actionTypes/trade';

const initialState = {
  default_auto_topup: false,
  deto_for_commission: false,
  interest_credit: false,
  email_preferences: {
    adl: true,
    liquidation: true,
    margin_topup: true,
    order_fill: false,
    stop_order_trigger: false,
    order_cancel: true,
  },
  notification_preferences: {
    adl: true,
    liquidation: true,
    margin_topup: false,
    order_cancel: false,
    order_fill: false,
    stop_order_trigger: false,
  },
  // enabled_portfolios: {},
  is_data_set: false,
  price_alert_assets: [],
};

// eslint-disable-next-line default-param-last
const tradePreference = (state = initialState, action) => {
  switch (action.type) {
    case TRADE.TRADE_PREFERENCES.SUCCESS: {
      const {
        default_auto_topup,
        deto_for_commission,
        interest_credit,
        email_preferences,
        vip_level,
        vip_discount_factor,
        // enabled_portfolios,
        notification_preferences,
        price_alert_assets,
      } = action.result.result || {};
      return {
        ...state,
        default_auto_topup,
        deto_for_commission,
        interest_credit:
          // eslint-disable-next-line no-unneeded-ternary
          interest_credit === 1 || interest_credit === true || interest_credit === 'true'
            ? true
            : false,
        email_preferences,
        notification_preferences,
        vip_level,
        vip_discount_factor,
        price_alert_assets,
        // enabled_portfolios,
        is_data_set: true,
      };
    }
    // case TRADE.UPDATE_PORTFOLIO_MARGIN_NOTIFICATION.SUCCESS:
    // case TRADE.UPDATE_MARGIN_MODE.SUCCESS:
    // case TRADE.UPDATE_PORTFOLIO_MARGIN.SUCCESS: {
    //   const { enabled_portfolios } = action.result.result;
    //   return {
    //     ...state,
    //     enabled_portfolios,
    //   };
    // }

    case TRADE.UPDATE_TRADE_PREFERENCE.SUCCESS: {
      const {
        default_auto_topup,
        deto_for_commission,
        interest_credit,
        email_preferences,
        notification_preferences,
        price_alert_assets,
      } = action.result.result || {};

      return {
        ...state,
        default_auto_topup,
        deto_for_commission,
        interest_credit,
        email_preferences,
        notification_preferences,
        price_alert_assets,
      };
    }
    case TRADE.UPDATE_PRICE_ALERT_PREFERENCE.SUCCESS: {
      const {
        payload: { data },
      } = action;

      let price_alert_assets = [...state.price_alert_assets];
      if (action.result.result.user_subscribed) {
        price_alert_assets.push(data.underlying_asset);
      } else {
        price_alert_assets = price_alert_assets.filter(
          asset => asset !== data.underlying_asset
        );
      }
      return {
        ...state,
        price_alert_assets,
        notification_preferences: {
          ...state.notification_preferences,
          price_alert: action.result.result.price_alert_enabled,
        },
      };
    }
    default:
      return state;
  }
};

export default tradePreference;
