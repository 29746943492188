/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
import {
  REMITANO_API,
  SIMPLEX_API,
  WALLET_API,
  POSITIONS,
  ONMETA_API,
  NEOFI_API,
  ONRAMPER_API,
  MUDREX_API,
} from 'constants/api';
import createAction from 'helpers/createAction';
import {
  GetOnmetaQuotationPayload,
  GetNeofiQuotationPayload,
  GetNeofiOrderUrlPayload,
  GetOnramperOrderUrlParams,
  MudrexQuotePayload,
} from 'types/actions/wallet';
import { CustomAction } from 'types/Istore';

import WALLET, {
  CLOSE_CURRENCY_CONVERSION_POPUP,
  CLOSE_POPUP,
  OPEN_CURRENCY_CONVERSION_POPUP,
  OPEN_POPUP,
  UPDATE_BALANCE,
  // UPDATE_PORTFOLIO_MARGIN_PARAMS,
} from '../actionTypes/wallet';
import { authAction } from './user';

export function getBalances() {
  return authAction({
    types: WALLET.BALANCES,
    promise: ({ request }) => request.get(WALLET_API.BALANCES),
  });
}
export function getInvestBalances() {
  return authAction({
    types: WALLET.INVEST_BALANCES,
    promise: ({ request }) => request.get(WALLET_API.INVEST_BALANCES),
  });
}
export function getWalletAddress(payload: { asset_symbol: string; network: string }) {
  return authAction({
    types: WALLET.ADDRESS,
    promise: ({ request }) => request.get(WALLET_API.ADDRESS, { params: payload }),
  });
}

export function getPendingDeposits() {
  return authAction({
    types: WALLET.PENDING_DEPOSITS,
    promise: ({ request }) => request.get(WALLET_API.DEPOSITS),
  });
}

export const getFiatDeposits = () => {
  return authAction({
    types: WALLET.FIAT_DEPOSITS,
    promise: ({ request }) => request.get(WALLET_API.FIAT_DEPOSITS),
  });
};

export const resendEmailConfirmation = payload => {
  return authAction({
    types: WALLET.WITHDRAWAL_RESEND_CONFIRMATION,
    promise: ({ request }) =>
      request.get(WALLET_API.WITHDRAWAL_RESEND_CONFIRMATION, {
        params: payload,
      }),
  });
};

export function changeMargin(product_id, delta_margin) {
  return authAction({
    types: WALLET.CHANGE_MARGIN,
    payload: { product_id, delta_margin },
    promise: ({ request }) =>
      request.post(POSITIONS.CHANGE_MARGIN, {
        data: { product_id, delta_margin },
      }),
  });
}
export function getConversionConfig(): CustomAction {
  return {
    types: WALLET.GET_CONVERSION_CONFIG,
    promise: ({ request }) => request.get(WALLET_API.CONVERSION_CONFIG),
  };
}

export function getConversionRate(params): CustomAction {
  return {
    types: WALLET.GET_CONVERSTION_RATE,
    promise: ({ request }) =>
      request.get(WALLET_API.CONVERSION_RATE, {
        params,
      }),
  };
}

export function convertCurrency(payload): CustomAction {
  return {
    types: WALLET.CONVERT_CURRENCY,
    promise: ({ request }) =>
      request.post(WALLET_API.CONVERSION, {
        data: payload,
      }),
    payload,
  };
}

export const openCurrencyConverterPopup = createAction(OPEN_CURRENCY_CONVERSION_POPUP);

export const closeCurrencyConverterPopup = createAction(CLOSE_CURRENCY_CONVERSION_POPUP);

export const openPopup = createAction(OPEN_POPUP);
export const closePopup = createAction(CLOSE_POPUP);
export const updateBalance = createAction(UPDATE_BALANCE);

export function getRemitanoPaymentURL(payload) {
  return authAction({
    types: WALLET.GET_REMITANO_PAYMENT_URL,
    promise: ({ request }) => request.post(REMITANO_API.GET_PAYMENT_URL, payload),
  });
}

export function getRemitanoCurrenciesInformation() {
  return authAction({
    types: WALLET.GET_REMITANO_CURRENCIES,
    promise: ({ request }) => request.get(REMITANO_API.GET_CURRENCIES),
  });
}

export function getRemitanoCurrencyExchangeRates(payload) {
  return authAction({
    types: WALLET.GET_REMITANO_CURRENCY_EXCHANGE_RATES,
    promise: ({ request }) =>
      request.get(REMITANO_API.GET_CURRENCIES_EXCHANGE_RATES, payload),
  });
}
// export const updatePortfolioMargin = createAction(
//   UPDATE_PORTFOLIO_MARGIN_PARAMS
// );

export function createSimplexPaymentQuote(payload: {
  data: {
    digital_currency: string;
    fiat_currency: string;
    requested_currency: string;
    requested_amount: number;
  };
}) {
  return authAction({
    types: WALLET.CREATE_SIMPLEX_PAYMENT_QUOTE,
    promise: ({ request }) =>
      request.post(SIMPLEX_API.CREATE_SIMPLEX_PAYMENT_QUOTE, payload),
  });
}

export function createSimplexPaymentRequest(payload: {
  data: {
    asset_symbol: string;
    network: string;
    quote_id: string;
    user_reg_date: string;
  };
}) {
  return authAction({
    types: WALLET.CREATE_SIMPLEX_PAYMENT_REQUEST,
    promise: ({ request }) =>
      request.post(SIMPLEX_API.CREATE_SIMPLEX_PAYMENT_REQUEST, payload),
  });
}

export function getSimplexSupportedFiatCurrencies() {
  return authAction({
    types: WALLET.GET_SIMPLEX_SUPPORTED_FIAT_CURRENCIES,
    promise: ({ request }) =>
      request.get(SIMPLEX_API.GET_SIMPLEX_SUPPORTED_FIAT_CURRENCIES),
  });
}

export function getOnmetaQuotation(payload: GetOnmetaQuotationPayload) {
  return authAction({
    types: WALLET.GET_ONMETA_QOUTE,
    promise: ({ request }) => request.post(ONMETA_API.QUOTE, payload),
  });
}

export function getNeofiQuotation(params: GetNeofiQuotationPayload) {
  return authAction({
    types: WALLET.GET_NEOFI_QUOTE,
    promise: ({ request }) => request.get(NEOFI_API.QUOTE, params),
  });
}

export function getNeofiOrderUrl(payload: GetNeofiOrderUrlPayload) {
  return authAction({
    types: WALLET.NEOFI_ORDER_URL,
    promise: ({ request }) => request.post(NEOFI_API.ORDER_URL, payload),
  });
}

export function getOnramperOrderUrl(params: GetOnramperOrderUrlParams) {
  return authAction({
    types: WALLET.ONRAMPER_ORDER_URL,
    promise: ({ request }) => request.get(ONRAMPER_API.ORDER_URL, params),
  });
}

export function getOnramperFiatCurrencyList() {
  return {
    types: WALLET.ONRAMPER_CURRENCY_LIST,
    promise: ({ request }) => request.get(ONRAMPER_API.CURRENCY_LIST),
  };
}
export function createOnMetaOrder(payload: { data: { order_id: string } }) {
  return authAction({
    types: WALLET.CREATE_ONMETA_ORDER,
    promise: ({ request }) => request.post(ONMETA_API.CREATE_ORDER, payload),
  });
}

export function downloadOffRampInvoiceOnmeta(transactionId: number) {
  return authAction({
    types: WALLET.DOWNLOAD_OFFRAMP_INVOICE_ONMETA,
    promise: ({ request }) =>
      request.get(ONMETA_API.OFFRAMP_INVOICE, {
        params: {
          withdrawal_id: transactionId,
        },
      }),
  });
}

export function createMudrexSignature() {
  return authAction({
    types: WALLET.MUDREX_SIGNATURE,
    promise: ({ request }) => request.post(MUDREX_API.SIGNATURE),
  });
}

export function getMudrexQuote(payload: MudrexQuotePayload) {
  return authAction({
    types: WALLET.MUDREX_QUOTE,
    promise: ({ request }) => request.get(MUDREX_API.QUOTE, payload),
  });
}
